/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer'
import { useSelector } from 'react-redux'
import ApiController from '../../redux/actions'
import { useForm } from 'react-hook-form'
import AddPhoto from './AddPhoto'
import { DEFAULT_API_IMAGE } from '../../routes/api_routes';

const DetailGallerie = () => {

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm()

    const [showModalPhoto, setShowModalPhoto] = useState(false)

    const [loading, setLoading] = useState(true);

    const currentAlbum = useSelector(state => state.albums?.currentAlbum)
    const photoList = useSelector(state => state.photos?.photoList)

    useEffect(() => {
        ApiController.photos.getphotosList(currentAlbum.id).then(() => { })
    }, [currentAlbum.id])

    const openAddModalPhoto = () => {
        setShowModalPhoto(true)
    }


    const handleImageLoaded = () => {
      setLoading(false);
    };
  
    const handleImageError = () => {
      setLoading(false);
    };




    return (
        <>
            <AdminInterfaceContainer>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0">
                                <div className="d-flex justify-content-between">
                                    <h1 className="fs-90 mt-5 ms-3">Album
                                    </h1>
                                    <div className="modeleImage mt-2" id='addphoto'>
                                        <button className=" py-2 mt-4 btn-photo me-3" onClick={() => openAddModalPhoto()}>
                                            Ajouter des photos
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <h4 className='fs-14 ms-2'>{currentAlbum.name}</h4>
                                <div className="row gy-">
                                    <div className="mt-3">
                                        <div className="col-12">
                                            <div className="row">

                                                {
                                                    photoList?.map((item, idx) => {

                                                        return (
                                                            <>

                                                                <div className="col-2">
                                                                    <div className="mt-3 ms-3 m-4">

                                                                        {
                                                                            item?.images ? (
                                                                                <>
                                                                                      {loading && <div className="spinner-border text-danger" role="status"></div>}
                                                                                <img src={DEFAULT_API_IMAGE + `${item.images}`} alt="imageAlbum" width="100" className='photos'
                                                                                 onLoad={handleImageLoaded}
                                                                                 onError={handleImageError}
                                                                                 style={{ display: loading ? 'none' : 'block' }} />
                                                                                
                                                                                </>
                                                                               
                                                                            ) : (
                                                                                <h1>Pad d'image</h1>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <AddPhoto showModal={showModalPhoto} setShowModal={setShowModalPhoto} />
                    </div>
                </div>

            </AdminInterfaceContainer>
        </>
    )
}

export default DetailGallerie