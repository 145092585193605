import React from 'react'

const MesureComponent = ({text, Texvalue}) => {
  return (
    <div className='d-flex justify-content-between'>
    <p className=" p-2 hommeTitle text-uppercase">{text}</p>
    <p className=" w-25 text-center rounded p-2 fm hommeMesure" style={{ backgroundColor: "#F7F7F7", color: "#000000" }}>{Texvalue}</p>
  </div>
  )
}

export default MesureComponent