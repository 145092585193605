/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { BiArrowBack } from "react-icons/bi";
import { BsFillImageFill } from "react-icons/bs";

import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer'
import './gallerie.css'
import { useNavigate } from 'react-router-dom';
import { GALERIE_PATH } from '../../utils/navigation_paths';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ApiController from '../../redux/actions';
import { toast } from 'react-toastify';




const AddGallerie = () => {
  const currentUser = useSelector(state => state.users.currentUser)
  const {register,handleSubmit,setError,formState:{errors},reset} = useForm()

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submit = (data) => {
    setIsLoading(true); // Activer la loader
    const newData = new FormData()
      newData.append('name', data.name);
      newData.append('images', data.images);
      newData.append('images', data.images[0]); 
      newData.append('createdBy', currentUser.id);
    // console.log(newData)

     ApiController.albums.registerAlbum(newData).then((res) => {
        console.log(res)
        toast.success('Ajout album réuissi')
        reset()
        navigate(GALERIE_PATH)
  
      }).catch(err => {
        console.log(err);
        setError(err)
        toast.error('erreur lors de l\'ajout')
      })
      .finally(() => {
        setIsLoading(false); // Désactiver la loader
      });
    }
    // preview image befor save
    const [imagePreview, setImagePreview] = useState(null);

    const handleImageChange = (event) => {
      const file = event.target.files[0];
    
      // Vérifier si un fichier a été sélectionné
      if (file) {
        const reader = new FileReader();
    
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
    
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    };



  return (
    <>
      <AdminInterfaceContainer>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="card hauteurRow border-0">
                <div className='shadow-sm p-2 mb-5 w-100 addGalerie text-dark '>
                  <BiArrowBack size={30} className='ms-3 retour' onClick={() => navigate(GALERIE_PATH)}/>
                </div>
                <div className='ms-3'>
                  <h4 className='text-dark'>Creér un album</h4>
                  <form onSubmit={handleSubmit(submit)}>
                    <div className="form-group">
                      <input type="text" className='form-control inpu1 p-3' placeholder="Nom de l'album"
                      {...register('name')}  />
                    </div>
                    <fieldset>
                      <div className="modeleImage" id='addphoto'>

                        <button className="inpu1 py-3 mt-4 btn-photo ">
                         Ajouter des photos
                        </button>
                        
                        <input
                        type="file"
                        name="modele"
                        id="modele"
                        className="form-control"
                        tabIndex="1"
                        required
                        autoFocus
                        {...register('images')}
                        onChange={handleImageChange} // Ajoutez cet événement onChange
/>
                      </div>
                      <button className="inpu1 mt-5 bg-btn inputform ">
                      Enregistrer
                        </button>
                    </fieldset>

                  </form>
                </div>
              </div>
            </div>
            <div className="col-7 centerContenu">
            <div className='vectorImg'>
              {imagePreview ? (
                <img src={imagePreview} alt="Aperçu de l'image" className="previewImage" />
              ) : (
                <BsFillImageFill size={100} />
              )}
            </div>
            <p>Faites glisser des photos par ici pour commencer</p>
          </div>
          </div>
        </div>
      </AdminInterfaceContainer>
    </>
  )
}

export default AddGallerie