import React, { useEffect, useState } from "react";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COMMANDES_PATH } from "../../utils/navigation_paths";
import AddClientForm from "../clients/AddClientForm";
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import { useForm } from "react-hook-form";
import ApiController from "../../redux/actions";
import { toast } from "react-toastify";
import { ButtonRed } from "../utils_components/buttons/Button";
import Select from "react-select";
import "./addCommande.css";

const AddCommandes = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [montantRestant, setMontantRestant] = useState(0);
  const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState(0);
  const [montantState, setMontantState] = useState();

  const currentUser = useSelector((state) => state.users.currentUser);
  // eslint-disable-next-line no-unused-vars
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const listClient = useSelector((state) => state.clients?.clientsList);

  // eslint-disable-next-line no-unused-vars
  const [errorFields, setErrorFields] = useState([]);
  // Image preview
  // eslint-disable-next-line no-unused-vars
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [isInvalidPrice, setIsInvalidPrice] = useState(false);
  const [isInvalidAvance, setIsInvalidAvance] = useState(false);
  const [errorState, setErrorState] = useState(false);

  // const [dateError, setDateError] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);

  const [validateTissuName, setValidateTissuName] = useState(false);

  const options = listClient.map((item) => ({
    value: item.id,
    label: item.nom_complet,
  }));

  const handleTissu = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)
    ) {
      e.preventDefault();
      setValidateTissuName(true);
    } else {
      setValidateTissuName(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // Fin
  useEffect(() => {
    const montant = parseInt(montantState);
    const montantPaye = montantPayeFromApiCompta;
    const montantRestant = montant - montantPaye;
    setMontantRestant(montantRestant);
  }, [montantState, montantPayeFromApiCompta]);

  const validateAge = (value) => {
    if (!value) return true;
    if (isNaN(value) || value < 0 || value > 99 || !/^\d{1,2}$/.test(value)) {
      return false;
    }
    return true;
  };

  const validatePrice = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };
  // const validateDelivredDateCommande = (value) => {
  //   const today = new Date(); // Date et heure actuelles
  //   const formattedToday = today.toISOString(); // Format en ISO 8601

  //   const delivredDate = new Date(value).toISOString(); // Format de la date de livraison en ISO 8601

  //   if (formattedToday <= delivredDate) {
  //     return true;
  //   }

  //   return false;
  // };

  const validateAvance = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const handlePrice = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && (isNaN(e.key) || !validatePrice(inputValue))) {
      e.preventDefault();
      setIsInvalidPrice(true);
    } else {
      setIsInvalidPrice(false);
    }
  };
  const handleAvance = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      (isNaN(e.key) || !validateAvance(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidAvance(true);
    } else {
      setIsInvalidAvance(false);
    }
  };

  const handleNumericInput = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      (isNaN(e.key) || !validateAge(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
    }
  };

  const submit = (data) => {
    // if (data.date_livraison) {
    //   if (validateDelivredDateCommande(data.date_livraison)) {
    //     return setDateError("La date saisie n'est pas correct");
    //   }
    // }

    setIsLoading(true);

    if (data.modele && data.modele[0]) {
      data.clientId = selectedClient?.value;
      const newData = new FormData();
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      newData.append("clientId", parseInt(selectedClient?.value));
      newData.append("nom_tissu", data.nom_tissu);
      newData.append("metre_tissu", parseInt(data.metre_tissu));
      newData.append("montant", parseInt(montantState));
      newData.append("montant_paye", montantPayeFromApiCompta);
      newData.append("date_livraison", isoDate);
      newData.append("modele", data.modele[0]);
      newData.append("createdBy", currentUser.id);

      ApiController.commandes
        .registerCommande(newData)
        .then((res) => {
          // console.log(res)
          const newTransaction = {
            createdBy: res?.createdBy,
            commande: res.id,
            montant_paye: montantPayeFromApiCompta,
          };

          // console.log(newTransaction)
          ApiController.comptabilite
            .registerComptabilite(newTransaction)
            .then((res) => {
              setMontantPayeFromApiCompta(res.montant_paye);
              // console.log({ REPONSE_TRANSACTION: res })
            })
            .catch(() => {
              // console.log(error);
              toast.error("Erreur lors de l'ajout de la commande");
            });
          toast.success("commande ajoute avec succés !");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch(() => {
          // console.log(err);
          setError("message", { message: "Veuillez selecetionnez un client" });
          toast.error("Erreur lors de l'ajout de la commande");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      data.clientId = selectedClient?.value;
      data.createdBy = currentUser.id;
      let { clientId } = data;
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      const newCommande = {
        clientId: parseInt(clientId),
        nom_tissu: data.nom_tissu,
        metre_tissu: parseInt(data.metre_tissu),
        montant: parseInt(montantState),
        montant_paye: parseInt(montantPayeFromApiCompta),
        restant: montantRestant,
        date_livraison: isoDate,
        createdBy: currentUser.id,
      };

      // console.log(newCommande)

      ApiController.commandes
        .registerCommande(newCommande)
        .then((res) => {
          // console.log({ commande: res })
          // console.log(res)
          const newTransaction = {
            createdBy: res?.createdBy,
            commande: res.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          ApiController.comptabilite
            .registerComptabilite(newTransaction)
            .then((res) => {
              // console.log({ REPONSE_COMMANDE: res })
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch((error) => {
              console.log(error);
            });
          toast.success("Ajout commande réussi");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch((err) => {
          console.log(err);
          setError("message", { message: "Erreur lors de l'ajout commande" });
          toast.error("Erreur lors de l'ajout commande");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (errorFields.length) {
      errorFields.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      verificate(data); // Appel de la méthode verificate ici
    }
  };

  const verificate = (data) => {
    // console.log({ data: Date.parse(data.date_livraison) });
    let errorFields = [];

    if (!data.clientId) {
      errorFields.push({
        field: "clientId",
        message: "Veuillez sélectionner un client",
      });
    }
    if (!data.telephone) {
      errorFields.push({
        field: "telephone",
        message: "Ce numéro n'est pas valide !",
      });
    }

    if (errorFields.length) {
      errorFields.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      setMontantPayeFromApiCompta(data.montant_avance);
      submit(data);
    }
  };

  // console.log(Date.now());
  return (
    <AdminInterfaceContainer>
      <div className="client">
        <nav aria-label="breadcrumb" className="app-breadcrumb">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item"
              onClick={() => navigate(COMMANDES_PATH)}
            >
              Liste des commandes
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ajout commande
            </li>
          </ol>
        </nav>

        <form onSubmit={handleSubmit(submit)}>
          <div className="add-commande-container">
            <div className="add-commande-client-section">
              {/* Client */}
              <h3 className="section-title">Client</h3>
              <div className="row section-content mb-4 displaySelectAndAddClient">
                <div className="row m-2 ">
                  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-10">
                    <label htmlFor="clientId" className="labelSmalDevice">
                      Nom client
                    </label>
                  </div>

                  <div className="col-xl-5 col-lg-7 col-md-10 col-sm-10 col-10">
                    <Select
                      className="onSmallDevicce"
                      name="clientId"
                      id="clientId"
                      options={options}
                      placeholder="Choisissez un client"
                      isClearable={true}
                      {...register("clientId")}
                      value={selectedClient || null}
                      onChange={(client) =>
                        client?.value !== "+" && setSelectedClient(client)
                      }
                    />

                    {errors.clientId && (
                      <p className="error-msg text-center">
                        &nbsp; {errors.clientId.message}
                      </p>
                    )}
                  </div>

                  <div
                    className={`col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 clientSmallDevice`}
                  >
                    <button
                      disabled={selectedClient}
                      className="add-new-client"
                      onClick={() => setShowModal(true)}
                      type="button"
                    >
                      <FiPlus />
                    </button>
                  </div>
                </div>
              </div>

              {/* Commandes */}
              <h3 className="section-title">Commande</h3>
              <div className="row section-content mb-4">
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="nom_tissus">Nom du tissu</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="text"
                      name="nom_tissu"
                      id="nom_tissu"
                      className="form-control"
                      {...register("nom_tissu", {
                        required: "Champ obligatoire !",
                      })}
                      onKeyDown={handleTissu}
                    />
                    {validateTissuName && (
                      <p className="error-msg">
                        Veuillez entrer un nom de tissu valide !
                      </p>
                    )}
                    {errors.nom_tissu && (
                      <p className="error-msg text-center">
                        {" "}
                        &nbsp; {errors.nom_tissu.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="quantite">Longueur tissu (m / y)</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="metre_tissu"
                      id="metre_tissu"
                      className="form-control"
                      {...register("metre_tissu", {
                        required: "Champ obligatoire !",
                      })}
                      onKeyDown={handleNumericInput}
                    />
                    {isInvalidInput && (
                      <p className="error-msg">
                        Veuillez entrer un nombre valide.
                      </p>
                    )}
                    {errors.metre_tissu && (
                      <p className="error-msg text-center">
                        {" "}
                        &nbsp; {errors.metre_tissu.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="prix">Prix confection</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montant"
                      id="montant"
                      className="form-control"
                      {...register("montantState", {
                        required: "Champ obligatoire !",
                      })}
                      onChange={(e) => setMontantState(e.target.value)}
                      value={montantState || ""}
                      onKeyDown={handlePrice}
                    />
                    {isInvalidPrice && (
                      <p className="error-msg">
                        Veuillez entrer un nombre valide.
                      </p>
                    )}
                    {errors.montantState && (
                      <p className="error-msg text-center">
                        {" "}
                        &nbsp; {errors.montantState.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="modele">Modèle / tissu</label>
                  </div>
                  <div className="modeleImage col-xl-5 col-lg-7">
                    <button className="modeleLabel">
                      <FiUpload /> Importer une image
                    </button>
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="modele"
                        style={{
                          marginTop: "5px",
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    )}
                    <input
                      type="file"
                      name="modele"
                      {...register("modele")}
                      id="modele"
                      className="form-control"
                      onChange={handleFileUpload}
                    />
                  </div>
                </div>

                {/* <AddClientForm showModal={showModal} setShowModal={setShowModal} /> */}

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="date_livraison">Date de livraison</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="date"
                      name="date_livraison"
                      id="date_livraison"
                      className="form-control"
                      {...register("date_livraison", {
                        required: "Champ obligatoire !",
                        min: {
                          value: new Date().toISOString().split("T")[0], // Format ISO 8601 sans heure
                          // message: "La date de livraison ne peut pas être dans le passé.",
                        },
                      })}
                    />
                    {errors.date_livraison && (
                      <span>{errors.date_livraison.message}</span>
                    )}

                    {errors.date_livraison && (
                      <p className="error-msg">
                        &nbsp; {errors.date_livraison.message}
                      </p>
                    )}
                    {/* {dateError && (
                      <p className="error-msg">&nbsp; {dateError}</p>
                    )} */}
                  </div>
                </div>
              </div>

              {/* Paiement */}
              <h3 className="section-title">Modalité de paiement</h3>
              <div className="row section-content">
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="montant_avance">Montant avancé</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montant_avance"
                      id="montant_avance"
                      className="form-control"
                      {...register("montantPayeFromApiCompta")}
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        const newMontantPaye = inputValue >= 0 ? inputValue : 0;
                        const newMontantRestant = montantState - newMontantPaye;

                        // Vérifier si montantPaye dépasse le montant total
                        if (newMontantPaye <= montantState) {
                          setMontantPayeFromApiCompta(newMontantPaye);
                          setMontantRestant(
                            newMontantRestant >= 0 ? newMontantRestant : 0
                          );
                          setErrorState(false);
                        } else {
                          // Afficher une erreur lorsque montantPaye dépasse le montant total
                          setMontantPayeFromApiCompta(montantPayeFromApiCompta);
                          setMontantRestant(montantRestant);
                          setErrorState(true);
                          // Afficher un message d'erreur ou utiliser une variable d'état pour indiquer une erreur
                          // Exemple : setErrorState(true);
                        }
                      }}
                      value={
                        montantPayeFromApiCompta !== 0
                          ? montantPayeFromApiCompta
                          : ""
                      }
                      onKeyDown={handleAvance}
                    />
                    {isInvalidAvance && (
                      <p style={{ color: "red" }}>
                        Veuillez entrer un nombre valide.
                      </p>
                    )}
                    {errors.metre_tissu && (
                      <p className="error-msg ">
                        {" "}
                        &nbsp; {errors.metre_tissu.message}
                      </p>
                    )}
                    {errorState && (
                      <p className="error-msg">
                        {" "}
                        &nbsp; Le montant de l'avance ne peut pas dépasser le
                        montant du prix confection.
                      </p>
                    )}
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="montant_restant">Montant restant</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montant_restant"
                      id="montant_restant"
                      className="form-control"
                      disabled
                      value={montantPayeFromApiCompta ? montantRestant : ""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center my-3">
              {/* <button type="submit">save</button> */}
              {/* <ButtonRed
              disabled={isLoading}
                type={"submit"}
                backgroundColor={"#E82828"}
                color={"#FFFFFF"}
              >
                Valider
              </ButtonRed> */}
              <ButtonRed
                onClick={() => {}}
                disabled={true}
                type="submit"
                className="pt-2"
                backgroundColor={"#e82828"}
                color={"#ffffff"}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  "Valider"
                )}
              </ButtonRed>
            </div>
          </div>
        </form>
        <AddClientForm showModal={showModal} setShowModal={setShowModal} />
      </div>
    </AdminInterfaceContainer>
  );
};

export default AddCommandes;
