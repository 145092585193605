import React from "react";
export function ButtonRed({
  type,
  className,
  processing,
  color,
  backgroundColor,
  children,
  onClick,
}) {
  return (
    <button
      type={type}
      className={className}
      disabled={processing}
      style={{
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "17px",
        color: color,
        background: backgroundColor,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        padding: "10px 30px",
        border: "none",
      }}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
}

export function ButtonWhite({
  type,
  className,
  processing,
  color,
  backgroundColor,
  children,
  onClick,
}) {
  return (
    <button
      type={type}
      className={className}
      disabled={processing}
      style={{
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "17px",
        color: color,
        background: backgroundColor,
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "11px",
        border: `1px solid ${color}`,
      }}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
}
