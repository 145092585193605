// eslint-disable-next-line no-unused-vars
import { ADD_PHOT_IN_ALBUM_API_ROUTE, ADD_photo_API_ROUTE, GET_photo_API_ROUTE, PHOT_IN_ALBUM_API_ROUTE } from "../../routes/api_routes"
import { addNewphoto, setphotoList } from "../reducers/photos"
import { store } from "../store/store"
import axios from 'axios'


const dispatch = store.dispatch

const photos = {
    getphotosList(id){
        return new Promise((resolve,reject)=>{
            axios.get(PHOT_IN_ALBUM_API_ROUTE + `/${id}`)
            .then(response => {
                
                if(response.status === 200){
                    let photos = response.data
                    dispatch(setphotoList(photos))
                    resolve(photos)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerphoto(id,data){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_PHOT_IN_ALBUM_API_ROUTE + `/${id}/`,data)
            .then(response => {
                console.log(response)
                if(response.status ===201){
                    let client = response.data
                    dispatch(addNewphoto(client))
                    resolve(client)
                    this.getphotosList(id)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    // updateClient(data,clieni_id){
       
    //     return new Promise((resolve,reject)=>{
    //         axios.put(`${UPDATE_CLIENT_API_ROUTE + clieni_id}/`,data)
    //         .then(response => {
    //             if(response.status ===200){
    //                 let client = response.data
    //                 dispatch(updateClientInList(client))
    //                 resolve(client)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // deleteClient(clieni_id, userId){
    //     return new Promise((resolve,reject)=>{
    //         axios.delete(`${DELETE_CLIENT_API_ROUTE + clieni_id}/`)
    //         .then(response => {
    //             if(response.status ===204){
    //                 dispatch(archiveClientInList(clieni_id))
    //                 resolve(response)
    //                 this.getphotosList(userId)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
}
export default photos