/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeCurrentUser } from '../../redux/reducers/users';
import { LOGIN_PATH } from '../../utils/navigation_paths';
import { GrLogout } from "react-icons/gr";
import { IoNotificationsSharp } from "react-icons/io";
import { FaBell } from "react-icons/fa";

import './header.css'

import InstallPrompt from '../pwInstallation';
import NonLivree from '../commandes/stautCommande/NonLivree';

const Header = () => {
  const currentUser = useSelector(state => state.users.currentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isOnThisPage = useSelector(state => state.page)


  const [showModal, setShowModal] = useState(false)
  const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)

  const commandeNonLivree = listCommandes?.filter(item => item.statut !== "livree")


  const disconnect = () => {
    localStorage.clear()
    dispatch(removeCurrentUser())
    navigate(LOGIN_PATH)
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon order-2"></span>
    </button> */}

          <div className='contenu-header'>
            {/* <InstallPrompt/> */}
            <ul className="navbar-nav user-info">

              <li className="nav-item">
                <span className="nav-link active">{`${currentUser.firstName} ${currentUser.lastName}`}</span>
              </li>
              {
                commandeNonLivree?.length ? <li className="nav-item">
                  {/* <FaBell className="nav-link badge bg-danger" size={40}  onClick={()=>setShowModal(true)}></FaBell> */}
                  <button type="button" onClick={() => setShowModal(true)} className="btn position-relative">
                    <FaBell />
                    <span className="position-absolute start-90 translate-middle badge rounded-pill bg-danger">
                      {commandeNonLivree?.length}

                    </span>
                  </button>

                </li> : ''
              }

              <li className="nav-item disableIconOnSmallDevice">
                <GrLogout className="nav-link" onClick={() => disconnect()} size={40} style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top" title="Deconnexion" />
              </li>
            </ul>

          </div>
        </div>
      </nav>
      <NonLivree showModal={showModal} setShowModal={setShowModal} commandeNonLivree={commandeNonLivree} />
    </>

  );
};

export default Header;