if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker
      .register("/path/to/your/service-worker.js")
      .then(function (registration) {
        console.log("Service worker registered:", registration);
      })
      .catch(function (error) {
        console.log("Service worker registration failed:", error);
      });
  });
}
