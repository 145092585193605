/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useNavigate } from "react-router-dom";
import { ADD_GALERIE_PATH, DETAIL_GALERIE_PATH, GALERIE_PATH } from "../../utils/navigation_paths";
import { ButtonRed } from "../utils_components/buttons/Button";
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import { useForm } from "react-hook-form";
import ApiController from "../../redux/actions";
import { toast } from "react-toastify";
import './gallerie.css'
import AddGallerie from "./AddGallerie";
import { setcurrentAlbum } from "../../redux/reducers/albums";
import { setCurrentItem } from "../../redux/reducers/navigation";
import { DEFAULT_API_IMAGE } from '../../routes/api_routes';
// import back from "../../img/icons8-left-arrow-50.png"
// import iconPhoto from "../../img/iconPhoto.jpg"



const Galleries = () => {


  const currentUser = useSelector(state => state.users?.currentUser)
  const dispatch = useDispatch();

  const albumList = useSelector(state => state.albums?.albumList)
  const navigate = useNavigate();
  

  useEffect(() =>{
    dispatch(setCurrentItem(GALERIE_PATH));

    ApiController.albums.getalbumsList(currentUser?.id).then((res) =>{})
  }, [currentUser?.id, dispatch])


  const detailAlbum = (item) => {
    dispatch(setcurrentAlbum(item))
    const albumId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailAlbum = DETAIL_GALERIE_PATH.replace(":id", albumId);
    navigate(detailAlbum);
  }



 
  return (
    <>
        <AdminInterfaceContainer>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <div className="card border-0">
                  <h1 className="fs-90 mt-5 ms-3">Album</h1>
                  <hr />
                  <div className="row gap">
                    <div className="col-md-3 ">
                  <div className="mt-3">
                    <Link to={ADD_GALERIE_PATH} className="text-dark">
                    <p className="ms-3 addImage">
                      <BsPlus size={50}/>
                    </p>
                    </Link>
                    <h2 className="mt-2 fs-90 text-center">Creer un album</h2>
                  </div>

                    </div>
                    {
                      albumList?.map((item, idx) =>{
                        return(
                          <>
                          
                    <div className="col-md-3" key={idx}>
                      
                    <div className="mt-3">
                    
                        <p className="ms-3 " >
                            <img src={DEFAULT_API_IMAGE + `${item?.images}`} className="imageGallerie" style={{cursor:"pointer"}} alt="modele africains" onClick={() => detailAlbum(item)}/>
                        </p>
                      
                        <h2 className="mt-2 fs-90 ms-3 text-center">{item?.name}</h2>
                    </div>

                    </div>
                          </>
                        )
                      })
                    }

               
                

            
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        </AdminInterfaceContainer>
    </>
  )
}

export default Galleries