/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';

import './parametre.css'
import ApiController from '../../redux/actions';
import { toast } from 'react-toastify';
import { ButtonRed } from '../utils_components/buttons/Button';

const ProfileTab = () => {

  const currentUser = useSelector(state => state.users.currentUser);
  const { register, handleSubmit, setError, formState: { errors }, reset,  } = useForm()

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() =>{
    ApiController.profiles.getProfile(currentUser.id).then((res) => console.log(res))
  },[currentUser.id])


  const toggleEditMode = () => {
    setIsEditMode((prevEditMode) => !prevEditMode);
  };

  const handleParametre = (data) => {
    setIsLoading(true)

    if (data.profile_image && data.profile_image[0] && data.uploaded_images[0]) {
      const formData = new FormData()
      formData.append('nom_attelier', data.nom_attelier)
      formData.append('numWhtsapp', data.numWhtsapp)
      formData.append('profile_image', data.profile_image[0])
      formData.append('ville', data.nom_attelier)
      formData.append('pays', data.pays)
      formData.append('description', data.description)
      formData.append('userId', currentUser?.id)
      formData.append('uploaded_images', data.uploaded_images[0])

      // console.log({FORMDATA:formData})

      ApiController.profiles.registerProfile(formData).then((res) => {
        console.log(res)
        setIsEditMode(false)
        reset()
        toast.success("Parametre avec succes !")
      }).then((err) => {
        console.log(err)
      })

    } else {
      const newData = {
        nom_attelier: data.nom_attelier,
        numWhtsapp: data.numWhtsapp,
        ville: data.nom_attelier,
        pays: data.pays,
        description: data.description,
        userId: currentUser?.id
      }

      delete data.profile_image

      console.log({NOIMAGE:newData})
      ApiController.profiles.registerProfile(newData).then((res) => {
        console.log(res)
        setIsEditMode(false)
        reset()
        toast.success("Parametre avec succes !")
      }).then((err) => {
        console.log({ERROR:err})
      }).finally(() => setIsLoading(false))
    }


  }


  return (
    <>

      <section className="h-100 gradient-custom-2">
        <div className="container py-5 h-100">
          <div className="row  h-100">
            <div className="col-12">
              <div className="card">
                <div className="rounded-top text-white d-flex flex-row" style={{ backgroundColor: "#000", height: "200px" }}>
                  <div className="ms-4 mt-5 d-flex flex-column" style={{ width: "150px" }}>
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
                      alt="Generic placeholder" className="img-fluid img-thumbnail mt-4 mb-2"
                      style={{ width: "150px", zIndex: 1 }} />

                    <button type="button" className="btn btn-outline-dark" data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }} onClick={toggleEditMode}>
                      Gerrer vos parametres
                    </button>
                  </div>
                  <div className="ms-3" style={{ marginTop: "130px" }}>
                    <h5>Andy Horwitz</h5>
                    <p>New York</p>
                  </div>
                </div>
                <div className="p-5 text-black" style={{ backgroundColor: "#f8f9fa" }}>
                  {/* <div class="d-flex justify-content-end text-center py-1">
              <div>
                <p class="mb-1 h5">253</p>
                <p class="small text-muted mb-0">Photos</p>
              </div>
              <div class="px-3">
                <p class="mb-1 h5">1026</p>
                <p class="small text-muted mb-0">Followers</p>
              </div>
              <div>
                <p class="mb-1 h5">478</p>
                <p class="small text-muted mb-0">Following</p>
              </div>
            </div> */}
                </div>
                <div className="card-body p-4 text-black">
                  {
                    isEditMode ? (
                      <>
                        <div className="card border-0">
                          <div className="card-body">
                            <form onSubmit={handleSubmit(handleParametre)}>
                              <div className="row">


                                <div className="col-md-6">

                                  <div className="mb-3">
                                    <label className="form-label">Nom de l'attelier</label>
                                    <input type="tel" className="form-control"  {...register('nom_attelier')} />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Numero Whtsapp</label>
                                    <input type="tel" className="form-control"  {...register('numWhtsapp')} />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Logo de l'attelier</label>
                                    <input type="file" className="form-control"  {...register('profile_image')} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="prenom" className="form-label">Pays</label>
                                    <input type="text" className="form-control" {...register('pays')} />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">Ville</label>
                                    <input type="text" className="form-control"  {...register('ville')} />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" {...register("description")} rows="3" cols="50"></textarea>
                                  </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Image du salon</label>
                                    <input type="file" className="form-control"  {...register('uploaded_images')} />
                                  </div>
                                <div className="d-flex justify-content-center ">


                                <ButtonRed onClick={() => { }} disabled={true} type="submit" className='pt-2 w-50' backgroundColor={'#e82828'} color={'#ffffff'}>
                {
                  isLoading ? (
                    <div className="spinner-border text-light" role="status">
                    </div>
                  ) : 'Valider'
                }
              </ButtonRed>

                                </div>

                              </div>


                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-5">
                          <p className="lead fw-normal mb-1">About</p>
                          <div className="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                            <p className="font-italic mb-1">Web Developer</p>
                            <p className="font-italic mb-1">Lives in New York</p>
                            <p className="font-italic mb-0">Photographer</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <p className="lead fw-normal mb-0">Recent photos</p>
                          <p className="mb-0"><a href="#!" className="text-muted">Show all</a></p>
                        </div>
                        <div className="row g-2">
                          <div className="col mb-2">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(112).webp"
                              alt="image1" className="w-100 rounded-3" />
                          </div>
                          <div className="col mb-2">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(107).webp"
                              alt="image1" className="w-100 rounded-3" />
                          </div>
                        </div>
                        <div className="row g-2">
                          <div className="col">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(108).webp"
                              alt="image1" className="w-100 rounded-3" />
                          </div>
                          <div className="col">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/Lightbox/Original/img%20(114).webp"
                              alt="image1" className="w-100 rounded-3" />
                          </div>
                        </div>
                      </>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProfileTab