import { createSlice } from "@reduxjs/toolkit/"
import { clientGenerator } from "../../utils/data_generator"

let data = clientGenerator(100)

let initialState = {
    currentMesure : {},
    listMesures : data
}
const mesuresSlice = createSlice({
    name:'mesures',
    initialState,
    reducers:{
        setListMesures:(state,{payload})=>{
            state.listMesures = payload
        },
        addNewMesure:(state,{payload})=>{
            state.listMesures = [...state.listMesures,payload]
        },
        setCurrentMesure:(state,{payload})=>{
            let listMesures = state.listMesures.filter(item => item.id !== payload.id)
            state.listMesures = [...listMesures,payload]
            state.currentMesure = state.listMesures.filter(item => item.id === payload)[0]
        },
        deleteCurrentMesure:(state,{payload})=>{
            state.listMesures = state.listMesures.filter(item => item.id !== payload)
        },
    }
})

export const { addNewMesure,setListMesures,setCurrentMesure,deleteCurrentMesure} = mesuresSlice.actions
export default mesuresSlice.reducer