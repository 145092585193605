import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ApiController from '../../redux/actions';
import { isValidMobilePhone, isValidText } from '../../utils/form_control';
// import { LOGIN_PATH } from '../../utils/navigation_paths';
import { FaMapMarkerAlt } from "react-icons/fa";


const RegisterPage = ({ setHasAccount }) => {

    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, setError } = useForm()

    const [isInvalidInput, setIsInvalidInput] = useState(false)


    const validatePhone = (value) => {
        if (!value) return true;
        if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
          return false;
        }
        return true;
      };

      const handleNumericInput = (e) => {
        const inputValue = e.target.value + e.key;
        if (e.key !== 'Backspace' && (isNaN(e.key) || !validatePhone(inputValue))) {
          e.preventDefault();
          setIsInvalidInput(true);
        } else {
          setIsInvalidInput(false);
        }
      };


    const submit = (data) => {
        setIsLoading(true);
        ApiController.users.registerUser(data)
            .then(() => {
                setHasAccount(true)
                toast.success('Inscription réuissi')
            })
            .catch(err => {
                // console.log("err", err);
                if (err.response.data?.phone) {
                    if (err.response.data.phone) {
                        setError('phone', { message: 'Ce numéro a déja un compte' })
                    }
                    // if(err.response.data.email){
                    //     setError('email',{message:'Email déja pris'})
                    // }
                }
                else {
                    setError('confirmPassword', { message: 'L\'inscription n\'a pas réuissi!' })
                }
            }).finally(() => {
                setIsLoading(false); // Désactiver la loader
            });

    }

    const verificate = (data) => {
        let errorFields = []

        if (!isValidText(data.firstName)) {
            errorFields.push({ field: 'firstName', message: 'Ce nom n\'est pas valid!' })
        }
        if (!isValidText(data.lastName)) {
            errorFields.push({ field: 'lastName', message: 'Ce prenom n\'est pas valid!' })
        }
        // if(!isValidText(data.adresse)){
        //     errorFields.push({field:'adresse',message:'Cette adresse n\'est pas valid!'})
        // }
        // if(!isValidEmail(data.email)){
        //     errorFields.push({field:'email',message:'Email invalid!'})
        // }
        if (!isValidMobilePhone(data.phone)) {
            errorFields.push({ field: 'phone', message: 'Ce numéro n\'est pas valide!' })
        }
        if (data.password.length < 4) {
            errorFields.push({ field: 'password', message: 'Mot de passe trop court' })
        } else if (data.password !== data.confirmPassword) {
            errorFields.push({ field: 'confirmPassword', message: 'Les deux mots de passe ne correspondent pas' })
        }

        if (errorFields.length) {
            errorFields.map(err => {
                let { field, message } = err
                setError(field, { message })
                return err
            })
        } else {
            submit(data)

        }

    }
    return (
        <div className=''>
            <p className='fw-bold mb-5 pb-3 fs-4'>CONNECTEZ-VOUS</p>
            <form onSubmit={handleSubmit(verificate)} className='mx-md-5 px-md-5' >
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon user-icon"></div>
                    <div className="col-10">
                        <input type="text"  {...register('firstName', { required: 'Ce champ est obligatore!' })} placeholder='Nom' className='champ' />
                    </div>
                </label>
                <p className='error-msg'> &nbsp; {errors?.firstName?.message}</p>
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon user-icon"></div>
                    <div className="col-10">
                        <input type="text"  {...register('lastName', { required: 'Ce champ est obligatore!' })} placeholder='Prénom' className='' />
                    </div>
                </label>
                <p className='error-msg'> &nbsp; {errors?.lastName?.message}</p>
                {/* <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon mail-icon"></div>
                    <div className="col-10">
                        <input {...register('email')} placeholder='Email' type="email" className='' />
                    </div>
                </label>
                <p className='error-msg'> &nbsp; {errors?.email?.message}</p> */}
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon phone-icon"></div>
                    <div className="col-10">
                        <input type="tel" {...register('phone', { required: 'Ce champ est obligatore!' })} placeholder='Téléphone'  className='' onKeyDown={handleNumericInput}/>
                    </div>
                </label>
                {isInvalidInput && (
                      <p className="error-msg">Veuillez entrer un numero de telephone valide.</p>
                    )}
                <p className='error-msg'> &nbsp; {errors?.phone?.message}</p>
                <label className='input-container align-items-center row m-0 p-0'>
                    <FaMapMarkerAlt className="col-2" />
                    <div className="col-10">
                        <input type="text"  {...register('adresse', { required: 'Ce champ est obligatore!' })} placeholder='Adresse' className='' />
                    </div>
                </label>
                <p className='error-msg'> &nbsp; {errors?.password?.message}</p>
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon password-icon"></div>
                    <div className="col-10">
                        <input type="password"  {...register('password', { required: 'Ce champ est obligatore!' })} className='' placeholder='Mot de passe' />
                    </div>
                </label>
                <p className='error-msg'> &nbsp; {errors?.password?.message}</p>
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon password-icon"></div>
                    <div className="col-10">
                        <input {...register('confirmPassword', { required: 'Ce champ est obligatore!' })} type="password" className='' placeholder='Confirmer votre mot de passe' />
                    </div>
                </label>
                <p className='error-msg mb-3'> &nbsp; {errors?.confirmPassword?.message}</p>
                <button className='primary-btn fw-bold'>
                    {isLoading ? (
                        <div className="spinner-border text-light" role="status">
                        </div>
                    ) : "Inscription"

                    }
                </button>
                <p className='text-center my-3' style={{ fontSize: '14px' }}>
                    Vous avez déja un compte? <button type='button' onClick={() => setHasAccount(true)} className="btn p-0 red-link"> Se connecter</button>                </p>
            </form>
        </div>
    );
};

export default RegisterPage;
