import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentAlbum: {},
    albumList: [],
    allalbumList: [],
}
const albumsSlice = createSlice({
    name:'albums',
    initialState,
    reducers:{
        addNewAlbum:(state,{payload})=>{
            state.albumList = [...state.allalbumList,payload].reverse()
            state.allalbumList = [...state.allalbumList,payload].reverse()
        },
        updateAlbumInList:(state,{payload})=>{
            let albums = state.allalbumList.filter(album => album.id !== payload.id)
            albums = [...albums,payload]
            state.allalbumList = albums
            state.albumList = albums
        },
        archiveAlbumInList:(state,{payload})=>{
            let albums = state.allalbumList.filter(album => album.id !== payload.id)
            state.allalbumList = albums
            state.albumList = albums
        },
        setcurrentAlbum:(state,{payload})=>{
            state.currentAlbum = payload
        },
        // eslint-disable-next-line no-unused-vars
        ressetCurrentAlbum:(state,{payload})=>{
                state.currentAlbum = {}
            },
        setAlbumList:(state,{payload})=>{
            state.albumList = payload
            state.allalbumList = payload
        },
        filterAlbumList:(state,{payload})=>{
            state.albumList = state.allalbumList.filter(item =>{
                let data = [item.nom_complet,item.telephone, item.adresse].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const {addNewAlbum, setcurrentAlbum,ressetCurrentAlbum, setAlbumList,filterAlbumList, updatealbumInList,archivealbumInList} = albumsSlice.actions
export default albumsSlice.reducer
