/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentphoto: {},
    photoList: [],
    allphotoList: [],
}
const photosSlice = createSlice({
    name:'photos',
    initialState,
    reducers:{
        addNewphoto:(state,{payload})=>{
            state.photoList = [...state.allphotoList,payload].reverse()
            state.allphotoList = [...state.allphotoList,payload].reverse()
        },
        updatephotoInList:(state,{payload})=>{
            let photos = state.allphotoList.filter(photo => photo.id !== payload.id)
            photos = [...photos,payload]
            state.allphotoList = photos
            state.photoList = photos
        },
        archivephotoInList:(state,{payload})=>{
            let photos = state.allphotoList.filter(photo => photo.id !== payload.id)
            state.allphotoList = photos
            state.photoList = photos
        },
        setcurrentphoto:(state,{payload})=>{
            state.currentphoto = payload
        },
        ressetCurrentphoto:(state,{})=>{
                state.currentphoto = {}
            },
        setphotoList:(state,{payload})=>{
            state.photoList = payload
            state.allphotoList = payload
        },
        filterphotoList:(state,{payload})=>{
            state.photoList = state.allphotoList.filter(item =>{
                let data = [item.nom_complet,item.telephone, item.adresse].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const {addNewphoto, setcurrentphoto,ressetCurrentphoto, setphotoList,filterphotoList, updatephotoInList,archivephotoInList} = photosSlice.actions
export default photosSlice.reducer
