/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"
import { productsGenerator } from "../../utils/data_generator"

let data = productsGenerator(100)
let initialState = {
    currentProfile: {},
    listProfile : [],
    listProfileAll : []
}
const profileSlice = createSlice({
    name:'profiles',
    initialState,
    reducers:{
        addNewProfile: (state, { payload }) => {
            state.listProfile = [...state.listProfileAll, payload]
            state.listProfileAll = [...state.listProfile]
          },
        // setCurrentCommande:(state,{payload})=>{
        //     state.currentCommande = payload
        // },
        setProfile:(state,{payload})=>{
            state.listCommandes = payload
            state.listCommandesAll = payload
        },
        // resetCurrentCommande:(state,{payload})=>{
        //     state.currentCommande = {}
        // }
    }
})

export const { addNewProfile,setProfile} = profileSlice.actions
export default profileSlice.reducer