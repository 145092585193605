/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import CustomModal from '../utils_components/modals/CustomModal';
import ApiController from '../../redux/actions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import './gallerie.css'

const AddPhoto = ({ showModal, setShowModal }) => {

    const currentUser = useSelector(state => state.users.currentUser)
    const currentAlbum = useSelector(state => state.albums?.currentAlbum)
    const {register,handleSubmit,setError,formState:{errors},reset} = useForm()
   
    // Image preview
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
        setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };
  // Fin

    function closeModal() {
        setShowModal(false);
    }



    const [isLoading, setIsLoading] = useState(false);

  const submit = (data) => {
    setIsLoading(true); // Activer la loader
    const newData = new FormData()
      newData.append('name', data.name);
      newData.append('images', data.images[0]); // 
      newData.append('albumId', currentAlbum.id);
      newData.append('createdBy', currentUser.id);


     ApiController.photos.registerphoto(currentAlbum.id, newData).then((res) => {
        console.log(res)
        toast.success('Ajout album réuissi')
        reset()
        closeModal()
        // navigate(GALERIE_PATH)
  
      }).catch(err => {
        console.log(err);
        setError(err)
        toast.error('erreur lors de l\'ajout')
      })
      .finally(() => {
        setIsLoading(false); // Désactiver la loader
      });

    }



    return (
        <>
            <div className="container">
                <CustomModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title='Ajouter une photo'
                    titleIconClass='white-image-icon'

                >
                    <div className="d-flex justify-content-center">

                <form onSubmit={handleSubmit(submit)}>
                    <label htmlFor="" className='mt-5'>Nom de la photo</label>
                    <div className="form-group">
                    <input type="text" className='form-control centerInput'
                    {...register('name')}
                    />
                    </div>
                    <div className="form-group mt-5">
                    {/* {previewImage && (
                      <img src={previewImage} alt="Image" style={{ marginTop: '5px', width: '200px', height: '200px' }} />
                    )} */}
                    <input type="file" className='form-control centerInput'
                    // onChange={handleFileUpload}
                    {...register('images')}
                    />
                    </div>
                    <div className="text-center mt-5">
                    <button className='w-100 btnSave'>
                        {
                            isLoading ?<div className="spinner-border text-light" role="status">
                            </div>:'Enregistrer'
                        }
                    </button>
                    </div>
                </form>
                    </div>
                </CustomModal>
            </div>
        </>
    )
}

export default AddPhoto