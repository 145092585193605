/* eslint-disable no-unused-vars */
import React from 'react';
import './cardview.css'
import Skeleton from 'react-loading-skeleton'


const CardView = ({title,value,iconClass,cardClass ="", src,altImag}) => {
    return (
        <div >
            <div className={"cardview row mx-2 pb-2 "+cardClass}>
                <div className="row p-0 m-0 pt-4">
                   
                    <img src={src} alt={altImag} style={{width:"90px", height:"70px"}}/>
                    <div className="col text-end">
                        <div className='p-0 m-0 text-center'>
                            {title||'Titre'}
                            <h1 className='fw-bold mt-2 mb-3'>{value || 0}</h1>
                        </div>
                        
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default CardView;