import axios from 'axios'
import { addNewCommande, setCommandesList, setCurrentCommande } from '../reducers/commandes'
import { store } from '../store/store'

const { COMMANDE_API_ROUTE, DELETE_COMMANDE_API_ROUTE, ADD_COMMANDE_API_ROUTE, UPDATE_COMMANDE_API_ROUTE, GET_COMMANDE_BYID_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const commandes = {
    getCommandesList(userId){
        return new Promise((resolve,reject)=>{
            axios.get(COMMANDE_API_ROUTE + `${userId}/`)
            .then(response => {
                if(response.status === 200){
                    let commandes = response.data
                    // console.log('commandes',commandes);
                    dispatch(setCommandesList(commandes))
                    resolve(commandes)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    getCommandeById(id){
        return new Promise((resolve,reject)=>{
            axios.get(GET_COMMANDE_BYID_API_ROUTE + `${id}/`)
            .then(response => {
                if(response.status === 200){
                    let commandes = response.data
                    // console.log('commandes',commandes);
                    dispatch(setCurrentCommande(commandes))
                    resolve(commandes)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerCommande(data){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_COMMANDE_API_ROUTE,data)
            .then(response => {
                if(response.status ===201){
                    // console.log(response.data)
                    let commande = response.data
                    // dispatch(this.getCommandesList(data, userId))
                    dispatch(addNewCommande(commande))
                    resolve(commande)
                }
                // else{
                //     reject(response)
                // }
            })
            .catch(error => reject(error))
        })
    },
    updateCommande(data,id,userId){
        return new Promise((resolve,reject)=>{
            axios.put(`${UPDATE_COMMANDE_API_ROUTE + id}/`,data)
            .then(response => {
                // console.log(response);
                if(response.status ===200){
                    let commande = response.data
                    dispatch(setCurrentCommande(commande))
                    this.getCommandesList(userId)
                    resolve(commande)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    deleteCommande(id, userId){
        return new Promise((resolve,reject)=>{
            axios.delete(`${DELETE_COMMANDE_API_ROUTE + id}/`)
            .then(response => {
                if(response.status ===204){
                    resolve(response)
                    dispatch(this.getCommandesList(userId))
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
}
export default commandes