/* eslint-disable no-unused-vars */
import React from 'react'
import CustomBootstrapTable from '../../utils_components/tables/CustomBootstrapTable';
import moment from 'moment';
import InputFiltrer from '../../utils_components/inputs/InputFiltrer';
import { filterCommandesBySearch } from '../../../redux/reducers/commandes';
import { useDispatch } from 'react-redux';

const Livree = ({listCommandes}) => {
  
  const dispatch = useDispatch();

    const commandeLivree = listCommandes?.filter(item => item.statut === 'livree')



    const columns = [
        {
          dataField: "numero_commande",
          text: "N°",
        },
        {
          dataField: "clientId",
          text: "Clients",
          formatter: (cell, row) => {
            if (row.client) {
              return row.client.nom_complet;
            } else {
              return "non defini";
            }
          },
  
        },
        {
          dataField: "date_livraison",
          text: "Date de livraison",
          formatter: (cell, row) => {
            const formattedDate = moment(row.date_livraison).format("DD/MM/YYYY HH:mm");
            return formattedDate;
          }
        },
        {
          dataField: "montant",
          text: "Montant",
          // formatter: (cell, row) => row.adresse,
        },
        {
          dataField: "adresse",
          text: "Paiement",
          formatter: (cell, row) => {
            const commande = row; // La commande spécifique à cette ligne
            return (
              <>
               {commande.montant_restant === 0 ? (
                <button className="btn fs-90 text-success">Payée</button>
              ) : commande.montant_restant === commande.montant ? (
                <button className="btn fs-90 text-danger">Impayée</button>
              ) : (
                <button className="btn fs-90 text-danger">En cours</button>
              )}
              </>
            );
          }
        },
        {
          dataField: "action",
          text: "Actions",
          formatter: (cell, row) => {
            return (
              <>
                <button
                  className="btn-icon edit-btn-icon me-4"
                // onClick={() => openUpdateModal(row)}
                ></button>
                <button
                  className="btn-icon delete-btn-icon"
                // onClick={() => deleteClient(row)}
                ></button>
              </>
            );
          },
        },
      ];
      return (
        <div className="p-0 m-0">
          {/* <DeleteCommande showModal={showDeleteModal} setShowModal={setShowDeleteModal} /> */}
         
          {
            commandeLivree?.length ? (
              <>
                {/* <InputFiltrer /> */}
                {/* <InputFiltrer onChange={(e) => {
                console.log(e.target.value)
                dispatch(filterCommandesBySearch(e.target.value))
              }} /> */}
                <CustomBootstrapTable
                  columns={columns}
                  data={commandeLivree}
                  // autoIncrementer="N°"
                  //   showPaginator={listClient.length > 10}
                  showPaginator={true}
                />
  
              </>
            ) : <p className="text-center p-5">pas de commande livrée</p>
          }
        </div>
      );
}

export default Livree