import { combineReducers, configureStore } from "@reduxjs/toolkit";
import users from "../reducers/users";
// import navbar from "../reducers/navbar";
import navigation from "../reducers/navigation";
import clients from "../reducers/clients";
import commandes from "../reducers/commandes";
import albums from "../reducers/albums";
import photos from "../reducers/photos";
import comptabilites from "../reducers/comptabilite";
import pages from "../reducers/pages";
import profile from "../reducers/profile";
import horraire from "../reducers/horraire";
import entreeArgent from "../reducers/entreeargent";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    users,
    clients,
    commandes,
    albums,
    photos,
    navigation,
    comptabilites,
    entreeArgent,
    profile,
    horraire,
    pages
   
})


const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)