import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ApiController from "../../redux/actions";
import CustomModal from "../utils_components/modals/CustomModal";
import "./add-client.css";
import { toast } from "react-toastify";
import { isValidMobilePhone, isValidText } from "../../utils/form_control";
import { useSelector } from "react-redux";
import { ressetCurrentClient } from "../../redux/reducers/clients";
import { useDispatch } from "react-redux";

// import { resetCurrentClient } from '../../redux/reducers/clients';

const UpdateClientForm = ({ showModal, setShowModal }) => {
  const currentClient = useSelector((state) => state.clients?.currentClient);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: currentClient,
    values: currentClient,
  });

  //   console.log({ currentClient: currentClient?.nom_complet });

  // eslint-disable-next-line no-unused-vars
  const [isFemale, setIsFemale] = useState(true);

  const [customFields, setCustomFields] = useState([
    { nomMesure: "" },
    { uniteMesure: "" },
  ]);

  // eslint-disable-next-line no-unused-vars
  const addCustomField = () => {
    if (customFields !== "") {
      // console.log(customFields.length)
      const newField = {
        nomMesure: "",
        uniteMesure: "",
      };
      setCustomFields([...customFields, newField]);
    } else {
      setCustomFields([{ nomMesure: "", uniteMesure: "" }]);
    }
  };

  const [validatePays, setValidatePay] = useState(false);
  const [validateVille, setValidateVille] = useState(false);

  const [isInvalidFields, setIsInvalidFields] = useState({
    cou: false,
    epaule: false,
    longueur_manche: false,
    ceinture: false,
    tour_bras: false,
    hanche: false,
    cuisse: false,
    longueur_haut: false,
    longueur_jupe: false,
    taille: false,
    longueur_poitrine: false,
    longueur_robe: false,
    longueur_boubou: false,
    poitrine: false,
    // ... Ajoutez ici les autres champs avec leur état d'erreur
  });

  const validateAge = (value) => {
    if (!value) return true;
    if (isNaN(value) || value < 1 || value > 999 || !/^\d{1,3}$/.test(value)) {
      return false;
    }
    return true;
  };

  const handleNumericInput = (e, fieldType) => {
    const inputValue = e.target.value + e.key;
    const newInvalidFields = { ...isInvalidFields };
    if (e.key !== "Backspace" && (isNaN(e.key) || !validateAge(inputValue))) {
      e.preventDefault();
      newInvalidFields[fieldType] = true;
    } else {
      newInvalidFields[fieldType] = false;
    }
    setIsInvalidFields(newInvalidFields);
  };

  const handlePays = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
      e.preventDefault();
      setValidatePay(true);
    } else {
      setValidatePay(false);
    }
  };
  const handleVille = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
      e.preventDefault();
      setValidateVille(true);
    } else {
      setValidateVille(false);
    }
  };

  // console.log(currentClient)

  const submit = (data) => {
    setIsLoading(true); // Activer la loader

    // console.log(data)
    ApiController.clients
      .updateClient(data, currentClient.id, currentUser?.id)
      .then(() => {
        // console.log(res)
        toast.success("Client modifié");
        setShowModal(false);
        // resetForm()
      })
      .catch(() => {
        // console.log(err);
        toast.error("erreur lors de la modification");
      })
      .finally(() => {
        setIsLoading(false); // Désactiver la loader
        // hideClientList(); // Montrer à nouveau la liste des clients
      });
  };
  const verificate = (data) => {
    let errorFields = [];

    if (!isValidText(data.nom_complet)) {
      errorFields.push({
        field: "nom_complet",
        message: "Ce nom n'est pas valid!",
      });
    }
    if (!isValidMobilePhone(data.telephone)) {
      errorFields.push({ field: "telephone", message: "Numéro invalid!" });
    }

    if (errorFields.length) {
      errorFields.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      submit(data);
    }
  };

  return (
    <CustomModal
      showModal={showModal}
      setShowModal={setShowModal}
      // onClose={resetForm}
      title="Modification client"
      titleIconClass="white-users-icon"
      onClose={() => dispatch(ressetCurrentClient())}
    >
      <form
        onSubmit={handleSubmit(verificate)}
        className="add-client row my-4 pb-4 fs-90 text-start"
      >
        <div className="col-lg space-client">
          <div className="bg-white mx-2 ms-md-3 rounded row pb-4 mb-4 px-2 py-2">
            <div>
              <p className="title mb-4">INFORMATIONS CLIENT</p>
            </div>
            <div className="">
              <div className="">
                Nom complet
                <input
                  {...register("nom_complet", {
                    required: "Champs obligatoire!",
                  })}
                  type="text"
                />
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.nom_complet?.message}
                </p>
              </div>
              <div className="mb-2 ">
                Téléphone
                <input
                  {...register("telephone", {
                    required: "Champs obligatoire!",
                  })}
                  type="tel"
                />
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.telephone?.message}
                </p>
              </div>
              <div className="mb-2 ">
                Adresse
                <input
                  {...register("adresse", { required: "Champs obligatoire!" })}
                  type="text"
                />
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.adresse?.message}
                </p>
              </div>
              <div className="mb-2 row bg-dange ">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      Pays
                      <input
                        {...register("pays")}
                        type="text"
                        defaultValue={"Sénégal"}
                        onKeyDown={handlePays}
                      />
                      {validatePays && (
                        <p style={{ color: "red" }}>
                          Veuillez entrer un nom de pays valide.
                        </p>
                      )}
                      {/* <p className='error-msg text-center'> &nbsp; {errors.pays?.message}</p> */}
                    </div>
                    <div className="col">
                      Ville
                      <input
                        {...register("Ville")}
                        type="text"
                        onKeyDown={handleVille}
                      />
                      {validateVille && (
                        <p style={{ color: "red" }}>
                          Veuillez entrer un nom de ville valide.
                        </p>
                      )}
                      {/* <p className='error-msg text-center'> &nbsp; {errors.Ville?.message}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2 d-flex justify-content-between">
                <p className="mx-1 mx-lg-3">
                  <label>
                    <input
                      onChange={() => setIsFemale(true)}
                      {...register("sexe")}
                      type="radio"
                      name="sexe"
                      value={"femme"}
                    />{" "}
                    Femme
                  </label>
                </p>
                <p className="mx-1 mx-lg-3">
                  <label>
                    <input
                      type="radio"
                      value={"homme"}
                      onChange={() => setIsFemale(false)}
                      {...register("sexe", {
                        required: "Selectionnez le sexe!",
                      })}
                    />{" "}
                    Homme
                  </label>
                </p>
              </div>
              <p className="error-msg text-center">
                {" "}
                &nbsp; {errors.sexe?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg mb-4 space-mesure">
          <div className="bg-white mx-2 me-md-3 rounded row pb-3 px-2 py-2">
            <div>
              <p className="title mb-4">MESURES CLIENT</p>
            </div>
            <div className="">
              {currentClient?.mesure_client ? (
                currentClient?.mesure_client.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between smallDeviceform"
                    >
                      <Controller
                        name={`mesure_client[${index}].nomMesure`}
                        control={control}
                        defaultValue={item?.nomMesure}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            placeholder="Title"
                            id="mesureTitle"
                            className="mb-2"
                          />
                        )}
                      />
                      <Controller
                        name={`mesure_client[${index}].uniteMesure`}
                        control={control}
                        defaultValue={item?.uniteMesure}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            placeholder="Mesure"
                            id="mesureTitle"
                          />
                        )}
                      />
                    </div>
                  );
                })
              ) : currentClient.sexe === "femme" ? (
                <>
                  <div className="d-flex justify-content-between">
                    <p>COU :</p>
                    <input
                      type="number"
                      {...register("cou", {})}
                      onKeyDown={(e) => handleNumericInput(e, "cou")}
                    />
                  </div>
                  {isInvalidFields.cou && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour COU.
                    </p>
                  )}
                  <div className=" d-flex justify-content-between">
                    <p>EPAULE :</p>
                    <input
                      type="number"
                      {...register("epaule")}
                      onKeyDown={(e) => handleNumericInput(e, "epaule")}
                    />
                  </div>
                  {isInvalidFields.epaule && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour epaule.
                    </p>
                  )}

                  <div className=" d-flex justify-content-between">
                    <p>MANCHES :</p>
                    <input
                      type="number"
                      {...register("longueur_manche")}
                      onKeyDown={(e) =>
                        handleNumericInput(e, "longueur_manche")
                      }
                    />
                  </div>
                  {isInvalidFields.longueur_manche && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour MANCHES.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>CEINTURE :</p>
                    <input
                      type="number"
                      {...register("ceinture")}
                      onKeyDown={(e) => handleNumericInput(e, "ceinture")}
                    />
                  </div>
                  {isInvalidFields.ceinture && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour CEINTURE.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE BRAS :</p>
                    <input
                      type="number"
                      {...register("tour_bras")}
                      onKeyDown={(e) => handleNumericInput(e, "tour_bras")}
                    />
                  </div>
                  {isInvalidFields.tour_bras && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE BRAS.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE HANCHES :</p>
                    <input
                      type="number"
                      {...register("hanche")}
                      onKeyDown={(e) => handleNumericInput(e, "hanche")}
                    />
                  </div>
                  {isInvalidFields.hanche && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE
                      HANCHES.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE CUISSE :</p>
                    <input
                      type="number"
                      {...register("cuisse")}
                      onKeyDown={(e) => handleNumericInput(e, "cuisse")}
                    />
                  </div>
                  {isInvalidFields.cuisse && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE
                      CUISSE .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR HAUT :</p>
                    <input
                      type="number"
                      {...register("longueur_haut")}
                      onKeyDown={(e) => handleNumericInput(e, "longueur_haut")}
                    />
                  </div>
                  {isInvalidFields.longueur_haut && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR HAUT
                      .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR JUPE :</p>
                    <input
                      type="number"
                      {...register("longueur_jupe")}
                      onKeyDown={(e) => handleNumericInput(e, "longueur_jupe")}
                    />
                  </div>
                  {isInvalidFields.longueur_jupe && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR JUPE
                      .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOURS DE TAILLE :</p>
                    <input
                      type="number"
                      {...register("taille")}
                      onKeyDown={(e) => handleNumericInput(e, "taille")}
                    />
                  </div>
                  {isInvalidFields.taille && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOURS DE
                      TAILLE .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR POITRINE :</p>
                    <input
                      type="number"
                      {...register("longueur_poitrine")}
                      onKeyDown={(e) =>
                        handleNumericInput(e, "longueur_poitrine")
                      }
                    />
                  </div>
                  {isInvalidFields.longueur_poitrine && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR
                      POITRINE .
                    </p>
                  )}
                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR ROBE :</p>
                    <input
                      type="number"
                      {...register("longueur_robe")}
                      onKeyDown={(e) => handleNumericInput(e, "longueur_robe")}
                    />
                  </div>
                  {isInvalidFields.longueur_robe && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR ROBE
                      .
                    </p>
                  )}
                  <div className="mb-2 d-flex justify-content-between">
                    <p>LONGUEUR BRAS:</p>
                    <input type="number" {...register("longueur_bras")} />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <p>COU :</p>
                    <input
                      type="number"
                      {...register("cou", {})}
                      onKeyDown={(e) => handleNumericInput(e, "cou")}
                    />
                  </div>
                  {isInvalidFields.cou && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour COU.
                    </p>
                  )}
                  <div className=" d-flex justify-content-between">
                    <p>EPAULE :</p>
                    <input
                      type="number"
                      {...register("epaule")}
                      onKeyDown={(e) => handleNumericInput(e, "epaule")}
                    />
                  </div>
                  {isInvalidFields.epaule && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour epaule.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>MANCHES :</p>
                    <input
                      type="number"
                      {...register("longueur_manche")}
                      onKeyDown={(e) => handleNumericInput(e, "epaule")}
                    />
                  </div>
                  {isInvalidFields.longueur_manche && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour MANCHES.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>CEINTURE :</p>
                    <input
                      type="number"
                      {...register("ceinture")}
                      onKeyDown={(e) => handleNumericInput(e, "ceinture")}
                    />
                  </div>
                  {isInvalidFields.ceinture && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour CEINTURE.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE BRAS :</p>
                    <input
                      type="number"
                      {...register("tour_bras")}
                      onKeyDown={(e) => handleNumericInput(e, "tour_bras")}
                    />
                  </div>
                  {isInvalidFields.tour_bras && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE BRAS
                      .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE HANCHES :</p>
                    <input
                      type="number"
                      {...register("hanche")}
                      onKeyDown={(e) => handleNumericInput(e, "hanche")}
                    />
                  </div>
                  {isInvalidFields.hanche && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE
                      HANCHES.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TOUR DE CUISSE :</p>
                    <input
                      type="number"
                      {...register("cuisse")}
                      onKeyDown={(e) => handleNumericInput(e, "cuisse")}
                    />
                  </div>
                  {isInvalidFields.cuisse && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour TOUR DE
                      CUISSE.
                    </p>
                  )}
                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR HAUT :</p>
                    <input
                      type="number"
                      {...register("longueur_haut")}
                      onKeyDown={(e) => handleNumericInput(e, "longueur_haut")}
                    />
                  </div>
                  {isInvalidFields.longueur_haut && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR
                      HAUT.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR BOUBOU :</p>
                    <input
                      type="number"
                      {...register("longueur_boubou")}
                      onKeyDown={(e) =>
                        handleNumericInput(e, "longueur_boubou")
                      }
                    />
                  </div>
                  {isInvalidFields.longueur_boubou && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR
                      BOUBOU.
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>LONGUEUR PANATALON :</p>
                    <input
                      type="number"
                      {...register("longueur_pantalon")}
                      onKeyDown={(e) =>
                        handleNumericInput(e, "longueur_pantalon")
                      }
                    />
                  </div>
                  {isInvalidFields.longueur_pantalon && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR
                      PANATALON .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>TAILLE :</p>
                    <input
                      type="number"
                      {...register("taille")}
                      onKeyDown={(e) => handleNumericInput(e, "taille")}
                    />
                  </div>
                  {isInvalidFields.taille && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour LONGUEUR
                      TAILLE .
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <p>POITRINE :</p>
                    <input
                      type="number"
                      {...register("poitrine")}
                      onKeyDown={(e) => handleNumericInput(e, "poitrine")}
                    />
                  </div>
                  {isInvalidFields.poitrine && (
                    <p style={{ color: "red" }}>
                      Veuillez entrer un nombre de 3 chiffres pour POITRINE.
                    </p>
                  )}

                  {/* <div className='mb-2 d-flex justify-content-between'>
                                    <p>
                                        LONGUEUR BRAS:
                                    </p>
                                    <input type="number" {...register('longueur_bras')} />
                                </div> */}
                </>
              )}

              {/* {
                                currentClient?.mesure_client ? <div className="d-flex justify-content-center mt-3">
                                <FiPlus type='button' onClick={addCustomField} size={30} />


                            </div>  :''
                            } */}
            </div>
          </div>
        </div>
        <div className="text-end pe-4">
          <button className="save-client-btn px-4" disabled={isLoading}>
            {isLoading ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              "Modifier"
            )}
          </button>
        </div>
      </form>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </CustomModal>
  );
};

export default UpdateClientForm;
