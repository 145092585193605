import React from 'react';
import './custom_modal.css'

const CustomModal = ({showModal=false,setShowModal,title='Title',titleIconClass='',children,onClose}) => {

    const closeModal = () => {
        try{
            onClose()
            setShowModal(false)
        }catch{
            setShowModal(false)
        }
    }

    if(showModal){
        return(
            <>
                <div className='custom-modal-overlay' onClick={closeModal}>
                </div>
                <div className="custom-modal custom-bg-light">
                    <header>
                        <canvas className={'icon ms-3 me-2 '+titleIconClass}></canvas>
                        <span>{title}</span>
                        <canvas onClick={closeModal} className='icon white-close-icon me-3 ms-auto'></canvas>
                    </header>
                    <div className='custom-bg-light body'>
                        {
                            children
                        }
                    </div>
                </div>
            </>
        )
    }
};

export default CustomModal;