// export const DEFAULT_API = 'http://127.0.0.1:8000/api/v1/'
// export const DEFAULT_API_IMAGE = 'http://127.0.0.1:8000/api'
export const DEFAULT_API = process.env.REACT_APP_DEFAULT_API
export const DEFAULT_API_IMAGE = process.env.REACT_APP_DEFAULT_API

// export const DEFAULT_API = 'http://167.99.253.190:8086/api/v1/'

export const REGISTER_API_ROUTE = DEFAULT_API +'users/'
export const LOGIN_API_ROUTE = DEFAULT_API +'login/'
export const UPDATE_USER_API_ROUTE = DEFAULT_API +'users/'// + {id}/
export const CHANGE_USER_PASSWORD_API_ROUTE = DEFAULT_API +'user/password/'// + {id}/

export const CLIENT_API_ROUTE = DEFAULT_API + 'clients/user/'
export const ADD_CLIENT_API_ROUTE = DEFAULT_API + 'clients'
export const UPDATE_CLIENT_API_ROUTE = DEFAULT_API +'clients/'// + {id}/
export const DETAIL_CLIENT_API_ROUTE = DEFAULT_API +'clients/'// + {id}/
export const DELETE_CLIENT_API_ROUTE = DEFAULT_API +'clients/'

export const MESURE_API_ROUTE = DEFAULT_API +'mesure'
export const GET_PROFILE_API_ROUTE = DEFAULT_API +'parametres/user/'
export const PROFILE_API_ROUTE = DEFAULT_API +'parametres/'

export const USER_API_ROUTE = DEFAULT_API +'user/'

export const ADD_COMMANDE_API_ROUTE = DEFAULT_API + 'commandes/'
export const GET_COMMANDE_BYID_API_ROUTE = DEFAULT_API + 'commandes/'
export const COMMANDE_API_ROUTE = DEFAULT_API +'commandes/user/'
export const DELETE_COMMANDE_API_ROUTE = DEFAULT_API +'commandes/'
export const UPDATE_COMMANDE_API_ROUTE = DEFAULT_API +'commandes/'

export const ADD_HORRAIRE_API_ROUTE = DEFAULT_API + 'horraires/'
export const HORRAIRE_API_ROUTE = DEFAULT_API +'horraires/user/'
export const DELETE_HORRAIRE_API_ROUTE = DEFAULT_API +'horraires/'
export const UPDATE_HORRAIRE_API_ROUTE = DEFAULT_API +'horraires/'

export const ADD_COMPTABILITE_API_ROUTE = DEFAULT_API + 'transaction/'
export const COMPTABILITE_API_ROUTE = DEFAULT_API +'comptabilite/'
export const DELETE_COMPTABILITE_API_ROUTE = DEFAULT_API +'comptabilite/'
export const UPDATE_COMPTABILITE_API_ROUTE = DEFAULT_API +'transaction/'

export const ADD_ENTREE_API_ROUTE = DEFAULT_API + 'entree/'
export const ENTREE_API_ROUTE = DEFAULT_API + 'entree/'

export const GET_ALBUM_API_ROUTE = DEFAULT_API +'album/user/'
export const ADD_ALBUM_API_ROUTE = DEFAULT_API +'album/'

export const PHOT_IN_ALBUM_API_ROUTE = DEFAULT_API +'photos/album'

export const ADD_PHOT_IN_ALBUM_API_ROUTE = DEFAULT_API +'photos/album'