/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import moment from 'moment';
import ok from '../../../img/icons/green-ok-icon-2.png';
import CustomBootstrapTable from "../../utils_components/tables/CustomBootstrapTable";
import "../../commandes/commandes.css";
import ApiController from "../../../redux/actions";
// import { toast } from "react-toastify";
// eslint-disable-next-line no-unused-vars
import  {filterCommandesBySearch, setCurrentCommande } from "../../../redux/reducers/commandes";
import { toast } from "react-toastify";
import { resetCurrentCommande } from "../../../redux/reducers/commandes";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import InputFiltrer from '../../utils_components/inputs/InputFiltrer';

   
const Terminee = ({listCommandes}) => {

  // const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)
  const [commandesTerminee, setCommandesTerminee] = useState([]);
  const [processingTerminee, setProcessingTerminee] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(state => state.users?.currentUser)

  const commandeTerminee = listCommandes?.filter(item => item.statut === "terminee")

 //  console.log(commandeTerminee)

  const dispatch = useDispatch();

  const handleStatusTerminee = (commandeId) => {
    if (processingTerminee.includes(commandeId)) {
      // La commande est déjà en cours de traitement, ne rien faire
      return;
    }
  
    setIsLoading(true);
    setProcessingTerminee((prevProcessingTerminee) => [...prevProcessingTerminee, commandeId]);
  
    const commande = listCommandes.find((cmd) => cmd.id === commandeId);
  
    if (commande && commande.statut !== 'livree') {
      dispatch(resetCurrentCommande());
      const formData = new FormData();
      formData.append('statut', 'livree');
      
      ApiController.commandes
      .updateCommande(formData, commandeId, currentUser.id)
      .then((res) => {
        dispatch(setCurrentCommande(res));
          toast.success('commande livrée');
          setCommandesTerminee((prevCommandes) => [...prevCommandes, commande]);
          setProcessingTerminee((prevProcessingTerminee) =>
          prevProcessingTerminee.filter((id) => id !== commandeId)
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error("erreur lors de la mis en livraison");
          setProcessingTerminee((prevProcessingTerminee) =>
          prevProcessingTerminee.filter((id) => id !== commandeId)
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
     setProcessingTerminee((prevProcessingTerminee) =>
     prevProcessingTerminee.filter((id) => id !== commandeId)
      );
    }
  
  };

    const columns = [
        {
          dataField: "id",
          text: "N°",
        },
        {
          dataField: "clientId",
          text: "Clients",
          formatter: (cell, row) => {
            if (row.client) {
              return row.client.nom_complet;
            } else {
              return "non defini";
            }
          },
  
        },
        {
          dataField: "date_livraison",
          text: "Date de livraison",
          formatter: (cell, row) => {
            const formattedDate = moment(row.date_livraison).format("DD/MM/YYYY HH:mm");
            return formattedDate;
          }
        },
        {
          dataField: "montant",
          text: "Montant",
          // formatter: (cell, row) => row.adresse,
        },
        {
          dataField: "adresse",
          text: "Status",
          formatter: (cell, row) => {
            const isLoading = processingTerminee.includes(row.id);
            return (
              <>
                {row.statut === 'terminee' ? (
                  <button
                    className="btn fs-90 shadow"
                    onClick={() => handleStatusTerminee(row.id)}
                    disabled={isLoading} // Désactiver le bouton pendant le traitement
                  >
                    {isLoading ? '...loading' : 'terminée'}
                  </button>
                ) : (
                  <p className="align-items-center">
                    Livrée
                  </p>
                )}
              </>
            );
          }
        },
        {
          dataField: "adresse",
          text: "Paiement",
          formatter: (cell, row) => {
            const commande = row; // La commande spécifique à cette ligne
            return (
              <>
               {commande.montant_restant === 0 ? (
                <button className="btn fs-90 text-success">Payée</button>
              ) : commande.montant_restant === commande.montant ? (
                <button className="btn fs-90 text-danger">Impayée</button>
              ) : (
                <button className="btn fs-90 text-danger">En cours</button>
              )}
              </>
            );
          }
        },
        {
          dataField: "action",
          text: "Actions",
          formatter: (cell, row) => {
            return (
              <>
                <button
                  className="btn-icon edit-btn-icon me-4"
                // onClick={() => openUpdateModal(row)}
                ></button>
                <button
                  className="btn-icon delete-btn-icon"
                // onClick={() => deleteClient(row)}
                ></button>
              </>
            );
          },
        },
      ];
    

    return (
        <div className="p-0 m-0">
  
        {/* <DeleteCommande showModal={showDeleteModal} setShowModal={setShowDeleteModal} /> */}
         
          {
            commandeTerminee?.length ? (
              <>
                {/* <InputFiltrer /> */}
                {/* <InputFiltrer onChange={(e) => {
                console.log(e.target.value)
                dispatch(filterCommandesBySearch(e.target.value))
              }} /> */}
                <CustomBootstrapTable
                  columns={columns}
                  data={commandeTerminee}
                  // autoIncrementer="N°"
                  //   showPaginator={listClient.length > 10}
                  showPaginator={true}
                />
  
              </>
            ) : <p className="text-center p-5">pas de commande terminée</p>
          }
        </div>
      );
}

export default Terminee