import React, { useState, useEffect } from "react";
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import { COMMANDES_PATH } from "../../utils/navigation_paths";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiUpload } from "react-icons/fi";
import ApiController from "../../redux/actions";
import { toast } from "react-toastify";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
// import moment from 'moment';

const UpdateCommande = () => {
  const currentCommande = useSelector(
    (state) => state.commandes?.currentCommande
  );
  const transactions = useSelector(
    (state) => state.commandes?.currentCommande?.transactions
  );

  const montantPaye = currentCommande?.transactions?.map((item) => {
    return item?.montant_paye;
  });

  // console.log(currentCommande)

  const sommeMontantPaye = montantPaye?.reduce((acc, montant) => {
    return acc + (montant || 0); // Assurez-vous de gérer les valeurs nulles ou undefined
  }, 0);

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      clientId: currentCommande?.clientId,
      nom_tissu: currentCommande?.nom_tissu,
      metre_tissu: currentCommande?.metre_tissu,
      montant: currentCommande?.montant,
      date_livraison: currentCommande?.date_livraison,
      montant_paye: sommeMontantPaye,
    },
  });

  const currentUser = useSelector((state) => state.users.currentUser);

  // eslint-disable-next-line no-unused-vars
  const [montantAvance, setMontantAvance] = useState(
    currentCommande?.montantAvance
  );
  const [montantRestant, setMontantRestant] = useState();
  const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState();
  // eslint-disable-next-line no-unused-vars
  const [montantState] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorState, setErrorState] = useState(false);
  // const [isInvalidInput, setIsInvalidInput] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isInvalidPrice, setIsInvalidPrice] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isInvalidAvance, setIsInvalidAvance] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const listClient = useSelector((state) => state.clients?.clientsList);

  // eslint-disable-next-line no-unused-vars
  const validatePrice = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };
  const validateAvance = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const handleAvance = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      (isNaN(e.key) || !validateAvance(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidAvance(true);
    } else {
      setIsInvalidAvance(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // var dateFormat = Date.parse(currentCommande?.date_livraison)
  // console.log(dateFormat)

  const calculateMontantRestant = (montantTotal, montantAvance) => {
    return montantTotal - montantAvance;
  };

  useEffect(() => {
    // const updatedMontantRestant = currentCommande?.montant - montantAvance;
    // setMontantRestant(updatedMontantRestant);

    setValue("montant_paye", parseInt(currentCommande?.montant_paye));
    const montantRestant = calculateMontantRestant(
      currentCommande.montant,
      currentCommande.montant_paye
    );

    setValue("montant_restant", montantRestant);

    const date_livraison = new Date(currentCommande?.date_livraison);
    const formattedDateLivraison = `${date_livraison.getFullYear()}-${(
      date_livraison.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date_livraison
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    setValue("date_livraison", formattedDateLivraison);
  }, [currentCommande, setValue]);

  const transactionIds = transactions?.map((transaction) => {
    return transaction?.id;
  });

  // console.log(currentCommande)

  const submit = (data) => {
    setIsLoading(true);

    // console.log(data)

    if (data.modele && data.modele[0]) {
      const newData = new FormData();
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      newData.append("clientId", parseInt(data.clientId));
      newData.append("nom_tissu", data.nom_tissu);
      newData.append("metre_tissu", parseInt(data.metre_tissu));
      newData.append("montant", parseInt(data.montant));
      newData.append("montant_paye", montantPayeFromApiCompta);
      newData.append("date_livraison", isoDate);
      newData.append("modele", data.modele[0]);
      newData.append("createdBy", currentUser.id);

      ApiController.commandes
        .updateCommande(newData, currentCommande?.id, currentUser?.id)
        .then((res) => {
          const newTransaction = {
            commande: res.id,
            montant_paye: montantPayeFromApiCompta,
            createdBy: currentUser.id,
          };
          ApiController.comptabilite
            .updateComptabilite(transactionIds, newTransaction)
            .then((res) => {
              console.log({ transaction: res });
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch((error) => {
              console.log(error);
            });
          toast.success("commande modifie avec succés !");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch((err) => {
          console.log(err);
          // setError('message', { message: 'Veuillez selecetionnez un client' });
          toast.error("Erreur lors de la modification de la commande");
        })
        .finally(() => {
          setIsLoading(false);
        });

      // console.log({NEW_COMMANDE:newData})
    } else {
      const new_data = new FormData();
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      new_data.append("clientId", parseInt(data.clientId));
      new_data.append("nom_tissu", data.nom_tissu);
      new_data.append("metre_tissu", parseInt(data.metre_tissu));
      new_data.append("montant", parseInt(data.montant));
      new_data.append("montant_paye", montantPayeFromApiCompta);
      new_data.append("date_livraison", isoDate);
      new_data.append("createdBy", currentUser.id);

      // console.log({NOiMAG:newCommande})

      ApiController.commandes
        .updateCommande(new_data, currentCommande?.id, currentUser?.id)
        .then((res) => {
          const newTransaction = {
            commande: res.id,
            montant_paye: montantPayeFromApiCompta,
            createdBy: currentUser.id,
          };

          // console.log(newTransaction)
          ApiController.comptabilite
            .updateComptabilite(transactionIds, newTransaction)
            .then((res) => {
              // console.log({transaction:res})
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch((error) => {
              console.log(error);
            });
          toast.success("Ajout commande réussi");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch((err) => {
          console.log(err);
          // setError('message', { message: 'Erreur lors de l\'ajout commande' });
          toast.error("Erreur lors de la mdfi commande");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <AdminInterfaceContainer>
      <div className="client">
        <nav aria-label="breadcrumb" className="app-breadcrumb">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item"
              onClick={() => navigate(COMMANDES_PATH)}
            >
              Liste des commandes
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit commande
            </li>
          </ol>
        </nav>

        <form onSubmit={handleSubmit(submit)} encType="multipart/form-data">
          <div className="add-commande-container">
            <div className="add-commande-client-section">
              {/* Client */}
              <h3 className="section-title">Client</h3>
              <div className="row section-content mb-4">
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-10">
                    <label htmlFor="clientId">Nom client</label>
                  </div>
                  <div className="col-xl-5 col-lg-7 col-md-10 col-sm-10 col-10">
                    <select
                      name="clientId"
                      id="clientId"
                      className="form-control"
                      defaultValue="default"
                      {...register("clientId", {
                        required: "Champ obligatoire!",
                      })}
                    >
                      <option value="default" disabled>
                        Choisissez un client
                      </option>
                      {listClient.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.nom_complet}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* Commandes */}
              <div className="row section-content mb-4">
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="nom_tissus">Nom du tissu</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="text"
                      name="nom_tissu"
                      id="nom_tissu"
                      className="form-control"
                      {...register("nom_tissu", {
                        required: "Champ obligatoire!",
                      })}
                    />
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="quantite">Unité</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="metre_tissu"
                      id="metre_tissu"
                      className="form-control"
                      {...register("metre_tissu", {
                        required: "Champ obligatoire!",
                      })}
                    />
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="prix">Prix confection</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montant"
                      id="montant"
                      className="form-control"
                      {...register("montant", {
                        required: "Champ obligatoire!",
                      })}
                    />
                  </div>
                </div>

                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="modele">Modèle</label>
                  </div>
                  <div className="modeleImage col-xl-5 col-lg-7">
                    <button className="modeleLabel">
                      <FiUpload /> Importer une image
                    </button>
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="modele"
                        style={{
                          marginTop: "5px",
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    )}
                    {currentCommande?.modele ? (
                      <img
                        src={DEFAULT_API_IMAGE + `${currentCommande.modele}`}
                        alt="modele"
                        style={{
                          marginTop: "5px",
                          width: "200px",
                          height: "200px",
                        }}
                      />
                    ) : null}
                    <input
                      type="file"
                      name="modele"
                      id="modele"
                      className="form-control"
                      {...register("modele")}
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                    {/* {
                      currentCommande?.modele ? <img src={DEFAULT_API_IMAGE + `${currentCommande?.modele}`} alt="modele" style={{ marginTop: '5px', width: '200px', height: '200px' }} />:''
                    } */}
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="date_livraison">Date de livraison</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="date"
                      name="date_livraison"
                      id="date_livraison"
                      className="form-control"
                      {...register("date_livraison", {
                        valueAsDate: true,
                        required: "Champ obligatoire!",
                      })}
                      // defaultValue={getValues('date_livraison')}
                    />
                  </div>
                </div>
              </div>

              {/* Paiement */}
              <h3 className="section-title">Modalité de paiement</h3>
              <div className="row section-content">
                <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="montant_avance">Montant avancé</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montantAvance"
                      id="montantAvance"
                      className="form-control"
                      {...register("montant_paye")}
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        const newMontantPaye = inputValue >= 0 ? inputValue : 0;
                        console.log(newMontantPaye);
                        const newMontantRestant =
                          currentCommande?.montant - newMontantPaye;

                        // Vérifier si montantPaye dépasse le montant total
                        if (newMontantPaye <= currentCommande?.montant) {
                          setMontantPayeFromApiCompta(newMontantPaye);
                          setMontantRestant(
                            newMontantRestant >= 0 ? newMontantRestant : 0
                          );
                          setErrorState(false);
                        } else {
                          // Afficher une erreur lorsque montantPaye dépasse le montant total
                          setMontantPayeFromApiCompta(montantPayeFromApiCompta);
                          setMontantRestant(montantRestant);
                          setErrorState(true);
                          // Afficher un message d'erreur ou utiliser une variable d'état pour indiquer une erreur
                          // Exemple : setErrorState(true);
                        }
                      }}
                      // defaultValue={montantPayeFromApiCompta}
                      // value={montantPayeFromApiCompta !== 0 ? montantPayeFromApiCompta : ''}
                      onKeyDown={handleAvance}
                    />
                    {errorState && (
                      <p className="error-msg">
                        {" "}
                        &nbsp; Le montant de l'avance ne peut pas dépasser le
                        montant du prix confection.
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="row m-2">
                  <div className="col-xl-2 col-lg-3">
                    <label htmlFor="montant_restant">Montant restant</label>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <input
                      type="number"
                      name="montant_restant"
                      id="montant_restant"
                      className="form-control"
                      min="0"
                      disabled
                      value={currentCommande.montant_paye ? currentCommande.montant - currentCommande.montant_paye : ''}
                    />
                  </div>
                </div> */}
              </div>
              {/* fin mode paiement */}
            </div>
            <div className="text-center mt-3">
              <button
                style={{
                  backgroundColor: "#E82828",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  fontWeight: "600",
                  fontSize: "14px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "10px 30px",
                  border: "none",
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                }}
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  "Modifier"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </AdminInterfaceContainer>
  );
};

export default UpdateCommande;
