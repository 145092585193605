/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"


let userData = localStorage.getItem('currentUser') ||'{}'

let initialState = {
    currentUser: {},
    listUsers :[]
}
const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        setCurrentUser:(state,{payload})=>{
            state.currentUser = payload
        },
        updateUser:(state,{payload})=>{
            let users = state.listUsers.filter(user => user.id !== payload.id)
            users = [payload,...users]
            state.listUsers = users
        
        },
        setUsersList:(state,{payload})=>{
            state.listUsers = payload
        },
        removeCurrentUser:(state,{payload})=>{
            state.currentUser = {}
        },
    }
})

export const { setCurrentUser,setUsersList,removeCurrentUser, updateUser} = usersSlice.actions
export default usersSlice.reducer