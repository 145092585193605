import React from 'react';
import './input_filtrer.css'

const InputFiltrer = ({label="Filtre",onChange,placeholder='Rechercher',children,containerClass=''}) => {
    return (
        <div className='row m-0 p-0 py-5'>
            <div className="col text-start">
                <span className='input-filtrer fw-100 fs-70 text-start px-2 my-4'>
                    <span className='text-secondary'>{label}</span>
                    <input onChange={onChange} className="search-input ms-4 px-3" placeholder={placeholder} />
                </span>
            </div>
            <div className={"col "+containerClass}>
                {children}
            </div>
        </div>
    );
};

export default InputFiltrer;