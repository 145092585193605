export const DEFAULT_PATH = "*";

/* ===================== AUTH PATHS =================== */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";

/* ===================== HOME PATH =================== */
export const ACCUEIL_PATH = "/";

/* ===================== COMMANDES PATHS =================== */
export const COMMANDES_PATH = "/commandes";
export const ADD_COMMANDE_PATH = "/add-commande";
export const DETAIL_COMMANDE_PATH = "/detail-commande/:id";
export const UPDATE_COMMANDE_PATH = "/update-commande/:id";
export const DETAIL_COMPTABLITE_PATH = "/detail-comptabilite/:id";

/* ===================== CLIENTS PATHS =================== */
export const CLIENTS_PATH = "/clients";
export const DETAIL_CLIENT_PATH = "/detail-client/:id";

/* ===================== COMPATIBILITÉ PATHS =================== */
export const COMPTABLITE_PATH = "/comptablite";

/* ===================== STOCK PATHS =================== */
export const STOCK_PATH = "/stock";

/* ===================== EMPLOYES PATHS =================== */
export const EMPLOYEES_PATH = "/employes";

/* ===================== GALERIE PATHS =================== */
export const GALERIE_PATH = "/galeries";
export const ADD_GALERIE_PATH = "/add-galerie";
export const DETAIL_GALERIE_PATH = "/detail-galerie/:id";

export const PARAMETRE_PATH = "/parametres";


export const DETAIL_ENTREE_PATH = "/detail-entree/:id";