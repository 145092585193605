/* eslint-disable no-unused-vars */
import React from 'react';
import './listcard.css'
import moment from 'moment';
import { DEFAULT_API_IMAGE } from '../../../routes/api_routes';
import { Link, useNavigate } from 'react-router-dom';
import { setCurrentCommande } from '../../../redux/reducers/commandes';
import { DETAIL_COMMANDE_PATH } from '../../../utils/navigation_paths';
import { useDispatch } from 'react-redux';

const ListCard = ({commandesList,title,path }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();



    const detailCommande = (item) => {
        dispatch(setCurrentCommande(item))
        const commandeId = item?.id; // ou tout autre moyen d'obtenir l'ID de la commande
        const detailCommande = DETAIL_COMMANDE_PATH.replace(":id", commandeId);
        navigate(detailCommande);
      }
    
    
    return (
        <div className='listcard col-12 col-sm-6 col-md  row m-0 p-0 text-center fw-100 mb-3'>
            {
                commandesList?.length ? (
                    <>
                          <div className=' p-1 card-container row'>
                
                <div className='p-0 m-0'>
                    <p className='title mb-1 fw-600'>{title} ({commandesList?.length})</p>
                    <hr  className='p-0 m-0'/>
                </div>
                <ul className='py-0 my-0'>
                    {commandesList?.map((commande,index) =>{
                        if(index < 5){
                            return(
                               
                                
                                <li key={index} className='listcard' onClick={() => detailCommande(commande)} >
                                    <div className='py-1'>
                                        <p className='p-0 m-0'>
                                            <img src={DEFAULT_API_IMAGE + `${commande?.modele}`} alt="" onError={e => e.currentTarget.src ="tayeur-logo.png"} />
                                        </p>
                                        <span className='fs-80 fw-600 p-0 m-0'>Commande de:  {commande?.client?.nom_complet}</span>
                                        <p className='fs-70 p-0 m-0'>Date: {moment(commande.date_livraison).format("DD/MM/YYYY HH:mm")}</p>
                                        
                                    </div>
                                </li>
                              
                            )
                        }
                    })}
                {commandesList?.length >5 && 
                    <p className='p-0 m-0 show-more my-3 row justify-content-end'>
                        Voir plus
                        <span className='icon next-icon'></span>
                    </p>
                }
                </ul>
            </div>
                    </>
                ):(
                    <div className='p-0 m-0'>
                    <p className='title mb-1 fw-600'>Pas de commande pour {title} </p>
                    <hr  className='p-0 m-0'/>
                </div>
                )
            }
          
        </div>
    );
};

export default ListCard;