import React, {useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ApiController from '../../redux/actions';
import { isValidMobilePhone } from '../../utils/form_control';
import { ACCUEIL_PATH } from '../../utils/navigation_paths';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/reducers/users';
// import { useSelector } from 'react-redux';
import { FiEye, FiEyeOff } from 'react-icons/fi';
// import { BsWhatsapp } from 'react-icons/bs';
import './login.css'
import Whatsap from '../utils_components/Whatsap';

// import ReactWhatsapp from 'react-whatsapp';

const LoginPage = ({ setHasAccount, setForgotPassword }) => {

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, setError } = useForm()
    const [visiblePassword, setVisiblePassword] = useState(false);

    const [isInvalidInput, setIsInvalidInput] = useState(false)


    const validatePhone = (value) => {
        if (!value) return true;
        if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
            return false;
        }
        return true;
    };

    const handleNumericInput = (e) => {
        // console.log(e);
        const inputValue = e.target.value + e.key;
        if (e.key !== 'Backspace' && e.key !== 'Tab' && (isNaN(e.key) || !validatePhone(inputValue))) {
            e.preventDefault();
            setIsInvalidInput(true);
        } else {
            setIsInvalidInput(false);
        }
    };





    const submit = (data) => {
        setIsLoading(true);
        ApiController.users.loginUser(data)
            .then(user => {
                dispatch(setCurrentUser(user))
                navigate(ACCUEIL_PATH)
            })
            .catch(() => {
                // console.log(err);
                setError('password', { message: 'Numéro ou mot de passe incorrect' })
            }).finally(() => {
                setIsLoading(false); // Désactiver la loader
            });
    }
    const verificate = (data) => {
        let errorFields = []

        if (!isValidMobilePhone(data.phone)) {
            errorFields.push({ field: 'phone', message: 'Ce numéro n\'est pas valide !' })
        }
        if (data.password.length < 4) {
            errorFields.push({ field: 'password', message: 'Mot de passe trop court' })
        }

        if (errorFields.length) {
            errorFields.map(err => {
                let { field, message } = err
                setError(field, { message })
                return err
            })
        } else {
            submit(data)
        }

    }
    return (
        <div className='text-center'>
            <p className='fw-bold mb-5 pb-3 fs-4'>CONNECTEZ-VOUS</p>
            <form onSubmit={handleSubmit(verificate)} className='mx-md-5 px-md-5' >
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon phone-icon"></div>
                    <div className="col-10">
                        <input {...register('phone', { required: 'Ce champ est obligatore!' })} placeholder='Téléphone' type="tel" onKeyDown={handleNumericInput}
                        />
                    </div>
                </label>
                {isInvalidInput && (
                    <p className="error-msg">Veuillez entrer un numero de telephone valide.</p>
                )}
                <p className='error-msg mb-4'>{errors?.phone?.message}</p>
                <label className='input-container row m-0 p-0 mb-3'>
                    <div className="col-2 icon password-icon"></div>
                    <div className="col-10">
                        <div className="d-flex">

                            <input type={visiblePassword ? "text" : "password"} {...register('password', { required: 'Ce champ est obligatore!' })} placeholder='Mot de passe' />
                            <div
                                style={{
                                    height: 41.5,
                                    width: 50,
                                    backgroundColor: "#fff",
                                    display: "grid",
                                    placeItems: "center",
                                    marginTop: 5,
                                    marginLeft: 0,
                                    // border: "2px solid gray",
                                    cursor: "pointer",
                                }}
                                className="login-input form-control px-2"
                                onClick={() => setVisiblePassword((v) => !v)}
                            >
                                {visiblePassword ? (
                                    <FiEye color="#000" />
                                ) : (
                                    <FiEyeOff color="#000" />
                                )}
                            </div>
                        </div>

                    </div>
                </label>
                <p className='error-msg'>{errors?.password?.message}</p>
                <p className='text-end mb-4'>
                    <button type='button' className="btn p-0" onClick={() => setForgotPassword(true)} style={{ fontSize: '14px' }}><span>Mot de passe oublié?</span></button>
                </p>
                <button className='primary-btn fw-bold'>
                    {
                        isLoading ? (
                            <div className="spinner-border text-light" role="status">
                            </div>
                        ) : 'Connexion'
                    }
                </button>
                <p className='text-center my-3 me-2' style={{ fontSize: '14px' }}>
                    Vous n'avez pas de compte? <button type='button' onClick={() => setHasAccount(false)} className="btn p-0 red-link"> S'inscrire</button>   
                  
                </p>
            </form>
            {/* <ReactWhatsapp number="782933656" className="btnWhatsapp">
                        <BsWhatsapp/>
                    </ReactWhatsapp> */}
                    <Whatsap/>
        </div>
    );
};

export default LoginPage;