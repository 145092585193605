import React, { useState } from 'react';
import LoginPage from './LoginPage';
import './auth.css'
import RegisterPage from './RegisterPage';
import ForgotPassword from './ForgotPassword';

const Auth = () => {
    const [hasAccount,setHasAccount] = useState(true)
    const [forgotPassword,setForgotPassword] = useState(false)
    return (
        <div className='auth row m-0 p-0 bg-white'>
            <div className="col-6 d-none d-md-flex auth-image-container">
                <div className="white-logo-container"></div>
            </div>

            <div className="col auth-form-container row m-0 p-0 align-items-center justify-content-center">
                {forgotPassword?(
                    <ForgotPassword/>
                ):(
                    <>
                        {
                            hasAccount?(
                                <LoginPage setHasAccount={setHasAccount} setForgotPassword={setForgotPassword} />
                            ):(
                                <RegisterPage setHasAccount={setHasAccount}/>
                            )
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default Auth;