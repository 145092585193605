import React from 'react'

const BreadCrumbComponent = ({onClick, text1, text2}) => {
  return (
    <nav aria-label="breadcrumb" className="app-breadcrumb container">
          <ol className="breadcrumb">
            
            <li
              className="breadcrumb-item border-bottom border-danger"
              onClick={onClick}
            >
              {text1}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {text2}
            </li>
          </ol>
        </nav>
  )
}

export default BreadCrumbComponent