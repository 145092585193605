/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"
import { clientGenerator } from "../../utils/data_generator"


let initialState = {
    horraire : {},
    listHorraire : []
}
const horraireSlice = createSlice({
    name:'horraires',
    initialState,
    reducers:{
        setListHorraires:(state,{payload})=>{
            state.listhorraires = payload
        },
        addNewHorraire:(state,{payload})=>{
            state.listHorraire = [...state.listHorraire,payload]
        },
        setCurrentHorraire:(state,{payload})=>{
            let listhorraires = state.listhorraires.filter(item => item.id !== payload.id)
            state.listHorraire = [...listhorraires,payload]
            state.currenthorraire = state.listHorraire.filter(item => item.id === payload)[0]
        },
        deleteCurrentHorraire:(state,{payload})=>{
            state.listHorraire = state.listHorraire.filter(item => item.id !== payload)
        },
    }
})

export const { addNewHorraire,setListHorraires,setCurrentHorraire,deleteCurrentHorraire} = horraireSlice.actions
export default horraireSlice.reducer