import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer'
import { CLIENTS_PATH } from '../../utils/navigation_paths'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './add-client.css'
import UpdateClientForm from './UpdateClientForm';
import './client.css'
// import '../clients/add-client.css'
import MesureComponent from '../utils_components/MesureComponent';
import ClientInfoComponent from '../utils_components/ClientInfoComponent';
import ApiController from '../../redux/actions';
import BreadCrumbComponent from '../utils_components/BreadCrumbComponent';

const DetailClient = () => {


    const navigate = useNavigate();
    let { id } = useParams();

    const currentUser = useSelector(state => state.users.currentUser)
    const currentClient = useSelector(state => state.clients?.currentClient)

    // console.log(currentClient.mesure_client)
    const [showUpdateModal, setShowUpdateModal] = useState(false)


    useEffect(() => {
        ApiController?.clients?.detailClient(id, currentUser?.id).then(() => {}).catch((e) => console.log(e))
    }, [id, currentUser?.id])

    const openUpdateModal = () => {
        // dispatch(setCurrentClient(item))
        setShowUpdateModal(true)
    }




    return (
        <AdminInterfaceContainer>

            <div className="container mt-5">
                {/* <nav aria-label="breadcrumb" className="app-breadcrumb">
                    <ol className="breadcrumb">
                        <li
                            className="breadcrumb-item"
                            onClick={() => navigate(CLIENTS_PATH)}
                        >
                           
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Details <span className='text-capitalize'>{currentClient?.nom_complet}</span>
                        </li>
                    </ol>

                </nav> */}
                <BreadCrumbComponent text1=" Liste des client" text2={`Detail client ${currentClient?.nom_complet}`} onClick={() => navigate(CLIENTS_PATH)}/>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="card-title">
                                    <h3 style={{ color: "#979797", fontWeight: 700 }} className='fs-14  text-uppercase'>Informations client</h3>
                                    <hr className='w-100' />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <ClientInfoComponent text="Nom Complet" Texvalue={currentClient?.nom_complet} />
                                    <ClientInfoComponent text="Telephone" Texvalue={currentClient?.telephone} />
                                    <ClientInfoComponent text="Adresse" Texvalue={currentClient?.adresse} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <ClientInfoComponent text="Sexe" Texvalue={currentClient?.sexe} />
                                    <ClientInfoComponent text="Pays" Texvalue={currentClient?.pays ? currentClient?.pays : "le pays n'est pas défini"} />
                                    <ClientInfoComponent text="Ville" Texvalue={currentClient?.Ville ? currentClient?.Ville : 'la ville n\est pas  défini'} />
                                </div>
                                {/*  */}

                                {/* <div className="col" >
                                    <p className='cleintGenre'>{currentClient?.sexe}</p>
                                    <p className='cleintGenre'>{currentClient?.pays}</p>
                                    <p className='cleintGenre'>{currentClient?.Ville ? currentClient?.Ville : 'pas defini'}</p>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-12">
                        <div className="card border-0 mesure">
                            <div className="card-body">
                                <div className="card-title">
                                    <h3 style={{ color: "#979797", fontWeight: 700 }} className='fs-14  text-uppercase'>Mesures client</h3>
                                    <hr className='w-100' />
                                </div>
                                <div className="row">
                                    {
                                        currentClient?.mesure_client ? (
                                            currentClient?.mesure_client.map((item, index) =>{
                                                return(
                                                    <div key={index} className="col-md-6 col-12 colcommande" >
                                                        <MesureComponent text={`${item?.nomMesure} :`} Texvalue={item?.uniteMesure} />
                                                    </div>
                                                )
                                            })
                                        ):(
                                            currentClient?.sexe === "femme" ? (
                                                <>
    
                                                    <div className="col-md-6 col-12 colcommande">
                                                        <MesureComponent text="COU :" Texvalue={currentClient.cou} />
                                                        <MesureComponent text="EPAULE :" Texvalue={currentClient.epaule} />
                                                        <MesureComponent text="MANCHE :" Texvalue={currentClient.longueur_manche} />
                                                        <MesureComponent text="CEINTURE :" Texvalue={currentClient.ceinture} />
                                                        <MesureComponent text="TOURS DE BRAS :" Texvalue={currentClient.tour_bras} />
                                                        <MesureComponent text="TOUR DE HANCHE :" Texvalue={currentClient.hanche} />
    
    
    
                        
                                                    </div>
    
    
                                                    <div className="col-md-6 col-12">
                                                        <MesureComponent text="LONGEUR JUPE :" Texvalue={currentClient.longueur_jupe} />
                                                        <MesureComponent text="LONGUEUR ROBE :" Texvalue={currentClient.longueur_robe} />
                                                        <MesureComponent text="POITRINE :" Texvalue={currentClient.poitrine} />
                                                        <MesureComponent text="TOUR DE TAILLE :" Texvalue={currentClient.taille} />
                                                        <MesureComponent text="TOURS de CUISSE :" Texvalue={currentClient.cuisse} />
                                                        <MesureComponent text="LONGUEUR HAUT :" Texvalue={currentClient.longueur_haut} />
    
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="col-md-6 col-12">
    
                                                        <MesureComponent text="COU :" Texvalue={currentClient.cou} />
                                                        <MesureComponent text="EPAULE :" Texvalue={currentClient.epaule} />
                                                        <MesureComponent text="MANCHE :" Texvalue={currentClient.longueur_manche} />
                                                        <MesureComponent text="CEINTURE :" Texvalue={currentClient.ceinture} />
                                                        <MesureComponent text="TOURS DE BRAS :" Texvalue={currentClient.tour_bras} />
                                                        <MesureComponent text="TOUR DE HANCHE :" Texvalue={currentClient.hanche} />
    
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <MesureComponent text="LONGEUR BOUBOU :" Texvalue={currentClient.longueur_boubou} />
                                                        <MesureComponent text="LONGEUR PANTALON :" Texvalue={currentClient.longueur_pantalon} />
                                                        <MesureComponent text="POITRINE :" Texvalue={currentClient.poitrine} />
                                                        <MesureComponent text="TOUR DE TAILLE :" Texvalue={currentClient.taille} />
                                                        <MesureComponent text="TOURS de CUISSE :" Texvalue={currentClient.cuisse} />
                                                        <MesureComponent text="LONGUEUR HAUT :" Texvalue={currentClient.longueur_haut} />
    
    
                                                    </div>
    
                                                </>
                                            )
                                        )
                                    }

                                 

                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center py-3'>

                            <button className='add-client-btn fs-80 px-4 fw-600' onClick={openUpdateModal}>Modifier</button>
                        </div>

                    </div>

                </div>
                <UpdateClientForm showModal={showUpdateModal} setShowModal={setShowUpdateModal} />
            </div>



        </AdminInterfaceContainer>
    )
}

export default DetailClient
