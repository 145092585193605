import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import { COMMANDES_PATH } from "../../utils/navigation_paths";
import { useSelector } from "react-redux";
import moment from "moment";
import { BsImageFill } from "react-icons/bs";
import "../clients/add-client.css";
import "./detailCommande.css";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
import MesureComponent from "../utils_components/MesureComponent";
import ClientInfoComponent from "../utils_components/ClientInfoComponent";
import { setCurrentCommande } from "../../redux/reducers/commandes";
// import { useDispatch } from 'react-redux';
import BreadCrumbComponent from "../utils_components/BreadCrumbComponent";
import ApiController from "../../redux/actions";
import Skeleton from "react-loading-skeleton";
import { Button, Modal } from "react-bootstrap";
import logo from "../../img/tayeur-logo1.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import imprimer from "../../img/file-text.png";
import printer from "../../img/printer.png";
import download from "../../img/fa_download.png";
import supprlmer from "../../img/trash-2.png";
import Vector from "../../img/Vector.png";
import DeleteCommande from "./DeleteCommande";

const DetailCommande = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentCommande = useSelector(
    (state) => state.commandes?.currentCommande
  );
  console.log({ currentCommande });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const modalContentRef = useRef();
  useEffect(() => {
    ApiController.commandes.getCommandeById(id).then(() => setLoading(false));
  }, []);

  const montantPaye = currentCommande?.transactions?.map((item) => {
    return item?.montant_paye;
  });

  // console.log(montantPaye)

  const sommeMontantPaye = montantPaye?.reduce((acc, montant) => {
    return acc + (montant || 0); // Assurez-vous de gérer les valeurs nulles ou undefined
  }, 0);

  const afficherFacturation = currentCommande?.montant_restant === 0;

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handlePrint = () => {
    const content = modalContentRef.current;
    const originalDocument = document.body.innerHTML;
    document.body.innerHTML = content.innerHTML;

    window.print();

    document.body.innerHTML = originalDocument;
    window.location.reload(); // Rafraîchit la page pour éviter de modifier l'affichage du contenu
  };

  const handleDownloadPDF = () => {
    const content = modalContentRef.current;

    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("modal_content.pdf");
    });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = (item) => {
    dispatch(setCurrentCommande(item));
    setShowDeleteModal(true);
  };

  return (
    <AdminInterfaceContainer>
      <div className="client my-5">
        <BreadCrumbComponent
          text1="Liste des commandes"
          text2="detail commande"
          onClick={() => navigate(COMMANDES_PATH)}
        />
        <div className="container">
          {/* Mesure  */}
          <div className="card border-0 p-3 mb-3">
            <h1 className="fs-14" style={{ textAlign: "center" }}>
              Mesure client
            </h1>
            <hr className="hr-lg" />
            <div className="row ">
              {currentCommande?.client?.mesure_client ? (
                currentCommande?.client?.mesure_client.map((item, idx) => {
                  return (
                    <div key={idx} className="col-md-6 col-12 colcommande">
                      <MesureComponent
                        text={`${item?.nomMesure} :`}
                        Texvalue={item?.uniteMesure}
                      />
                    </div>
                  );
                })
              ) : currentCommande?.client?.sexe === "femme" ? (
                <>
                  <div className="col-md-6 col-12 colcommande">
                    <MesureComponent
                      text="COU :"
                      Texvalue={currentCommande?.client?.cou}
                    />
                    <MesureComponent
                      text="EPAULE :"
                      Texvalue={currentCommande?.client?.epaule}
                    />
                    <MesureComponent
                      text="MANCHE :"
                      Texvalue={currentCommande?.client?.longueur_manche}
                    />
                    <MesureComponent
                      text="CEINTURE :"
                      Texvalue={currentCommande?.client?.ceinture}
                    />
                    <MesureComponent
                      text="TOURS DE BRAS :"
                      Texvalue={currentCommande?.client?.tour_bras}
                    />
                    <MesureComponent
                      text="TOUR DE HANCHE :"
                      Texvalue={currentCommande?.client?.hanche}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <MesureComponent
                      text="LONGEUR JUPE :"
                      Texvalue={currentCommande?.client?.longueur_jupe}
                    />
                    <MesureComponent
                      text="LONGUEUR ROBE :"
                      Texvalue={currentCommande?.client?.longueur_robe}
                    />
                    <MesureComponent
                      text="POITRINE :"
                      Texvalue={currentCommande?.poitrine}
                    />
                    <MesureComponent
                      text="TOUR DE TAILLE :"
                      Texvalue={currentCommande?.client?.taille}
                    />
                    <MesureComponent
                      text="TOURS de CUISSE :"
                      Texvalue={currentCommande?.client?.cuisse}
                    />
                    <MesureComponent
                      text="LONGUEUR HAUT :"
                      Texvalue={currentCommande?.client?.longueur_haut}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6 col-12">
                    <MesureComponent
                      text="COU :"
                      Texvalue={currentCommande?.client?.cou}
                    />
                    <MesureComponent
                      text="EPAULE :"
                      Texvalue={currentCommande?.client?.epaule}
                    />
                    <MesureComponent
                      text="MANCHE :"
                      Texvalue={currentCommande?.client?.longueur_manche}
                    />
                    <MesureComponent
                      text="CEINTURE :"
                      Texvalue={currentCommande?.client?.ceinture}
                    />
                    <MesureComponent
                      text="TOURS DE BRAS :"
                      Texvalue={currentCommande?.client?.tour_bras}
                    />
                    <MesureComponent
                      text="TOUR DE HANCHE :"
                      Texvalue={currentCommande?.client?.hanche}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <MesureComponent
                      text="LONGEUR BOUBOU :"
                      Texvalue={currentCommande?.client?.longueur_boubou}
                    />
                    <MesureComponent
                      text="LONGEUR PANTALON :"
                      Texvalue={currentCommande?.client?.longueur_pantalon}
                    />
                    <MesureComponent
                      text="POITRINE :"
                      Texvalue={currentCommande.client?.poitrine}
                    />
                    <MesureComponent
                      text="TOUR DE TAILLE :"
                      Texvalue={currentCommande.client?.taille}
                    />
                    <MesureComponent
                      text="TOURS de CUISSE :"
                      Texvalue={currentCommande.client?.cuisse}
                    />
                    <MesureComponent
                      text="LONGUEUR HAUT :"
                      Texvalue={currentCommande?.client?.longueur_haut}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* fin mesure */}

          <div className="row">
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  <h1 className="fs-14" style={{ textAlign: "center" }}>
                    Detail commande
                  </h1>
                  <hr className="hr-lg" />
                  <div className="row">
                    {loading ? (
                      <Skeleton count={6} duration={5} />
                    ) : (
                      <>
                        <div className="col-md-4 col-12">
                          <ClientInfoComponent
                            text="Nom client"
                            Texvalue={currentCommande?.client?.nom_complet}
                          />
                          <ClientInfoComponent
                            text="Date Commande"
                            Texvalue={moment(
                              currentCommande.date_Commande
                            ).format("DD/MM/YYYY")}
                          />
                          <ClientInfoComponent
                            text="Telephone"
                            Texvalue={currentCommande?.client?.telephone}
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <ClientInfoComponent
                            text="Date Livraison"
                            Texvalue={moment(
                              currentCommande?.date_livraison
                            ).format("DD/MM/YYYY")}
                          />
                          <ClientInfoComponent
                            text="status Commande"
                            Texvalue={currentCommande.statut}
                          />
                          <ClientInfoComponent
                            text="Etat paiement"
                            Texvalue={
                              currentCommande?.montant_restant === 0
                                ? "Payée"
                                : currentCommande?.montant_restant ===
                                  currentCommande?.montant
                                ? "Impayée"
                                : "En cours"
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Detail Confection */}
          <div className="row mt-3">
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  <h1 className="fs-14" style={{ textAlign: "center" }}>
                    Detail Confection
                  </h1>
                  <hr className="hr-lg" />
                  <div className="row">
                    <div className="col-md-6 col-12">
                      {loading ? (
                        <Skeleton count={5} />
                      ) : (
                        <>
                          <ClientInfoComponent
                            text="Type tissu"
                            Texvalue={currentCommande?.nom_tissu}
                          />
                          <ClientInfoComponent
                            text="longueur tissu"
                            Texvalue={currentCommande?.metre_tissu + " m"}
                          />
                          <ClientInfoComponent
                            text="montant tissu"
                            Texvalue={currentCommande?.montant + " FCFA"}
                          />

                          <ClientInfoComponent
                            text="montant avance"
                            Texvalue={
                              sommeMontantPaye + " FCFA"
                                ? sommeMontantPaye + " FCFA"
                                : "pas d'avance"
                            }
                          />
                          <ClientInfoComponent
                            text="montant restant"
                            Texvalue={
                              currentCommande?.montant_restant + " FCFA"
                            }
                          />
                        </>
                      )}
                    </div>

                    <div className="col-md-6 col-12">
                      <div>
                        {currentCommande.modele ? (
                          <img
                            src={
                              DEFAULT_API_IMAGE + `${currentCommande?.modele}`
                            }
                            alt={currentCommande.nom_tissu}
                            height="200"
                            width="200"
                            style={{ objectFit: "fill" }}
                          />
                        ) : (
                          <BsImageFill size={80} color="#E82828" />
                        )}
                      </div>
                      <div className="d-flex flex-column justify-content-between align-items-end facture">
                        {/* ... (autre contenu du composant) */}
                        <DeleteCommande
                          showModal={showDeleteModal}
                          setShowModal={setShowDeleteModal}
                        />
                        <div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginRight: "14px" }}
                            onClick={() => openDeleteModal(row)}
                          >
                            <img src={supprlmer} />
                            &nbsp; supprimer
                          </button>

                          {/* Condition pour afficher le bouton de facturation si la commande est payée */}
                          {afficherFacturation ? (
                            <button
                              onClick={handleShow}
                              type="button"
                              className="btn btn-secondary"
                            >
                              <img src={imprimer} />
                              &nbsp;Facture
                            </button>
                          ) : (
                            <button type="button" className="btn btn-secondary">
                              <img src={Vector} />
                              &nbsp;&nbsp;Payer
                            </button>
                          )}
                        </div>
                        <Modal show={showModal} onHide={handleClose}>
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body ref={modalContentRef}>
                            <div className="row d-flex justify-content-between">
                              <div className="d-flex justify-content-between">
                                <div className="col-md-6">
                                  <img src={logo} />
                                </div>
                                <div className="col-md-8 facturation">
                                  <h3 className="">Facture N° </h3>
                                  <ClientInfoComponent
                                    text="Date Commande"
                                    Texvalue={moment(
                                      currentCommande.date_Commande
                                    ).format("DD/MM/YYYY")}
                                  />
                                  <ClientInfoComponent
                                    text="Date Livraison"
                                    Texvalue={moment(
                                      currentCommande?.date_livraison
                                    ).format("DD/MM/YYYY")}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="info mt-4">
                              <div className="d-flex justify-content-between">
                                <div className="col-md-6">
                                  <p>Tayeur</p>
                                  <p>
                                    {currentCommande?.user?.firstName}{" "}
                                    {currentCommande?.user?.lastName}
                                  </p>
                                  <p>Hlm Grand Yoff</p>
                                  <p>Tel: {currentCommande?.user?.phone}</p>
                                </div>
                                <div className="col-md-8  text-right">
                                  <p className="">client </p>
                                  <p>{currentCommande?.client?.nom_complet}</p>
                                  <p>Hlm Grand Yoff</p>
                                  <p>
                                    Tel:{currentCommande?.client?.telephone}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Le tableau Bootstrap que vous avez demandé */}
                            <table className="table table-bordered mt-4">
                              <thead>
                                <tr
                                  style={{
                                    backgroundColor: "black",
                                    color: "white",
                                  }}
                                >
                                  <th>Désignation</th>
                                  <th>Quantité</th>
                                  <th>Prix</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Insérez ici les lignes du tableau avec les données dynamiques */}
                                <tr>
                                  <td>{currentCommande?.nom_tissu}</td>
                                  <td>1</td>
                                  <td>{currentCommande?.montant}</td>
                                  <td>{currentCommande?.montant_paye}</td>
                                </tr>
                                {/* ... Autres lignes ... */}
                              </tbody>
                            </table>
                            <div className="col-md-12 ml-auto text-border">
                              <div>
                                <ClientInfoComponent
                                  text="montant restant"
                                  Texvalue={
                                    currentCommande?.montant_restant + " FCFA"
                                  }
                                />
                              </div>
                              <div>
                                <ClientInfoComponent
                                  text="montant avance"
                                  Texvalue={
                                    sommeMontantPaye + " FCFA"
                                      ? sommeMontantPaye + " FCFA"
                                      : "pas d'avance"
                                  }
                                />
                              </div>
                              <div>
                                <ClientInfoComponent
                                  text="montant payer"
                                  Texvalue={currentCommande?.montant + " FCFA"}
                                />
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleDownloadPDF}
                            >
                              <img src={download} />
                              &nbsp;Telecharger
                            </Button>
                            <Button variant="danger" onClick={handlePrint}>
                              <img src={printer} />
                              &nbsp;Imprimer
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* fin detail confection */}
          {/* Condition pour afficher le bouton de facturation si la commande est payée */}
        </div>
      </div>
    </AdminInterfaceContainer>
  );
};

export default DetailCommande;
