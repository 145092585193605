/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GoThreeBars } from "react-icons/go";
// import { ImCross } from "react-icons/im";


import { setCurrentItem } from '../../../redux/reducers/navigation';
import Header from '../../header/Header';
import SideBar from '../../sidebar/SideBar';
import './interface.css'
// import TabNavigation from '../../tabNavigation';
import Whatsap from '../Whatsap';

const AdminInterfaceContainer = ({ children, path }) => {

    const dispatch = useDispatch()


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarButtonVisible, setIsSidebarButtonVisible] = useState(true);


    useEffect(() => {
        if (path) {
            dispatch(setCurrentItem(path))
        }
    }, [dispatch, path])


    const handleSidebarOpen = () => {
        setIsSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setIsSidebarOpen(false);

    };


    return (
        <>
            <div className='admin-interface custom-bg-light min-height-100'>
                <div className="d-lg-none">
                    <div className='d-flex align-items-center justify-content-between'>

                        {
                            !isSidebarOpen && isSidebarButtonVisible &&

                            <GoThreeBars className='icon-open-side' onClick={handleSidebarOpen} size={30} />
                        }
                        {isSidebarOpen && <SideBar isOpen={isSidebarOpen} disabled={isSidebarOpen} onClose={handleSidebarClose} />
                        }
                    </div>
                </div>
                <div className="sidebar-container m-0 p-0 d-none d-lg-block">
                    <div className="d-none d-lg-block">
                        <SideBar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
                    </div>
                </div>
                <div className="body-container">
                    <div className="fixed-header d-flex justify-content-end">
                        <Header />
                    </div>
                        {/* <p>hello</p> */}
                    <div className={`body ${isSidebarOpen ? "body-fixed": ""}`}>
                        {children}
                    <Whatsap/>
                    {/* <TabNavigation/> */}
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default AdminInterfaceContainer;