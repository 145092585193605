import all from './all';
import users from './users';
import clients from './clients';
import mesures from './mesures';
import commandes from './commandes';
import albums from './albums';
import photos from './photos';
import comptabilite from './comptabilites';
import entrees from './entreeargent'
import profiles from './profiles';

const ApiController = {
    users,
    clients,
    all,
    mesures,
    commandes,
    albums,
    photos,
    comptabilite,
    entrees,
    profiles
}
export default ApiController