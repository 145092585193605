import React, { useState } from "react";
import DeleteModal from "../utils_components/modals/DeleteModal";
import { useSelector } from "react-redux";
import ApiController from "../../redux/actions";
import { toast } from "react-toastify";

const DeleteCommande = ({ showModal, setShowModal }) => {
  const currentCommande = useSelector(
    (state) => state.commandes?.currentCommande
  );
  const currentUser = useSelector((state) => state.users?.currentUser);
  const [isLoading, setIsLoading] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  const handeleDeleteCommande = () => {
    setIsLoading(true);
    let commandeId = currentCommande.id;
    ApiController.commandes
      .deleteCommande(commandeId, currentUser.id)
      .then(() => {
        closeModal();
        toast.success("Commande supprimé");
      })
      .catch((err) => {
        console.log(err);
        toast.error("commande non supprimé");
      })
      .finally(() => {
        setIsLoading(false); // Désactiver la loader
      });
  };
  return (
    <DeleteModal
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={closeModal}
      title="Suppression commande"
      titleIconClass="white-users-icon"
    >
      <div className="row m-0 p-0">
        <p className="text-center py-3">
          Voulez vous vraiment supprimer cette commande?
        </p>
        <p className="text-center">
          <button
            type="submit"
            className=" btn btn-secondary text-white   py-2 px-3 mx-3 mt-3"
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            style={{
              backgroundColor: "#E82828",
              color: "#FFFFFF",
              borderRadius: "5px",
              fontWeight: "600",
              fontSize: "14px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              padding: "10px 30px",
              border: "none",
              fontStyle: "normal",
              fontFamily: "Montserrat",
            }}
            disabled={isLoading}
            onClick={handeleDeleteCommande}
            type="submit"
          >
            {isLoading ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              "Supprimer"
            )}
          </button>
        </p>
      </div>
    </DeleteModal>
  );
};

export default DeleteCommande;
