import React, { useState } from 'react'
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer'
import ProfileTab from './ProfileTab'
import HorraireTab from './HorraireTab'
import './parametre.css'
import TabCompnent from '../utils_components/TabCompnent'
import TabContent from '../utils_components/TabContent'

const Parametre = () => {
    const [activeTab, setActiveTab] = useState("tab1");


    return (
        <AdminInterfaceContainer>
            <div className="container-fluid">

            <div className="Tabs my-5">
                {/* Tab nav */}
                <ul className="nav">
                    <TabCompnent title="Profile" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} />
                    <TabCompnent title="Horraires" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />

                </ul>
                <div className="outlet">
                    <TabContent id="tab1" activeTab={activeTab}>
                        <ProfileTab />
                    </TabContent>
                    <TabContent id="tab2" activeTab={activeTab}>
                        <HorraireTab />

                    </TabContent>



                </div>
            </div>
            </div>


        </AdminInterfaceContainer>
    )
}

export default Parametre