/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer';
import { COMMANDES_PATH } from '../../utils/navigation_paths';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { BsImageFill } from 'react-icons/bs';
import '../clients/add-client.css'
import { DEFAULT_API_IMAGE } from '../../routes/api_routes';
import ClientInfoComponent from '../utils_components/ClientInfoComponent';



const DetailEntree = () => {
  const navigate = useNavigate();
  const currentCommande = useSelector(state => state.commandes?.currentCommande)
  const [showMesureModal,setShowMesureModal] = useState(false)
  const currentCommandes = useSelector(state => state.commandes?.currentCommande?.client)
  // console.log( currentCommande)
  const dateL = moment(currentCommande.date_livrainson);
  const dateC = moment(currentCommande.date_commande);

  const date_livrainson = dateL.format('YYYY-MM-DD HH:mm');
  const date_Commande = dateC.format('YYYY-MM-DD HH:mm');

  const openMesurModal = () =>{
    setShowMesureModal(true)
  }




  return (
    <AdminInterfaceContainer>
      <div className="client">
      

        <div className="container">

          <div className="row">
          <div className="col-12">
            <div className="card border-0">
              <div className="card-body">
                <h1 className='fs-14' style={{textAlign:'center'}}>Detail commande Paiement</h1>
                <hr className='hr-lg'/>
                <div className="row">
                    <div className="col-md-6 col-12">
                      <ClientInfoComponent text="Numero commande" Texvalue={currentCommande?.numero_commande}/>
                      <ClientInfoComponent text="Nom client" Texvalue={currentCommande?.client?.nom_complet}/>
                      <ClientInfoComponent text="Date Commande" Texvalue={date_Commande}/>
                      <ClientInfoComponent text="Telephone" Texvalue={currentCommande?.client?.telephone}/>
                    </div>
                    <div className="col-md-6 col-12 ">
                    <ClientInfoComponent text="Date Livraison" Texvalue={date_livrainson}/>
                    <ClientInfoComponent text="status Commande" Texvalue={currentCommande.statut}/>
                    <ClientInfoComponent text="Etat paiement" Texvalue={currentCommande?.montant_restant === 0 ? (
                        <p>Payée</p>
                      ) : currentCommande?.montant_restant === currentCommande?.montant ? (
                        <p>Impayée</p>
                      ) : (
                        <p>En cours</p>
                      )}/>
                     
                    </div>
                   
                  </div>
              </div>
            </div>
          </div>
          </div>
         
          {/* Detail Confection */}
          <div className="row mt-3">
          <div className="col-12">
            <div className="card border-0">
              <div className="card-body">
                <h1 className='fs-14' style={{textAlign:'center'}}>Detail paiement</h1>
                <hr className='hr-lg'/>
                <div className="row">
                <div className="col-md-6 col-12">
                      
                        <ClientInfoComponent text="montant tissu" Texvalue={currentCommande?.montant + ' FCFA'}/>
                        
                        <ClientInfoComponent text="montant avance" Texvalue={currentCommande?.montant_paye  + ' FCFA' ? currentCommande?.montant_paye  + ' FCFA' : "pas d'avance"}/>
                        <ClientInfoComponent text="montant restant" Texvalue={currentCommande?.montant_restant  + ' FCFA' ? currentCommande?.montant_restant  + ' FCFA' : " pas de restant "}/>
                    
                    </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </AdminInterfaceContainer>
  )
}

export default DetailEntree