import axios from "axios"
import { store } from "../store/store"
import { addNewProfile, setProfile } from "../reducers/profile"
// eslint-disable-next-line no-unused-vars
import { ADD_PROFILE_API_ROUTE, GET_PROFILE_API_ROUTE, PROFILE_API_ROUTE } from "../../routes/api_routes"


const dispatch = store.dispatch


const profiles = {
    getProfile(userId){
        return new Promise((resolve,reject)=>{
            axios.get(GET_PROFILE_API_ROUTE + `${userId}/`)
            .then(response => {
                console.log(response)
                if(response.status === 200){
                    let profile = response.data
                    dispatch(setProfile(profile))
                    resolve(profile)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerProfile(data){
        return new Promise((resolve,reject)=>{
            axios.post(PROFILE_API_ROUTE,data)
            .then(response => {
                console.log(response)
                if(response.status ===201){
                    let profile = response.data
                    dispatch(addNewProfile(profile))
                    resolve(profile)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    // updateProfile(data,clieni_id, userId){
       
    //     return new Promise((resolve,reject)=>{
    //         axios.put(`${'UPDATE_CLIENT_API_ROUTE' + clieni_id}/`,data)
    //         .then(response => {
    //             if(response.status ===200){
    //                 let client = response.data
    //                 dispatch(updateClientInList(client))
    //                 this.detailClient(clieni_id,userId)
    //                 this.getClientsList(userId)
    //                 resolve(client)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // detailClient(clieni_id, userId){
       
    //     return new Promise((resolve,reject)=>{
    //         axios.get(`${DETAIL_CLIENT_API_ROUTE + clieni_id}/`)
    //         .then(response => {
    //             if(response.status ===200){
    //                 let client = response.data
    //                 // console.log(client)
    //                 dispatch(setCurrentClient(client))
    //                 // this.getClientsList(userId)
    //                 resolve(client)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // deleteClient(clieni_id, userId){
    //     return new Promise((resolve,reject)=>{
    //         axios.delete(`${DELETE_CLIENT_API_ROUTE + clieni_id}/`)
    //         .then(response => {
    //             if(response.status ===204){
    //                 dispatch(archiveClientInList(clieni_id))
    //                 resolve(response)
    //                 this.getClientsList(userId)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
}
export default profiles