import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet,useLocation } from 'react-router-dom';
import { LOGIN_PATH } from '../utils/navigation_paths';

const PrivateRoutes = () => {
  const location = useLocation();
    const currentUser = useSelector(state => state.users.currentUser);

  return (
   currentUser.id ? <Outlet/> : <Navigate to={LOGIN_PATH} state={{ from: location }} replace/>
  )
}

export default PrivateRoutes