/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiController from '../../redux/actions';
import { filterClientsList, setCurrentClient } from '../../redux/reducers/clients';
import { formatStringByChar } from '../../utils/string_formatteur';
import InputFiltrer from '../utils_components/inputs/InputFiltrer';
import CustomBootstrapTable from '../utils_components/tables/CustomBootstrapTable';
import UpdateClientForm from './UpdateClientForm';
import DeleteClient from './DeleteClient';
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from 'react-router-dom';
import { DETAIL_CLIENT_PATH } from '../../utils/navigation_paths';
import Skeleton from 'react-loading-skeleton';


const ClientsListTable = ({ showModal }) => {
    const dispatch = useDispatch()

    const currentUser = useSelector(state => state.users?.currentUser)
    const listClient = useSelector(state => state.clients?.clientsList)
    const currentClient = useSelector(state => state.clients?.currentClient)

    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isClientListVisible, setIsClientListVisible] = useState(true);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        ApiController.clients.getClientsList(currentUser.id).then(() => { setLoading(false) }).catch((e) => console.log(e))
    }, [currentUser.id])

    const openUpdateModal = (item) => {
        // dispatch(ressetCurrentClient())
        dispatch(setCurrentClient(item))
        setShowUpdateModal(true)
        setIsClientListVisible(true); // Masquer la liste des clients lorsque le modal est ouvert
    }

    const openDeleteModal = (item) => {
        dispatch(setCurrentClient(item))
        setShowDeleteModal(true)
    }

    const detailClient = (item) => {
        dispatch(setCurrentClient(item))
        const clientId = item?.id; // ou tout autre moyen d'obtenir l'ID de la commande
        const clientDetail = DETAIL_CLIENT_PATH.replace(":id", clientId);
        navigate(clientDetail);
    }


    const columns = [
        {
            dataField: "nom_complet",
            text: "Nom complet",
            formatter: (cell, row) => {
                return (
                    <>
                        {
                            loading ? <Skeleton duration={10} /> : <p onClick={() => detailClient(row)} style={{ cursor: 'pointer' }}>{row.nom_complet}</p>
                        }
                    </>
                )
            }
        },
        {
            dataField: "telephone",
            text: "Numéro de téléphone",
            formatter: (cell, row) => {
                return (
                    <>
                        {
                            loading ? <Skeleton duration={10} /> : <p onClick={() => detailClient(row)} style={{ cursor: 'pointer' }}>{formatStringByChar('#', '## ### ## ##', row.telephone)}</p>
                        }
                    </>
                )
            }
        },
        // {
        //     dataField: "sexe",
        //     text: "Genre",
        //     formatter: (cell, row) => {
        //         return (
        //             <>
        //                 <p onClick={() => detailClient(row)} style={{ cursor: 'pointer' }}>{row.sexe}</p>
        //             </>
        //         )
        //     }
        // },
        {
            dataField: "adresse",
            text: "Adresse",
            formatter: (cell, row) => {
                return (
                    <>
                        {
                            loading ? <Skeleton duration={10} /> : <p onClick={() => detailClient(row)} style={{ cursor: 'pointer' }}>{row.adresse}</p>
                        }
                    </>
                )
            }
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => {
                return (
                    <>
                        <div className="d-flex align-items-center">

                            {
                                loading ? <Skeleton duration={10} /> : (
                                    <>

                                        <button className='btn-icon edit-btn-icon me-4'
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="modifier client"
                                            onClick={() => openUpdateModal(row)}></button>
                                        <button className='btn-icon delete-btn-icon'
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="supprimer"
                                            onClick={() => openDeleteModal(row)}></button>
                                    </>
                                )
                            }
                        </div>
                    </>
                )
            }
        },
    ];

    return (
        <div className={`p-0 m-0 ${showModal ? 'd-none' : ''}`}>
            {currentClient?.id &&
                <UpdateClientForm unshowList={showModal} showModal={showUpdateModal} setShowModal={setShowUpdateModal} hideClientList={() => setIsClientListVisible(true)} />

            }
            <DeleteClient showModal={showDeleteModal} setShowModal={setShowDeleteModal} />

            <InputFiltrer
                onChange={e => dispatch(filterClientsList(e.target.value))}
            />

            {
                listClient.length ? (

                    <CustomBootstrapTable
                        columns={columns}
                        data={listClient}
                        autoIncrementer='N°'
                        showPaginator={listClient.length > 10 ? true : false}

                    />
                ) : <p className='text-center'>Pas de client disponible</p>
            }

        </div>
    );
};

export default ClientsListTable;