import axios from 'axios'
import { setEntreesList } from '../reducers/entreeargent'
import { store } from '../store/store'

const { ENTREE_API_ROUTE, ADD_ENTREE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const entrees = {
    getEntreesList(userId){
        return new Promise((resolve,reject)=>{
            axios.get(ENTREE_API_ROUTE + `${userId}/`)
            .then(response => {
                // console.log('entrees',response);
                if(response.status === 200){
                    let entrees = response.data
                    dispatch(setEntreesList(entrees))
                    resolve(entrees)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerEntree(data){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_ENTREE_API_ROUTE,data)
            .then(response => {
                // console.log(response)
                if(response.status ===201){
                    let entree = response.data
                    // dispatch(addNewEntree(entree))
                    // this.getEntreesList(userId)
                    resolve(entree)
                }
                
            })
            .catch(error => reject(error))
        })
    },
    // updateCommande(data,id,userId){
    //     return new Promise((resolve,reject)=>{
    //         axios.put(`${UPDATE_COMMANDE_API_ROUTE + id}/`,data)
    //         .then(response => {
    //             // console.log(response);
    //             if(response.status ===200){
    //                 let commande = response.data
    //                 dispatch(setCurrentCommande(commande))
    //                 this.getCommandesList(userId)
    //                 resolve(commande)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // deleteCommande(id, userId){
    //     return new Promise((resolve,reject)=>{
    //         axios.delete(`${DELETE_COMMANDE_API_ROUTE + id}/`)
    //         .then(response => {
    //             if(response.status ===204){
    //                 resolve(response)
    //                 dispatch(this.getCommandesList(userId))
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
}
export default entrees