/* eslint-disable no-unused-vars */
import { ADD_ALBUM_API_ROUTE, GET_ALBUM_API_ROUTE } from "../../routes/api_routes"
import { addNewAlbum, setAlbumList } from "../reducers/albums"
import { store } from "../store/store"
import axios from 'axios'


const dispatch = store.dispatch

const albums = {
    getalbumsList(id){
        return new Promise((resolve,reject)=>{
            axios.get(GET_ALBUM_API_ROUTE + `${id}/`)
            .then(response => {
                // console.log(response)
                if(response.status === 200){
                    let albums = response.data
                    dispatch(setAlbumList(albums))
                    resolve(albums)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerAlbum(data){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_ALBUM_API_ROUTE,data)
            .then(response => {
                console.log(response)
                if(response.status ===201){
                    let client = response.data
                    // dispatch(addNewAlbum(client))
                    resolve(client)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    // updateClient(data,clieni_id){
       
    //     return new Promise((resolve,reject)=>{
    //         axios.put(`${UPDATE_CLIENT_API_ROUTE + clieni_id}/`,data)
    //         .then(response => {
    //             if(response.status ===200){
    //                 let client = response.data
    //                 dispatch(updateClientInList(client))
    //                 resolve(client)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // deleteClient(clieni_id, userId){
    //     return new Promise((resolve,reject)=>{
    //         axios.delete(`${DELETE_CLIENT_API_ROUTE + clieni_id}/`)
    //         .then(response => {
    //             if(response.status ===204){
    //                 dispatch(archiveClientInList(clieni_id))
    //                 resolve(response)
    //                 this.getalbumsList(userId)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
}
export default albums