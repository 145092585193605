/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./table.css";
import TablePaginator from "./TablePaginator";
import { Link } from "react-router-dom";

const CustomBootstrapTable = ({
  columns = [],
  data = [],
  autoIncrementer,
  showPaginator = false,
  path,
}) => {
  const [paginationIndex] = useState(0);

  const [currentPage, setPaginationIndex] = useState(1);
  const [itemsPerPage] = useState(10);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.slice(startIndex, endIndex);

  return (
    <div className="row m-3 p-2">
      <div className="row">
        <div className="col-md-12 col-12 table-responsive-sm">
          <table className="table table-striped">
            <thead className="tableHead">
              <tr className="text-dark">
                {autoIncrementer && <th className="">{autoIncrementer}</th>}
                {columns.map((item, index) => (
                  <th className="fs-90" key={index}>
                    {item.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tablebody">
              {currentItems.map(
                (item, i) =>
                  i + 1 > paginationIndex * 10 &&
                  i < paginationIndex * 10 + 10 && (
                    <tr key={i}>
                      {autoIncrementer && <td>00{i + 1}</td>}
                      {columns.map((col, index) => {
                        if (col.formatter) {
                          return (
                            <td className="fs-14" key={index}>
                              {col.formatter(i, item) || ""}
                            </td>
                          );
                        } else {
                          return (
                            <td key={index}>
                              {item[columns[index].dataField] || ""}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div className="text-end pe-4 py-3">
          {showPaginator && (
            <TablePaginator
              setPaginationIndex={setPaginationIndex}
              dataLength={data.length}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomBootstrapTable;
