import React from "react";

const TablePaginator = ({
  setPaginationIndex,
  totalPages,
  currentPage
}) => {
  // Calculate the range of pages to display
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, startPage + 3);

  return (
    <div className="paginator">
      <button
        className="prev"
        disabled={currentPage === 1}
        onClick={() => setPaginationIndex(currentPage - 1)}
      >
        Précédente
      </button>
      {Array.from(Array(endPage - startPage + 1).keys()).map((item) => (
        <button
          className={`num mx-1 ${currentPage === startPage + item ? "actif" : null}`}
          key={startPage + item}
          onClick={() => setPaginationIndex(startPage + item)}
        >
          {startPage + item}
        </button>
      ))}
      <button
        className="next"
        disabled={currentPage === totalPages}
        onClick={() => setPaginationIndex(currentPage + 1)}
      >
        Suivante
      </button>
    </div>
  );
};

export default TablePaginator;
