/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"


let initialState = {
    currentComptabilite: {},
    listComptabilites :  [],
    listComptabilitesAll : []
}
const comptabiliteSlice = createSlice({
    name:'comptabilites',
    initialState,
    reducers:{
        addNewComptabilite: (state, { payload }) => {
            state.listComptabilites = [...state.listComptabilitesAll, payload].reverse();
            state.listComptabilitesAll = [...state.listComptabilites, payload].reverse();
          },
        setCurrentComptabilite:(state,{payload})=>{
            state.currentComptabilite = payload
        },
        setComptabiliteList:(state,{payload})=>{
            state.listComptabilites = payload
            state.listComptabilitesAll = payload
        },
        resetCurrentComptabilite:(state,{payload})=>{
            state.currentComptabilite = {}
        },
        filterComptabiliteBySearch:(state,{payload})=>{
            state.listComptabilites = state.listComptabilitesAll.filter(item =>{
                let fields = payload.fields.map((field)=> item[field])
                
                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
    }
})

export const {addNewComptabilite, setCurrentComptabilite,setComptabiliteList,resetCurrentComptabilite,filterComptabiliteBySearch} = comptabiliteSlice.actions
export default comptabiliteSlice.reducer