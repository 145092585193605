import React from 'react'
// import { BsWhatsapp } from 'react-icons/bs'
import { FaWhatsapp } from 'react-icons/fa'
// import ReactWhatsapp from 'react-whatsapp'

const Whatsap = () => {
  return (
    <div className="container">

      <div className="position-fixed bottom-0 end-0" 
      style={{ backgroundColor: "#25d366", 
      padding: "10px", 
      borderRadius: "55px",
     marginRight: '20px', 
     marginBottom: '20px' }}>

        <a
          href="https://api.whatsapp.com/send?phone=782933656"
          target="_blank" // Pour ouvrir dans une nouvelle fenêtre/onglett
          className="text-light"
          rel="noreferrer"

        >
          <FaWhatsapp className="" size={30} />
        </a>
      </div>
    </div>
  )
}

export default Whatsap