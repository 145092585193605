/* eslint-disable no-unused-vars */
import React from "react";
import AccueilPage from "../components/accueil/AccueilPage";
import Auth from "../components/auth/Auth";
import ClientsList from "../components/clients/ClientsList";

import AddCommandes from "../components/commandes/AddCommandes";
import Commandes from "../components/commandes/Commandes";
import {
  ACCUEIL_PATH,
  ADD_COMMANDE_PATH,

  ADD_GALERIE_PATH,

  CLIENTS_PATH,
  COMMANDES_PATH,
  COMPTABLITE_PATH,
  
 
  DETAIL_CLIENT_PATH,
  DETAIL_COMMANDE_PATH,
  DETAIL_COMPTABLITE_PATH,

  DETAIL_GALERIE_PATH,

  GALERIE_PATH,
  LOGIN_PATH,
 
  PARAMETRE_PATH,
 
  UPDATE_COMMANDE_PATH,
  
} from "../utils/navigation_paths";
import UpdateCommande from "../components/commandes/UpdateCommande";
import DetailCommande from "../components/commandes/DetailCommande";
import DetailClient from "../components/clients/DetailClient";
import Galleries from "../components/gallerie/Galleries";
import AddGallerie from "../components/gallerie/AddGallerie";
import DetailGallerie from "../components/gallerie/DetailGallerie";
import Entree from "../components/comptabilite/Entree";
import DetailEntree from "../components/comptabilite/detailEntree"
import Parametre from "../components/parametre/Parametre";

const { Navigate } = require("react-router-dom");

export const public_routes = [
  {
    path: LOGIN_PATH,
    element: <Auth />,
  },
  {
    path: LOGIN_PATH,
    element: <Auth />,
  },
]

const navigation_routes = [

  
  {
    path: ACCUEIL_PATH,
    element: <AccueilPage />,
  },
  {
    path: CLIENTS_PATH,
    element: <ClientsList />,
  },
  {
    path: DETAIL_CLIENT_PATH,
    element: <DetailClient />,
  },
  {
    path: COMMANDES_PATH,
    element: <Commandes />,
  },
  {
    path: ADD_COMMANDE_PATH,
    element: <AddCommandes />,
  },
  {
    path: UPDATE_COMMANDE_PATH,
    element: <UpdateCommande />,
  },
  {
    path: DETAIL_COMMANDE_PATH,
    element: <DetailCommande />,
  },
  {
    path: DETAIL_COMPTABLITE_PATH,
    element: <DetailEntree />,
  },
  {
    path: COMPTABLITE_PATH,
    element: <Entree />,
  },
  {
    path: GALERIE_PATH,
    element: <Galleries />,
  },
  {
    path: ADD_GALERIE_PATH,
    element: <AddGallerie />,
  },
  {
    path: DETAIL_GALERIE_PATH,
    element: <DetailGallerie />,
  },
  {
    path: PARAMETRE_PATH,
    element: <Parametre />,
  },
];

export default navigation_routes;
