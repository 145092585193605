/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, Nav } from 'react-bootstrap';
import * as XLSX from "xlsx";
import { FiLogIn, FiPlus, FiPrinter } from "react-icons/fi";
import { BsEyeFill } from 'react-icons/bs';
import { useDispatch } from "react-redux";
import { setCurrentItem } from "../../redux/reducers/navigation";
import { bilanGenerator, depensesGenerator } from "../../utils/data_generator";
// import { setCommandesList } from "../../redux/reducers/commandes";
import { COMPTABLITE_PATH } from "../../utils/navigation_paths";
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import CustomBootstrapTable from "../utils_components/tables/CustomBootstrapTable";
import "./compatibilite.css";
import { useSelector } from "react-redux";
import { DETAIL_COMPTABLITE_PATH } from "../../utils/navigation_paths";
import { useNavigate } from "react-router-dom";
import ApiController from "../../redux/actions";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from 'moment';
import { setCurrentComptabilite } from "../../redux/reducers/comptabilite";
import { ButtonRed } from "../utils_components/buttons/Button";
// import { setEntreesList } from "../../redux/reducers/entreeargent";



const Comptabilite = () => {

  const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)
  // console.log(listCommandes)
  const [selectedCommande, setSelectedCommande] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('clientpay');

  const [isLoading, setIsLoading] = useState(false);


  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm();

  const handleCommandeChange = (event) => {
    const commandeId = event.target.value;
    const selected = listCommandes.find(item => item.id === parseInt(commandeId));
    setSelectedCommande(selected);
    setShowModal(true)
  };

  const currentUser = useSelector(state => state.users?.currentUser)

  const navigate = useNavigate();
  // console.log(selectedCommande);
  const detailEntree = (item) => {
    dispatch(setCurrentComptabilite(item))
    const commandeId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailEntree = DETAIL_COMPTABLITE_PATH.replace(":id", commandeId);
    navigate(detailEntree);
  }
  // HOC
  const dispatch = useDispatch();
  // modal

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    // const montantPaye = parseInt(montantAvance ++);
    // const montantRestant = selectedCommande.montant - montantPaye;
    // setMontantRestant(montantRestant);
    ApiController.commandes.getCommandesList(currentUser?.id).then(() => { })
  }, [currentUser.id]);


  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedCommande(null);
  };

  const handleSaveEntre = (data) => {
    setIsLoading(true)
    const newData = {
      ...data, createdBy: currentUser.id
    }
    // console.log(newData)
    ApiController.entrees.registerEntree(newData).then((res) => {
      // console.log(res)
      closeModal();
      reset()
      // dispatch(setEntreesList(res))
      toast.success('montant ajouter avec success')

    }).catch((er) => console.log(er)).finally(() => setIsLoading(false))

  };



  const handleSave = (data) => {
    setIsLoading(true);
    // Utilisez la valeur de "montantPaye" pour effectuer les opérations souhaitées
    const newData = { ...data, commande: selectedCommande.id }
    ApiController.comptabilite.registerComptabilite(newData).then((res) => {
      closeModal();
      // dispatch(setCommandesList(res))
      if (res.montant_paye === selectedCommande.montant) {

        toast.success('paiement complete de la commande')
      } else {
        toast.warning(`il vous reste ${selectedCommande.montant_restant - res.montant_paye}`)
      }
    }).catch((er) => console.log(er))
      .finally(() => {
        setIsLoading(false); // Désactiver la loader
      });

  };
  // fin modal
  // Function imprimer
  const handlePrint = (row) => {
    const printContent = document.createElement('pre');
    printContent.innerText = row;
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title></title></head><body>');
    printWindow.document.body.appendChild(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    // printWindow.close();

  };

  // Set current route to active
  React.useEffect(() => {
    dispatch(setCurrentItem(COMPTABLITE_PATH));
  }, [dispatch]);

  const [isTabActive, setIsTabActive] = React.useState(false);

  // Entrees
  const Entrees = () => {
    // Table colums
    const columns = [
      {
        dataField: "numero_commande",
        text: "N° Commandes",
      },
      {
        dataField: "date_livraison",
        text: "Date",
        formatter: (cell, row) => {
          const formattedDate = moment(row.date_livraison).format("DD/MM/YYYY HH:mm");
          return formattedDate;
        }
      },
      {

        dataField: "clientId",
        text: "Clients",
        formatter: (cell, row) => {
          if (row.client) {
            return row.client?.nom_complet;
          } else {
            return "non defini";
          }
        },

      },
      // {

      //   dataField: "clientId",
      //   text: "T",
      //   formatter: (cell, row) => {
      //     if (row.client) {
      //       return row?.client?.telephone;
      //     } else {
      //       return "non defini";
      //     }
      //   },

      // },
      {
        dataField: "montant",
        text: "Montant a payer",
        // formatter: (cell, row) => row.adresse,
      },
      {
        dataField: "montant_paye",
        text: "Avance",
        formatter: (cell, row) => {
          return parseInt(row?.montant_paye)
        },
      },
      {
        dataField: "montant_restant",
        text: "Restant",
        formatter: (cell, row) => {
          if(row?.montant_restant === row?.montant){
            return "0"
          }else{
            return row?.montant_restant
          }
        }
      },
      // {
      //   dataField: "imprimer",
      //   text: "Imprimer",
      //   formatter: (cell, row) => {
      //     // const commande = row;??
      //     return (
      //       <>
      //         <div className="d-flex align-items-center">
      //           <BsEyeFill className=" btn-icon   me-4" size={17}
      //             onClick={() => detailEntree(row)} style={{ cursor: 'pointer' }} />

      //           {/* <FiPrinter onClick={() => handlePrint(row)} style={{ cursor: "pointer" }} /> */}
      //         </div>
      //       </>
      //     )
      //   }
      // },
      // {
      //   dataField: "montant_paye",
      //   text: "Status",

      //   formatter: (cell, row) => {
      //     // <button className="btn fs-90 text-danger">Impaye</button>
      //     const commande = row; // La commande spécifique à cette ligne
      //       return (
      //         <>
      //           {commande.montant_restant === 0 ? (
      //             <button className="btn fs-90 text-succes">payer</button>
      //           ) : (
      //             <button className="btn fs-90 text-danger">impayer</button>
      //           )}
      //         </>
      //       );
      //   }

      // },
    ];
    // importation excel
    const exportToExcel = () => {
      const workbook = XLSX.utils.book_new(); //un nouveau classeur Excel vide
      // const exportData = listCommandes.slice(0, 10); // Exemple : exporter les 10 premières lignes
      // const worksheet = XLSX.utils.json_to_sheet(exportData);
      const worksheet = XLSX.utils.json_to_sheet(listCommandes); //prend les données et les convertit en une feuille de calcul Excel. Chaque objet dans le tableau 
      XLSX.utils.book_append_sheet(workbook, worksheet, "Commandes"); // Cette ligne ajoute la feuille de calcul worksheet au classeur workbook. Le troisième argument 
      XLSX.writeFile(workbook, "commandes.xlsx"); // Générer le fichier Excel
    };

    return (
      <>
      
       
      <div className=" container">
        <div className="row">

{/* 
          <div className="col-md-4 col-12 my-5">
            <div className="d-flex justify-content-end">
              <button className='add-btn-red float-end fs-80 px-4 fw-600' onClick={openModal}>
                <FiPlus size={20} /> Ajouter une entrée
              </button>
            </div>
          </div> */}

          {/* <div className="col-md-4 col-12  my-5">
            <div className="d-flex justify-content-space-between">
            <button className="text-color-red btn" onClick={exportToExcel}>
              <FiLogIn /> Exporter en exel
            </button>
            </div>
          </div> */}
        </div>

        {
          listCommandes?.length ? (
            <>

              {/* <InputFiltrer onChange={(e) => dispatch(filterCommandesBySearch(e.target.value))} /> */}
              <CustomBootstrapTable
                columns={columns}
                data={listCommandes}
                showPaginator={listCommandes.length > 10 ? true : false}
                keyField='id'
              />
            </>
          ) : <p className="text-center p-5">Pas de commande disponible</p>
        }
      </div>
      </>
    );
  };

  // Depenses
  const Depenses = () => {
    const columns = [
      {
        dataField: "date",
        text: "Date",
      },
      {
        dataField: "designation",
        text: "Designation",
        // formatter: (cell, row) => row.adresse,
      },
      {
        dataField: "montant",
        text: "Montant",
        // formatter: (cell, row) => row.adresse,
      },
    ];

    return (
      <div className="p-0 m-0">
        <div className="row d-flex align-items-center table-utils px-2 py-5">
          <div className="col text-start">
            <button>
              <FiLogIn /> Exporter en exel
            </button>
          </div>

          <div className="col">
            <div className="row table-utils-date-section">
              <div className="col d-flex flex-column text-start">
                <label htmlFor="date-debut">Date de début</label>
                <input type="date" name="date-debut" id="date-debut" />
              </div>
              <div className="col d-flex flex-column text-start">
                <label htmlFor="date-fin">Date de début</label>
                <input type="date" name="date-fin" id="date-fin" />
              </div>
            </div>
          </div>
        </div>
        <CustomBootstrapTable
          columns={columns}
          data={depensesGenerator(100)}
          showPaginator={true}
        />
      </div>
    );
  };

  // Bilan
  const Bilan = () => {
    const columns = [
      {
        dataField: "date",
        text: "Date",
      },
      {
        dataField: "entrees",
        text: "Entrées",
        // formatter: (cell, row) => row.adresse,
      },
      {
        dataField: "depenses",
        text: "Dépenses",
        // formatter: (cell, row) => row.adresse,
      },
      {
        dataField: "benefices",
        text: "Benefices",
        // formatter: (cell, row) => row.adresse,
      },
    ];

    return (
      <div className="p-0 m-0">
        <div className="row d-flex align-items-center table-utils px-2 py-5">
          <div className="col text-start">
            <button>
              <FiLogIn /> Exporter en exel
            </button>
          </div>

          <div className="col">
            <div className="row table-utils-date-section">
              <div className="col d-flex flex-column text-start">
                <label htmlFor="date-debut">Date de début</label>
                <input type="date" name="date-debut" id="date-debut" />
              </div>
              <div className="col d-flex flex-column text-start">
                <label htmlFor="date-fin">Date de début</label>
                <input type="date" name="date-fin" id="date-fin" />
              </div>
            </div>
          </div>
        </div>
        <CustomBootstrapTable
          columns={columns}
          data={bilanGenerator(100)}
          showPaginator={true}
        />
      </div>
    );
  };

  // check if item has active class
  // const checkActive = () => {
  //   let btn = document.getElementById("depenses-tab");
  //   const isActive = btn?.classList?.contains("active");
  //   isActive && setIsTabActive(true);
  //   return;
  // };

  return (
    <AdminInterfaceContainer>
      <div className="client">
        <div className="row mt-3 mx-2 mb-4">
          <div className="col text-end">
            {isTabActive && (
              <button
                //   onClick={() => setShowModal(true)}
                className="add-client-btn fs-80 px-4 fw-600"
              >
                Ajouter une depense
              </button>
            )}
          </div>
        </div>

        <div className="row  mb-5 mx-3 pt-0">
          <h1>Comptabilite</h1>
          {/* <ul className="nav nav-tabs commands-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="entrees-tab"
                data-bs-toggle="tab"
                data-bs-target="#entrees-tab-pane"
                type="button"
                role="tab"
                aria-controls="entrees-tab-pane"
                aria-selected="true"
                onClick={() => setIsTabActive(false)}
              >
                Entrées
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="depenses-tab"
                data-bs-toggle="tab"
                data-bs-target="#depenses-tab-pane"
                type="button"
                role="tab"
                aria-controls="depenses-tab-pane"
                aria-selected="false"
                onClick={checkActive}
              >
                Dépenses
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="bilan-tab"
                data-bs-toggle="tab"
                data-bs-target="#bilan-tab-pane"
                type="button"
                role="tab"
                aria-controls="bilan-tab-pane"
                aria-selected="false"
                onClick={() => setIsTabActive(false)}
              >
                Bilan
              </button>
            </li>
          </ul> */}
          <div className="tab-content bg-white" id="myTabContent">
            <div
              className="tab-pane fade show active "
              id="entrees-tab-pane"
              role="tabpanel"
              aria-labelledby="entrees-tab"
              tabIndex="0"
            >
              <Entrees />
            </div>
            <div
              className="tab-pane fade"
              id="depenses-tab-pane"
              role="tabpanel"
              aria-labelledby="depenses-tab"
              tabIndex="0"
            >
              <Depenses />
            </div>
            <div
              className="tab-pane fade"
              id="bilan-tab-pane"
              role="tabpanel"
              aria-labelledby="bilan-tab"
              tabIndex="0"
            >
              <Bilan />
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <>

        <Modal show={showModal} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une entrée</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabChange}>
              <Nav.Item>
                <Nav.Link className="pay" eventKey="clientpay">Paiement Commande</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="pay" eventKey="autrepay">Autres entrées</Nav.Link>
              </Nav.Item>
            </Nav>
            {activeTab === 'clientpay' && (
              <div>
                {/* Contenu de l'onglet de connexion */}
                <form onSubmit={handleSubmit(handleSave)}>
                  <div className="add-commande-container">
                    <div className="pay-commande-client-section">
                      {/* Client */}
                      {/* <h3 className="section-title">Commande</h3> */}
                      <div className="row section-content mb-4">
                        <div className="row m-2">
                          <div className="col-xl-4 col-lg-6 col-md-8 col-sm-6 col-10">
                            <label htmlFor="clientId">Selectionner une commande</label>
                          </div>
                          <div className="col-xl-5 col-lg-7 col-md-10 col-sm-10 col-10">
                            <select
                              name="clientId"
                              id="clientId"
                              className="form-control"
                              defaultValue="default"
                              onChange={handleCommandeChange}
                            >
                              <option value="default" disabled>choisir une commande</option>
                              {listCommandes?.filter(item => item?.montant_restant !== 0).map((item, index) => (
                                // console.log(item.client.nom_complet),
                                <option key={index} value={item?.id} required>
                                  {item?.numero_commande + " -  " + item?.client?.nom_complet}
                                  {/* {item?.nom_tissu} {item?.client.nom_complet} */}
                                </option>
                              ))}
                            </select>

                          </div>
                        </div>
                      </div>
                      {/* afficher les infos celon l'id de la commande selectionnée */}
                      {selectedCommande && (
                        <div className="row section-content mb-4">
                          <div className="row m-2">
                            <div className="col-xl-4 col-lg-3">
                              <label htmlFor="paiement" style={{ color: '#e82828', fontSize: '15px' }}>Ajouter une Avance</label>
                            </div>
                            <div className="col-xl-5 col-lg-7">
                              <input
                                type="number"
                                name="montant_paye"
                                id="montant_paye"
                                className="form-control"
                                {...register("montant_paye")}
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col-xl-4 col-lg-3">
                              <label htmlFor="paiement">Montant</label>
                            </div>
                            <div className="col-xl-5 col-lg-7">
                              <input
                                type="number"
                                name="montant_paye"
                                id="montant_paye"
                                className="form-control"
                                value={selectedCommande.montant}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col-xl-4 col-lg-3">
                              <label htmlFor="paiement">Montant Avance</label>
                            </div>
                            <div className="col-xl-5 col-lg-7">
                              <input
                                type="number"
                                name="montant_paye"
                                id="montant_paye"
                                className="form-control"
                                value={selectedCommande.montant_paye}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row m-2">
                            <div className="col-xl-4 col-lg-3">
                              <label htmlFor="paiement">Montant Restant</label>
                            </div>
                            <div className="col-xl-5 col-lg-7">
                              <input
                                type="number"
                                name="montant_paye"
                                id="montant_paye"
                                className="form-control"
                                value={selectedCommande.montant_restant}
                                disabled
                              />
                            </div>
                          </div>
                          <div className='text-end pe-4'>
                            <ButtonRed onClick={() => { }} backgroundColor={'#e82828'} color={'#ffffff'} disabled={isLoading}>
                              {
                                isLoading ? (
                                  <div className="spinner-border text-light" role="status">
                                  </div>
                                ) : 'Enrgistrer'
                              }
                            </ButtonRed>
                          </div>
                        </div>

                      )}

                    </div>

                  </div>
                </form>
                {/* fin contenue enter clien*/}
              </div>
            )}
            {activeTab === 'autrepay' && (
              <div>
                {/* Contenu de l'onglet d'inscription */}
                <form onSubmit={handleSubmit(handleSaveEntre)}>
                  <div className="row section-content mb-4">
                    <div className="row m-2">
                      <div className="col-xl-4 col-lg-3">
                        <label htmlFor="quantite">Entrer la somme</label>
                      </div>
                      <div className="col-xl-5 col-lg-7">
                        <input
                          type="number"
                          name="somme"
                          id="somme"
                          className="form-control"
                          {...register("montant_entree")}
                        />
                      </div>
                    </div>
                    <div className="row m-2">
                      <div className="col-xl-4 col-lg-3">
                        <label htmlFor="quantite">Nom de l'entrée</label>
                      </div>
                      <div className="col-xl-5 col-lg-7">
                        <input
                          type="text"
                          name="nom"
                          id="nom"
                          className="form-control"
                          {...register("nom_entree")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <ButtonRed onClick={() => { }} backgroundColor={'#e82828'} color={'#ffffff'} disabled={isLoading}>
                      {
                        isLoading ? (
                          <div className="spinner-border text-light" role="status">
                          </div>
                        ) : 'Enrgistrer'
                      }
                    </ButtonRed>
                    {/* <button style={{
                      backgroundColor: "#E82828", color: "#FFFFFF", borderRadius: "5px",
                      fontWeight: "600",
                      fontSize: "14px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      padding: "10px 30px",
                      border: "none",
                      fontStyle: "normal",
                      fontFamily: "Montserrat",
                    }} disabled={isLoading} >
                      {
                        isLoading ? (
                          <div className="spinner-border text-light" role="status">
                          </div>
                        ) : 'Enrgistrer'
                      }
                    </button> */}
                  </div>
                </form>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    </AdminInterfaceContainer>
  );
};

export default Comptabilite;
