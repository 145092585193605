import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ACCUEIL_PATH, ADD_COMMANDE_PATH, CLIENTS_PATH, COMMANDES_PATH, COMPTABLITE_PATH } from '../../utils/navigation_paths';
import CardView from '../utils_components/cards/CardView';
import ListCard from '../utils_components/cards/ListCard';
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer';
import { Link, useNavigate } from 'react-router-dom';
import ApiController from '../../redux/actions';
import './acceuil.css'
import { setCurrentItem } from '../../redux/reducers/navigation';
import { setCommandesList } from '../../redux/reducers/commandes';
import { useDispatch } from 'react-redux';
import InstallPrompt from '../pwInstallation';
import AddClientForm from '../clients/AddClientForm';
// import Skeleton from 'react-loading-skeleton'


const AccueilPage = () => {
    const listClient = useSelector(state => state.clients?.clientsList)
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(true)


    const navigate = useNavigate();
    const commandesCurrentWeek = useSelector(state => state?.commandes?.listCommandes?.livrer_dans_la_semaine)

    const currentUser = useSelector(state => state?.users?.currentUser);
    const commandesNextWeek = useSelector(state => state?.commandes?.listCommandes?.livrer_semaine_prochaine)
    const commandesNextMonth = useSelector(state => state?.commandes?.listCommandes?.livrer_mois_prochain)
    const [showModal, setShowModal] = useState(false)

    const listDesCommandes = useSelector(state => state?.commandes?.listCommandes?.total_commandes)
    // const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)


    // const [query,setQuery] =  useState()

    //   const commandeNonLivree = listCommandes?.filter(item => item.statut !== "livree")

    //   console.log(commandeNonLivree)

    //   console.log(listCommandes)
    //   console.log(listDesCommandes)
    // console.log({listentree:entreeListe})
    // filtre commande par status
    // let totalMontantEntree = 0;

    // console.log(totalMontantEntree)




    useEffect(() => {
        dispatch(setCurrentItem(COMMANDES_PATH));
        ApiController.commandes?.getCommandesList(currentUser?.id).then((res) => {
            // console.log(res)
            dispatch(setCommandesList(res))
            // setLoading(false)
        })
    }, [currentUser.id, dispatch]);

    // eslint-disable-next-line no-lone-blocks
    {
        commandesCurrentWeek?.filter(item => item?.montant_restant !== 0).map((item, index) => (
            <option key={index} value={item?.id} required>{item?.numero_commande} {item?.nom_tissu} {item?.client?.nom_complet}</option>
        ))
    }

    // const commandeEncour = listCommandes?.filter(item => item?.statut === "encours")

    // const commandeTerminee = listCommandes?.filter(item => item?.statut === 'terminee')

    // const commandeLivree = listCommandes?.filter(item => item?.statut === 'livree')

    // const commandeNouvelle = listCommandes?.filter(item => item?.statut === 'nouvelle')

    // prix
    const prixTotal = useSelector(state => state?.commandes?.listCommandes?.prixTotal)
    // const TotalAvance = useSelector(state => state?.commandes?.listCommandes?.TotalAvance)
    // const TotalRestant = useSelector(state => state?.commandes?.listCommandes?.totalRestant)
    // console.log(prixTotal);

    // console.log(listCommandes)

    // function showNotification(title, body, icon) {
    //     if ('Notification' in window && Notification.permission === 'granted') {
    //       const notification = new Notification(title, {
    //         body: body,
    //         icon: icon,
    //       });

    //     //   notification.onclick = () => {
    //     //     // Gérer le clic sur la notification (si nécessaire)
    //     //   };
    //     }
    //   }




    useEffect(() => {
        ApiController?.clients?.getClientsList(currentUser?.id).then(() => {

        }).catch((e) => { console.log(e) })

        ApiController?.commandes?.getCommandesList(currentUser?.id).then(() => { }).catch((e) => console.log(e))

        // if ('Notification' in window) {
        //     Notification.requestPermission()
        //       .then(permission => {
        //         if (permission === 'granted') {
        //             console.log("le user a accepte d'utiliser les notifications")
        //           // L'utilisateur a accepté les notifications
        //         } else if (permission === 'denied') {
        //             console.log("le user n a pas accepte d'utiliser les notifications")
        //           // L'utilisateur a refusé les notifications
        //         }
        //       });
        //   }



    }, [currentUser.id])





    return (
        <AdminInterfaceContainer path={ACCUEIL_PATH}>
            <InstallPrompt />
            <div className="container">

                <div className="d-flex justify-content-around">
                    <button onClick={() => setShowModal(true)} className='add-btn-red dbtn fs-80 px-4 me-2 fw-600 d'>Ajouter un client</button>
                    <AddClientForm showModal={showModal} setShowModal={setShowModal} />
                    <button onClick={() => navigate(ADD_COMMANDE_PATH)} className='add-btn-red dbtn fs-80 px-4 fw-600'>Ajouter une commande</button>

                </div>
            </div>
            <div className="row p-0 m-0 mt-4 pt-4 justify-content-center mb-1">


                <div className="col-md-4">


                    <Link to={COMMANDES_PATH} className='mb-3 text-decoration-none text-dark'>
                        <CardView title='Commandes' value={listDesCommandes} cardclass='me-md-2' src={'group.svg'} />


                    </Link>
                </div>

                <Link to={CLIENTS_PATH} className='col-md-4 mb-3 text-decoration-none text-dark'>
                    <CardView title='Clients' value={listClient?.length} iconclass='header-users-icon' cardclass='mx-md-2' src={'users.svg'} style={{ cursor: "pointer" }} />
                </Link>
                <Link to={COMPTABLITE_PATH} className='col-md-4 mb-3 text-decoration-none text-dark'>
                    <CardView title='Solde' value={prixTotal} iconclass='header-comptablite-icon' cardclass='ms-md-2' src={'activity.svg'} />
                </Link>
                {/* <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Solde' value={prixTotal} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Mes Entrées' value={totalMontantEntree} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>
                
                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Avance' value={TotalAvance} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Paiement restant' value={TotalRestant} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='En confection' value={commandeEncour?.length} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Commandes Nouvelle' value={commandeNouvelle?.length} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Commandes Terminées' value={commandeTerminee?.length} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link>

                <Link className='col-md-4 mb-3 text-decoration-none text-dark'>
                <CardView title='Commandes Livrées' value={commandeLivree?.length} iconclass='header-comptablite-icon' cardclass='ms-md-2' />
                </Link> */}
            </div>

            <div className="row p-0 m-0 rounded">
                <div className="col row m-0 p-0 mx-3 px-2 mb-5 pb-3 mt-1 bg-white fs-90 text-center pt-4">
                    <p className='fw-100'>Liste des commandes à livrer</p>
                    <div className="row m-0 p-0 justify-content-center ">
                        <ListCard title='cette semaine' commandesList={commandesCurrentWeek} />
                        <ListCard title='la semaine prochaine' commandesList={commandesNextWeek} />
                        <ListCard title='le mois prochain' commandesList={commandesNextMonth} />
                    </div>
                </div>
            </div>
        </AdminInterfaceContainer>
    );
};

export default AccueilPage;