/* eslint-disable no-unused-vars */
import React from 'react'
import CustomModal from '../../utils_components/modals/CustomModal'
import ClientInfoComponent from '../../utils_components/ClientInfoComponent'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { DETAIL_COMMANDE_PATH } from '../../../utils/navigation_paths'
import { useDispatch } from 'react-redux'
import { setCurrentCommande } from '../../../redux/reducers/commandes'
import { useSelector } from 'react-redux'

const NonLivree = ({ showModal, setShowModal, commandeNonLivree }) => {


    const dispatch = useDispatch();
    const isOnThisPage = useSelector(state => state.page)
    const navigate = useNavigate();
    // const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)

    // const commandeNonLivree = listCommandes?.filter(item => item.statut !== "livree")

    // console.log(commandeNonLivree)

    const detailCommande = (item) => {
        dispatch(setCurrentCommande(item))

        const commandeId = item?.id; // ou tout autre moyen d'obtenir l'ID de la commande
        const detailCommande = DETAIL_COMMANDE_PATH.replace(":id", commandeId);
        navigate(detailCommande);
    }

    return (



        <CustomModal
            showModal={showModal}
            setShowModal={setShowModal}
            title='Liste des commandes non livree'
        // titleIconClass='white-users-icon'
        >
            <div className="container">
                <div className="row">
                    {
                        commandeNonLivree && commandeNonLivree?.map((item, index) => {
                            const date_livraison = new Date(item.date_livraison);
                            const formattedDateLivraison = `${date_livraison.getFullYear()}-${(date_livraison.getMonth() + 1).toString().padStart(2, '0')}-${date_livraison.getDate().toString().padStart(2, '0')}`;

                            const date_Commande = moment(item.date_commande).format('YYYY-MM-DD');

                            return (


                                <div className="card m-2 border-0 text-decoration-none text-dark" key={index} onClick={() => detailCommande(item)} style={{
                                    color: `rgba(55, 55, 55, 0.5)`, cursor: 'pointer'
                                }}>
                                    <div className='col-md-6 col-12'>
                                        <ClientInfoComponent Texvalue={item?.client?.nom_complet} />
                                        <ClientInfoComponent text="Telephone" Texvalue={item?.client?.telephone} />
                                        <ClientInfoComponent text="Date Commande" Texvalue={date_Commande} />
                                        <ClientInfoComponent text="Date de livraison" Texvalue={formattedDateLivraison
                                        } />
                                    </div>

                                </div>



                            )
                        })
                    }
                </div>
            </div>
        </CustomModal>
    )
}

export default NonLivree