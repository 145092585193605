/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./seviceWorkersTest.js";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "./theme/theme.css";
import "./theme/fonts.css";
import "./theme/icons.css";
import "./index.css";
import 'react-loading-skeleton/dist/skeleton.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";



const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Profiler id='app' onRender={callback}> */}
          <App />
          {/* </Profiler> */}
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
