/* eslint-disable no-unused-vars */
import React from 'react';
import PWAInstallerPrompt from 'react-pwa-installer-prompt';
import './pw.css'
// import { FaDownload } from "react-icons/fa";



function InstallPrompt() {
 

  return (
    <>
    <div className="d-flex justify-content-center">
      
    <PWAInstallerPrompt 
    
      render={({ onClick }) => (
        <button type="button" className='btn btnInstallation'
         onClick={onClick}> Installer l'application
        </button>
      )}
      callback={(data) => {}} 
    />
    {/* <button  className='btn btn-danger w-70 btnInstallation'
         onClick={'onClick'}> Installer l'application
        </button> */}
    </div>
    </>
  )
}

export default InstallPrompt;
