import React from 'react'

const ClientInfoComponent = ({text, Texvalue}) => {
  return (
    <div className='d-flex justify-content-space-around'>
        <p style={{ fontWeight: 400 }} className='m-2'>{text}</p>
        <p className='m-2'>{Texvalue}</p>
    </div>
  )
}

export default ClientInfoComponent