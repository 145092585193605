import { createSlice } from "@reduxjs/toolkit"

const pageSlice = createSlice({
    name: 'pages',
    initialState: false, // L'état initial est false, ce qui signifie que l'utilisateur n'est pas sur la page de détail par défaut
    showModal: false,
    reducers: {
      setIsOnAnyPage: (state, action) => action.payload,
      setShowModal: (state, action) => {
        state.showModal = action.payload;
      },
    },
  });

  export const { setIsOnAnyPage,setShowModal } = pageSlice.actions;
  export default pageSlice.reducer