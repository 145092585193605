/* eslint-disable no-unused-vars */
import { addNewClient, archiveClientInList, setClientsList, setCurrentClient, updateClientInList } from "../reducers/clients"
import { store } from "../store/store"
import axios from 'axios'

const { CLIENT_API_ROUTE, DELETE_CLIENT_API_ROUTE, UPDATE_CLIENT_API_ROUTE, ADD_CLIENT_API_ROUTE, DETAIL_CLIENT_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch

const clients = {
    getClientsList(userId){
        return new Promise((resolve,reject)=>{
            axios.get(CLIENT_API_ROUTE + `${userId}/`)
            .then(response => {
                if(response.status === 200){
                    let clients = response.data
                    dispatch(setClientsList(clients))
                    resolve(clients)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerClient(data){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_CLIENT_API_ROUTE,data)
            .then(response => {
                if(response.status ===201){
                    let client = response.data
                    dispatch(addNewClient(client))
                    resolve(client)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    updateClient(data,clieni_id, userId){
       
        return new Promise((resolve,reject)=>{
            axios.put(`${UPDATE_CLIENT_API_ROUTE + clieni_id}/`,data)
            .then(response => {
                if(response.status ===200){
                    let client = response.data
                    dispatch(updateClientInList(client))
                    this.detailClient(clieni_id,userId)
                    this.getClientsList(userId)
                    resolve(client)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    detailClient(clieni_id, userId){
       
        return new Promise((resolve,reject)=>{
            axios.get(`${DETAIL_CLIENT_API_ROUTE + clieni_id}/`)
            .then(response => {
                if(response.status ===200){
                    let client = response.data
                    // console.log(client)
                    dispatch(setCurrentClient(client))
                    // this.getClientsList(userId)
                    resolve(client)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    deleteClient(clieni_id, userId){
        return new Promise((resolve,reject)=>{
            axios.delete(`${DELETE_CLIENT_API_ROUTE + clieni_id}/`)
            .then(response => {
                if(response.status ===204){
                    dispatch(archiveClientInList(clieni_id))
                    resolve(response)
                    this.getClientsList(userId)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
}
export default clients