/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"


let initialState = {
    currentEntree: {},
    listEntrees :  [],
    listEntreesAll : []
}
const entreeSlice = createSlice({
    name:'entrees',
    initialState,
    reducers:{
        addNewEntree: (state, { payload }) => {
            state.listEntrees = [...state.listEntreesAll, payload].reverse();
            state.listEntreesAll = [...state.listEntrees, payload].reverse();
          },
        setCurrentEntree:(state,{payload})=>{
            state.currentEntree = payload
        },
        setEntreesList:(state,{payload})=>{
            state.listEntrees = payload
            state.listEntreesAll = payload
        },
        resetCurrentEntree:(state,{payload})=>{
            state.currentEntree = {}
        },
        filterEntreeBySearch:(state,{payload})=>{
            state.listEntrees = state.listEntreesAll.filter(item =>{
                let fields = payload.fields.map((field)=> item[field])
                
                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
    }
})

export const {addNewEntree, setCurrentEntree,setEntreesList,resetCurrentEntree,filterEntreeBySearch} = entreeSlice.actions
export default entreeSlice.reducer