import React from 'react';
import { useForm } from 'react-hook-form';

const ForgotPassword = () => {
    const {register,handleSubmit,formState:{errors}} = useForm()

    const submit =()=>{
        
    }
    return (
        <div className=''>
            <p className='fw-bold mb-5 pb-3 fs-4'>Modifier votre mot de passe</p>
            <form onSubmit={handleSubmit(submit)} className='mx-md-5 px-md-5' >
                <label className='input-container row m-0 p-0'>
                    <div className="col-2 icon mail-icon"></div>
                    <div className="col-10">
                        <input {...register('email',{required:'Ce champ est obligatore!'})} placeholder='Entrez votre email' type="text" className='' />
                    </div>
                </label>
                <p className='error-msg mb-5'>{errors?.email?.message}</p>
                
                <button className='primary-btn fw-bold'>Connexion</button>
            </form>
        </div>
    );
};

export default ForgotPassword;