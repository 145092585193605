/* eslint-disable no-unused-vars */
import { store } from "../store/store"
import axios from 'axios'

const { REGISTER_API_ROUTE, LOGIN_API_ROUTE, RESET_PASSWORD_API_ROUTE, UPDATE_USER_API_ROUTE, RESET_PASSWORD_API_ROUTE_SENT, CHANGE_USER_PASSWORD_API_ROUTE, USER_API_ROUTE } = require("../../routes/api_routes")
const { setCurrentUser, setUsersList, updateUser } = require("../reducers/users")
const  Cookie  = require("js-cookie")

const dispatch = store.dispatch

const users = {
    getUsersList(){
        return new Promise((resolve,reject)=>{
            axios.get(USER_API_ROUTE)
            .then(response => {
                if(response.status ===201){
                    let users = response.data
                    dispatch(setUsersList(users))
                    resolve(users)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    registerUser(data){
        return new Promise((resolve,reject)=>{
            axios.post(REGISTER_API_ROUTE,data)
            .then(response => {
                if(response.status ===201){
                    let user = response.data
                    resolve(user)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    updateProfile(id,data){
        return new Promise((resolve,reject)=>{
            axios.post(UPDATE_USER_API_ROUTE + `${id}`,data)
            .then(response => {
                if(response.status ===201){
                    let user = response.data
                    dispatch(updateUser(user))
                    resolve(user)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    loginUser(data){
        return new Promise((resolve,reject)=>{
            axios.post(LOGIN_API_ROUTE,data)
            .then(response => {
                if(response.status === 200){
                    let user = response.data
                    let userData = {
                        id:user.id,
                        firstName:user.firstName,
                        lastName:user.lastName,
                        phone:user.phone,
                        email:user.email,
                        token:user.token,
                    }
                    localStorage.setItem('currentUser',JSON.stringify(userData))
                    
                    dispatch(setCurrentUser(user))
                    resolve(user)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
}
export default users