import React, { useState } from 'react';
import { CLIENTS_PATH } from '../../utils/navigation_paths';
import AdminInterfaceContainer from '../utils_components/interface_containers/AdminInterfaceContainer';
import AddClientForm from './AddClientForm';
import './client.css'
import ClientsListTable from './ClientsListTable';

const ClientsList = () => {
    
    
    const [showModal,setShowModal] = useState(false)

    return (
        <AdminInterfaceContainer path={CLIENTS_PATH}>
            <div className="client py-3 container">
                <div className="row mt-3 mx-2 mb-4">
                    <div className="col align-items-center row">
                        <h1 className="text-start fw-600 fs-80">Liste des clients</h1>
                    </div>
                    <div className="col text-end">
                        <button onClick={()=>setShowModal(true)} className='add-btn-red fs-80 px-4 fw-600'>Ajouter un client</button>
                        <AddClientForm showModal={showModal} setShowModal={setShowModal} />
                    </div> 
                </div>

                <div className="row bg-white mb-5 mx-3">
                    <ClientsListTable showModal={showModal}/>
                </div>
            </div>
        </AdminInterfaceContainer>
    );
};

export default ClientsList;