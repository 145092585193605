/* eslint-disable no-unused-vars */
import { store } from "../store/store"
import axios from 'axios'
import ApiController from "."

const { REGISTER_API_ROUTE, LOGIN_API_ROUTE, RESET_PASSWORD_API_ROUTE, UPDATE_USER_API_ROUTE, RESET_PASSWORD_API_ROUTE_SENT, CHANGE_USER_PASSWORD_API_ROUTE, CLIENT_API_ROUTE } = require("../../routes/api_routes")
const { setCurrentUser } = require("../reducers/users")

const dispatch = store.dispatch

const all = {
    getAllData(userId){
        // ApiController.users.getUsersList()
        ApiController?.clients?.getClientsList(userId)
        // ApiController.mesures.getMesuresList()
        ApiController?.commandes?.getCommandesList(userId)
        // ApiController.comptabilite.getComptabilitesList()
        ApiController?.entrees.getEntreesList(userId)
       
    },
}
export default all