export const formatStringByChar = (formatter='',format='',text = '')=>{
    let result = ''
    let index =0
    format.split('').map((char)=>{
        if(char === formatter){
            if(text[index]){
                result += text[index]
                index += 1
            }
        }else{
            result += char
        }
    })
    return result
}