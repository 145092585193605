import { createSlice } from "@reduxjs/toolkit/";

let initialState = {
  currentClient: {},
  clientsList: [],
  allClientsList: [],
};
const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    addNewClient: (state, { payload }) => {
      state.clientsList = [...state.allClientsList, payload].reverse();
      state.allClientsList = [...state.clientsList, payload].reverse();
    },
    updateClientInList: (state, { payload }) => {
      let clients = state.allClientsList.filter(
        (client) => client.id !== payload.id
      );
      clients = [payload, ...clients];
      state.allClientsList = clients;
      state.clientsList = clients;
    },
    archiveClientInList: (state, { payload }) => {
      let clients = state.allClientsList.filter(
        (client) => client.id !== payload.id
      );
      state.allClientsList = clients;
      state.clientsList = clients;
    },
    setCurrentClient: (state, { payload }) => {
      state.currentClient = payload;
    },
    // eslint-disable-next-line no-unused-vars
    ressetCurrentClient: (state, { payload }) => {
      state.currentClient = {};
    },
    setClientsList: (state, { payload }) => {
      state.clientsList = payload;
      state.allClientsList = payload;
    },
    filterClientsList: (state, { payload }) => {
      state.clientsList = state.allClientsList.filter((item) => {
        let data = [item.nom_complet, item.telephone, item.adresse]
          .join(" ")
          .toLowerCase();
        return data.includes(payload.toLowerCase());
      });
    },
  },
});

export const {
  addNewClient,
  setCurrentClient,
  ressetCurrentClient,
  setClientsList,
  filterClientsList,
  updateClientInList,
  archiveClientInList,
} = clientsSlice.actions;
export default clientsSlice.reducer;
