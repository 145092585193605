import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
// import logo from "./logo.svg";
// import ApiController from "./redux/actions";
import navigation_routes, { public_routes } from "./routes/navigation_routes";
import PrivateRoutes from "./routes/PrivateRoutes";
// import { LOGIN_PATH } from "./utils/navigation_paths";

function App() {


  // useEffect(() => {

  //   currentUser.id ? ApiController.all.getAllData(currentUser.id) : navigate(LOGIN_PATH)
  // }, [currentUser.id, navigate])

  return (
    <div className="container-fluid m-0 p-0 font-Montserrat">
      <ToastContainer />

      <Routes>
      {public_routes.map((item, index) => {
            return <Route key={index}  path={item?.path} element={item?.element} />;
          })}

        <Route element={<PrivateRoutes />}>
          {navigation_routes.map((item, index) => {
            return <Route key={index} path={item?.path} element={item.element} />;
          })}

        </Route>
      </Routes>

    </div>
  );
}

export default App;
