/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";
import sidebar_items from "../../utils/sidebar_items";
import "./sidebar.css";
import { Sidebar, InputItem, DropdownItem, Icon, Item, Logo, LogoText } from 'react-sidebar-ui'
import { useDispatch } from "react-redux";
import { changeDrawerState } from "../../redux/reducers/navigation";
import { ACCUEIL_PATH } from "../../utils/navigation_paths";


const SideBarComponent = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const currentItem = useSelector((state) => state.navigation.currentItem);
  const splitLocation = pathname.split("/");




  return (
    <>
      {/* <img src="tayeur-logo.png" alt="tayeurImg" /> */}


      <div
        className={`sidebar pt-4 bg-white min-height-max`}
      >
        {/* <button onClick={onClose}>Fermer la sidebar</button>  */}
        {/* {} */}
        <Link to={ACCUEIL_PATH} className="logo">
          <div className="red-logo-container mb-4 d-flex align-items-center"></div>
          {/* <img src="tayeur-logo.png" alt="tayeurImg" /> */}

        </Link>
        <div className={`text-start fs-90 bg-white ${isOpen ? 'sidebar-fixed' : null}`}>
          <div className="text-end mt-4">
            <ImCross size={20} className=" sidebar-close-btn" onClick={onClose} />
          </div>
          {
            sidebar_items.map((item, index) => {
              let defaultItemClass = "item ms-2 ms-lg-4 d-flex align-items-center";
              let actifItemClass = "actif " + defaultItemClass;
              let defaultIconClass = "menu-icon ";
              let actifIconClass = "actif " + defaultIconClass;
              return (
                <p
                  key={index}
                  onClick={() => navigate(item.path)}
                  className={
                    item.path === currentItem ? actifItemClass : defaultItemClass
                  }
                >
                  <canvas
                    className={
                      item.path === currentItem
                        ? actifIconClass + item.iconClass
                        : defaultIconClass + item.iconClass
                    }
                  ></canvas>
                  <span className="ms-4">{item.text}</span>
                </p>
              );
            })
          }
        </div>
      </div>

    </>
  );




};

export default SideBarComponent;
