import React, { useState } from 'react'
import DeleteModal from '../utils_components/modals/DeleteModal';
import { useSelector } from 'react-redux';
import ApiController from '../../redux/actions';
import { toast } from 'react-toastify';

const DeleteClient = ({showModal, setShowModal}) => {
    const currentClient = useSelector(state => state.clients.currentClient)
    const currentUser = useSelector(state => state.users.currentUser)
    const [isLoading, setIsLoading] = useState(false);


    function closeModal() {
        setShowModal(false);
      }


      const handeleDeleteClient =()=>{
        setIsLoading(true); 

        let clientId = currentClient.id
        ApiController.clients.deleteClient(clientId, currentUser.id)
        .then(()=>{
            closeModal()
            toast.success('Client supprimé')
        })
        .catch((err)=> {
            console.log(err)
            toast.error('Client non supprimé')
        }).finally(() => {
          setIsLoading(false); // Désactiver la loader
        })
    }
    
  return (
    <DeleteModal

    showModal={showModal}
    setShowModal={setShowModal}
    onClose={closeModal}
    title='Suppression client'
    titleIconClass='white-users-icon'
    >
        
         <div className="row m-0 p-0">
          <p className="text-center py-3">
            Voulez vous vraiment supprimer ce client?
          </p>
          <p className="text-center">
            <button
              type="submit"
              className=" btn btn-secondary text-white   py-2 px-3 mx-3 mt-3"
              onClick={closeModal}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-danger py-2 px-3 mx-3 mt-3"
              disabled={isLoading}
              onClick={handeleDeleteClient}
              >
              {isLoading ? (
                <div className="spinner-border text-light" role="status"></div>
              ) : (
                'Supprimer'
              )}
            </button>
          </p>
        </div>
    </DeleteModal>
  )
}

export default DeleteClient