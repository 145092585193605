/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ApiController from '../../redux/actions';
import CustomModal from '../utils_components/modals/CustomModal';
import './add-client.css'
import { toast } from 'react-toastify'
import { isValidMobilePhone, isValidText } from '../../utils/form_control';
import { useSelector } from 'react-redux';
import { FiPlus } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';

const AddClientForm = ({ showModal, setShowModal }) => {
    const currentUser = useSelector(state => state.users.currentUser)
    const { register, handleSubmit, setError, formState: { errors }, reset, control } = useForm()
    const [mesureTitle, setMesureTitle] = useState(false)
    const [mesureValue, setMesureValue] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [custumiseForm, setIsCustmiseForm] = useState(false)
    const [isInvalidFields, setIsInvalidFields] = useState({
        cou: false,
        epaule: false,
        longueur_manche: false,
        ceinture: false,
        tour_bras: false,
        hanche: false,
        cuisse: false,
        longueur_haut: false,
        longueur_jupe: false,
        taille: false,
        longueur_poitrine: false,
        longueur_robe: false,
        longueur_boubou: false,
        poitrine: false,
        longueur_bras: false,
        mesure_client:false
        // ... Ajoutez ici les autres champs avec leur état d'erreur
    });
    const [isFemale, setIsFemale] = useState(true)

    const [validatePays, setValidatePay] = useState(false)
    const [validateVille, setValidateVille] = useState(false)

    const [customFields, setCustomFields] = useState([
        { nomMesure: '', },
        { uniteMesure: '' },
    ]);


    const toggalForm = () => {
        setIsCustmiseForm(prevCustomisble => !prevCustomisble)
    }
    const addCustomField = () => {

        if (customFields !== "") {
            // console.log(customFields.length)
            const newField = {
                nomMesure: '',
                uniteMesure: '',
            };
            setCustomFields([...customFields, newField]);
        } else {
            setCustomFields([{ nomMesure: '', uniteMesure: '' }])
        }
    };


    const removeCustomField = (index) => {
        const updatedFields = [...customFields];
        updatedFields.splice(index, 1);
        setCustomFields(updatedFields);
    };

    const validateAge = (value) => {
        if (!value) return true;
        if (isNaN(value) || value < 1 || value > 9999 || !/^\d{1,4}$/.test(value)) {
            return false;
        }
        return true;
    };


    const handleNumericInput = (e, fieldType) => {
        const newInvalidFields = { ...isInvalidFields };
        if (e.key !== 'Backspace' && e.key !== 'Tab' && (isNaN(e.key) || !validateAge(e.target.value + e.key))) {
            e.preventDefault();
            newInvalidFields[fieldType] = true;
        } else {
            newInvalidFields[fieldType] = false;
        }
        setIsInvalidFields(newInvalidFields);
    };

    const handleMesureValue = (e) => {
        if (e.key !== 'Backspace' && e.key !== 'Tab' && (isNaN(e.key) || !validateAge(e.target.value + e.key))) {
            e.preventDefault();
            setMesureValue(true)
        } else {
            setMesureValue(false)
        }
    };




    const handlePays = (e) => {
        const inputValue = e.target.value + e.key;
        if (e.key !== 'Backspace' && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
            e.preventDefault();
            setValidatePay(true);
        } else {
            setValidatePay(false);
        }
    };
    const handleMesure = (e) => {
        const inputValue = e.target.value + e.key;
        if (e.key !== 'Backspace' && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
            e.preventDefault();
            setMesureTitle(true);
        } else {
            setMesureTitle(false);
        }
    };
    const handleVille = (e) => {
        const inputValue = e.target.value + e.key;
        if (e.key !== 'Backspace' && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
            e.preventDefault();
            setValidateVille(true);
        } else {
            setValidateVille(false);
        }
    };





    const submit = (data) => {
        setIsLoading(true); // Activer la loader

        const newData = {
            nom_complet: data.nom_complet,
            telephone: data.telephone,
            adresse: data.adresse,
            pays: data.pays || null,
            Ville: data.Ville || null,
            sexe: data.sexe,
            cou: parseInt(data.cou),
            epaule: parseInt(data.epaule),
            longueur_boubou: parseInt(data.longueur_boubou),
            longueur_haut: parseInt(data.longueur_haut),
            longueur_manche: parseInt(data.longueur_manche),
            longueur_bras: parseInt(data.longueur_bras),
            cuisse: parseInt(data.cuisse),
            longueur_pantalon: parseInt(data.longueur_pantalon),
            hanche: parseInt(data.hanche),
            ceinture: parseInt(data.ceinture),
            tour_bras: parseInt(data.tour_bras),
            poitrine: parseInt(data.poitrine),
            taille: parseInt(data.taille),
            longueur_robe: parseInt(data.longueur_robe),
            longueur_poitrine: parseInt(data.longueur_poitrine),
            longueur_jupe: parseInt(data.longueur_jupe),
            mesure_client: data.mesure_client,
            createdBy: currentUser.id
        }

        // console.log(newData)
        ApiController.clients.registerClient(newData)
            .then(() => {
                toast.success('Ajout client réuissi')
                setShowModal(false)
                reset()
            })
            .catch(err => {
                console.log(err);
                if (err.response.data) {
                    setError('sexe', { message: "veuillez choisir le sex du client" })
                }
                toast.error('erreur lors de l\'ajout')
            })
            .finally(() => {
                setIsLoading(false);
            });

    }
    const verificate = (data) => {

        let errorFields = []

        if (!isValidText(data.nom_complet)) {
            errorFields.push({ field: 'nom_complet', message: 'Veuillez un saisir un nom valide!' })
        }
        if (!isValidMobilePhone(data.telephone)) {
            errorFields.push({ field: 'telephone', message: 'Veuillez saisir un numéro valide!' })
        }

        if (errorFields.length) {
            errorFields.map(err => {
                let { field, message } = err
                setError(field, { message })
                return err
            })
        } else {
            submit(data)

            // console.log('======>', data)
        }
    }

    // const numericPattern = /^[0-9]*$/;

    // const handleFocus = (event) => {
    //     const { name } = event.target;
    //     clearErrors(name); // Efface l'erreur associée au champ spécifique lorsqu'il obtient le focus
    // };
    return (
        <CustomModal
            showModal={showModal}
            setShowModal={setShowModal}
            title='Ajout client'
            titleIconClass='white-users-icon'
        >
            <form onSubmit={handleSubmit(verificate)} className="add-client row my-4 pb-4 fs-90 text-start">
                <div className="col-lg space-client">
                    <div className="bg-white mx-2 ms-md-3 rounded row pb-4 mb-4 px-2 py-2">
                        <div>
                            <p className="title mb-4">INFORMATIONS CLIENT</p>
                        </div>
                        <div className=''>
                            <div className=''>
                                Nom complet
                                <input {...register('nom_complet', { required: 'Champs obligatoire!' })} type="text" />
                                <p className='error-msg text-center'> &nbsp; {errors.nom_complet?.message}</p>
                            </div>
                            <div className='mb-2 '>
                                Téléphone
                                <input {...register('telephone', { required: 'Champs obligatoire!' })} type="tel" />
                                <p className='error-msg text-center'> &nbsp; {errors.telephone?.message}</p>
                            </div>
                            <div className='mb-2 '>
                                Adresse
                                <input {...register('adresse', { required: 'Champs obligatoire!' })} type="text" />
                                <p className='error-msg text-center'> &nbsp; {errors.adresse?.message}</p>
                            </div>
                            <div className='mb-2 row bg-dange '>
                                <div className="col">
                                    <div className='row'>
                                        <div className='col'>
                                            Pays
                                            <input {...register('pays')} type="text" defaultValue={'Sénégal'} onKeyDown={handlePays} />
                                            {validatePays && (
                                                <p style={{ color: 'red' }}>Veuillez entrer un nom de pays valide.</p>
                                            )}
                                            {/* <p className='error-msg text-center'> &nbsp; {errors.pays?.message}</p> */}
                                        </div>
                                        <div className='col'>
                                            Ville
                                            <input {...register('Ville')} type="text" onKeyDown={handleVille} />
                                            {validateVille && (
                                                <p style={{ color: 'red' }}>Veuillez entrer un nom de ville valide.</p>
                                            )}
                                            {/* <p className='error-msg text-center'> &nbsp; {errors.Ville?.message}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-2 d-flex justify-content-between'>

                                <p className='mx-1 mx-lg-3'>
                                    <label >
                                        <input type="radio" name='sexe' onChange={() => setIsFemale(true)} {...register('sexe')} value={'femme'} /> Femme
                                    </label>
                                </p>
                                <p className='mx-1 mx-lg-3'>
                                    <label >
                                        <input type="radio" value={'homme'} onChange={() => setIsFemale(false)} {...register('sexe')} /> Homme
                                    </label>
                                </p>
                            </div>
                            <p className='error-msg text-center'> &nbsp; {errors.sexe?.message}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg mb-4 space-mesure">
                    <div className="bg-white mx-2 me-md-3 rounded row pb-3 px-2 py-2">
                        <div className='d-flex justify-content-between mb-3'>
                            <p className="title mb-4">MESURES CLIENT</p>
                            <button type='button' className='save-client-btn ' onClick={toggalForm}>personnaliser</button>
                        </div>
                        <div className=''>

                            {
                                custumiseForm ? (
                                    <>

                                        {

                                            <>

                                                {customFields.map((field, index) => (
                                                    
                                                    <div key={index} className='d-flex justify-content-between smallDeviceform'>
                                                        
                                                        <Controller
                                                            name={`mesure_client[${index}].nomMesure`}
                                                            control={control}
                                                            defaultValue={field.nomMesure}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="text"
                                                                    {...field}
                                                                    placeholder="Titre"
                                                                    id='mesureTitle'
                                                                    className={`mb-2 ${mesureValue ? 'd-none':''}`}
                                                                    onKeyDown={handleMesure} 
                                                                />
                                                                
                                                            )}
                                                        />
                                                         {mesureTitle && (
                                                <p className='fs-14' style={{ color: 'red' }}>Veuillez entrer des lettres svp .</p>
                                            )}
                                                        
                                                        <Controller
                                                            name={`mesure_client[${index}].uniteMesure`}
                                                            control={control}
                                                            defaultValue={field.uniteMesure || ''}
                                                            render={({ field }) => (
                                                                <input
                                                                    type="number"
                                                                    {...field}
                                                                    placeholder="Mesure"
                                                                    id='mesureValue'
                                                                    onKeyDown={handleMesureValue}
                                                                    className={`${mesureTitle ? 'd-none': ''}`}
                                                                />
                                                            )}
                                                        />
                                                                   {mesureValue && (
                                                <p className='ms-2' style={{ color: 'red' }}>Veuillez entrer 1 , 2 ou 4 chiffres svp !</p>
                                            )}
                                                        <FaTrashAlt className='text-danger m-2' size={20} type="button" onClick={() => removeCustomField(index)}>
                                                            Supprimer
                                                        </FaTrashAlt>
                                                    </div>
                                                ))}

                                                <div className="d-flex justify-content-center mt-3">
                                                    {/* <FiPlus type='button' onClick={addCustomField} size={30} /> */}
                                                    <button
                                                        className="add-new-client"
                                                        type="button"
                                                        onClick={addCustomField}
                                                    >
                                                        <FiPlus />
                                                    </button>
                                                </div>

                                            </>

                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            isFemale ? (
                                                <>

                                                    <div className='d-flex justify-content-between'>

                                                        <p>
                                                            COU :
                                                        </p>
                                                        <input type="number" {...register('cou', {

                                                        })} onKeyDown={(e) => handleNumericInput(e, 'cou')} />
                                                    </div>
                                                    {isInvalidFields.cou && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour COU.</p>}
                                                    <div className=' d-flex justify-content-between'>
                                                        <p>
                                                            EPAULE :
                                                        </p>
                                                        <input type="number" {...register('epaule')} onKeyDown={(e) => handleNumericInput(e, 'epaule')} />
                                                    </div>
                                                    {isInvalidFields.epaule && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour epaule.</p>}

                                                    <div className=' d-flex justify-content-between'>
                                                        <p>
                                                            MANCHES :
                                                        </p>
                                                        <input type="number" {...register('longueur_manche')} onKeyDown={(e) => handleNumericInput(e, 'longueur_manche')} />
                                                    </div>
                                                    {isInvalidFields.longueur_manche && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour MANCHES.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            CEINTURE :
                                                        </p>
                                                        <input type="number" {...register('ceinture')} onKeyDown={(e) => handleNumericInput(e, 'ceinture')} />
                                                    </div>
                                                    {isInvalidFields.ceinture && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour CEINTURE.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE BRAS :
                                                        </p>
                                                        <input type="number" {...register('tour_bras')} onKeyDown={(e) => handleNumericInput(e, 'tour_bras')} />
                                                    </div>
                                                    {isInvalidFields.tour_bras && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE BRAS.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE HANCHES :
                                                        </p>
                                                        <input type="number" {...register('hanche')} onKeyDown={(e) => handleNumericInput(e, 'hanche')} />
                                                    </div>
                                                    {isInvalidFields.hanche && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE HANCHES.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE CUISSE :
                                                        </p>
                                                        <input type="number" {...register('cuisse')} onKeyDown={(e) => handleNumericInput(e, 'cuisse')} />
                                                    </div>
                                                    {isInvalidFields.cuisse && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE CUISSE .</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR HAUT :
                                                        </p>
                                                        <input type="number" {...register('longueur_haut')} onKeyDown={(e) => handleNumericInput(e, 'longueur_haut')} />
                                                    </div>
                                                    {isInvalidFields.longueur_haut && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR HAUT  .</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR JUPE :
                                                        </p>
                                                        <input type="number" {...register('longueur_jupe')} onKeyDown={(e) => handleNumericInput(e, 'longueur_jupe')} />
                                                    </div>
                                                    {isInvalidFields.longueur_jupe && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour  LONGUEUR JUPE .</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOURS DE TAILLE :
                                                        </p>
                                                        <input type="number" {...register('taille')} onKeyDown={(e) => handleNumericInput(e, 'taille')} />
                                                    </div>
                                                    {isInvalidFields.taille && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOURS DE TAILLE .</p>}
                                                    {/* <div className='d-flex justify-content-between'>
                                            <p>
                                                HANCHES :
                                            </p>
                                            <input type="number" {...register('hanche')} onKeyDown={(e) => handleNumericInput(e, 'hanche')} />
                                        </div>
                                        {isInvalidFields.hanche && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour HANCHES.</p>} */}
                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR POITRINE :
                                                        </p>
                                                        <input type="number" {...register('longueur_poitrine')} onKeyDown={(e) => handleNumericInput(e, 'longueur_poitrine')} />
                                                    </div>
                                                    {isInvalidFields.longueur_poitrine && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR POITRINE  .</p>}
                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR ROBE :
                                                        </p>
                                                        <input type="number" {...register('longueur_robe')} onKeyDown={(e) => handleNumericInput(e, 'longueur_robe')} />
                                                    </div>
                                                    {isInvalidFields.longueur_robe && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR ROBE  .</p>}
                                                    <div className='mb-2 d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR BRAS:
                                                        </p>
                                                        <input type="number" {...register('longueur_bras')} />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='d-flex justify-content-between'>

                                                        <p>
                                                            COU :
                                                        </p>
                                                        <input type="number" {...register('cou', {

                                                        })} onKeyDown={(e) => handleNumericInput(e, 'cou')} />
                                                    </div>
                                                    {isInvalidFields.cou && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour COU.</p>}
                                                    <div className=' d-flex justify-content-between'>
                                                        <p>
                                                            EPAULE :
                                                        </p>
                                                        <input type="number" {...register('epaule')} onKeyDown={(e) => handleNumericInput(e, 'epaule')} />
                                                    </div>
                                                    {isInvalidFields.epaule && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour epaule.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            MANCHES :
                                                        </p>
                                                        <input type="number" {...register('longueur_manche')} onKeyDown={(e) => handleNumericInput(e, 'epaule')} />
                                                    </div>
                                                    {isInvalidFields.longueur_manche && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour MANCHES.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            CEINTURE :
                                                        </p>
                                                        <input type="number" {...register('ceinture')} onKeyDown={(e) => handleNumericInput(e, 'ceinture')} />
                                                    </div>
                                                    {isInvalidFields.ceinture && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour CEINTURE.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE BRAS :
                                                        </p>
                                                        <input type="number" {...register('tour_bras')} onKeyDown={(e) => handleNumericInput(e, 'tour_bras')} />
                                                    </div>
                                                    {isInvalidFields.tour_bras && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE BRAS .</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE HANCHES :
                                                        </p>
                                                        <input type="number" {...register('hanche')} onKeyDown={(e) => handleNumericInput(e, 'hanche')} />
                                                    </div>
                                                    {isInvalidFields.hanche && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE HANCHES.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TOUR DE CUISSE :
                                                        </p>
                                                        <input type="number" {...register('cuisse')} onKeyDown={(e) => handleNumericInput(e, 'cuisse')} />
                                                    </div>
                                                    {isInvalidFields.cuisse && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour TOUR DE CUISSE.</p>}
                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR HAUT :
                                                        </p>
                                                        <input type="number" {...register('longueur_haut')} onKeyDown={(e) => handleNumericInput(e, 'longueur_haut')} />
                                                    </div>
                                                    {isInvalidFields.longueur_haut && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR HAUT.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR BOUBOU :
                                                        </p>
                                                        <input type="number" {...register('longueur_boubou')} onKeyDown={(e) => handleNumericInput(e, 'longueur_boubou')} />
                                                    </div>
                                                    {isInvalidFields.longueur_boubou && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR BOUBOU.</p>}

                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            LONGUEUR PANATALON :
                                                        </p>
                                                        <input type="number" {...register('longueur_pantalon')} onKeyDown={(e) => handleNumericInput(e, 'longueur_pantalon')} />
                                                    </div>
                                                    {isInvalidFields.longueur_pantalon && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR PANATALON .</p>}


                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            TAILLE :
                                                        </p>
                                                        <input type="number" {...register('taille')} onKeyDown={(e) => handleNumericInput(e, 'taille')} />
                                                    </div>
                                                    {isInvalidFields.taille && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour LONGUEUR TAILLE .</p>}



                                                    <div className='d-flex justify-content-between'>
                                                        <p>
                                                            POITRINE :
                                                        </p>
                                                        <input type="number" {...register('poitrine')} onKeyDown={(e) => handleNumericInput(e, 'poitrine')} />
                                                    </div>
                                                    {isInvalidFields.poitrine && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour POITRINE.</p>}
                                                    {/* 

                                        <div className='mb-2 d-flex justify-content-between'>
                                    <p>
                                        LONGUEUR BRAS:
                                    </p>
                                    <input type="number" {...register('longueur_bras')} onKeyDown={(e) => handleNumericInput(e, 'poitrine')} />
                                        </div>
                                        {isInvalidFields.poitrine && <p style={{ color: 'red' }}>Veuillez entrer un nombre de 4 chiffres pour  LONGUEUR BRAS.</p>} */}
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }






                        </div>
                    </div>
                </div>
                <div className='text-end pe-4'>
                    <button className='save-client-btn px-4' disabled={isLoading}>
                        {isLoading ? (
                            <div className="spinner-border text-light" role="status">
                            </div>
                        ) : (
                            'Ajouter'
                        )}
                    </button>
                </div>

            </form>
        </CustomModal>
    );
};

export default AddClientForm;