import axios from 'axios'
import { addNewMesure, setCurrentMesure, setListMesures } from '../reducers/mesures'
import { store } from '../store/store'

const { MESURE_API_ROUTE, GET_MESURE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const mesures = {
    getMesuresList(){
        return new Promise((resolve,reject)=>{
            axios.get(GET_MESURE_API_ROUTE)
            .then(response => {
                if(response.status === 200){
                    let mesures = response.data
                    dispatch(setListMesures(mesures))
                    resolve(mesures)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerMesure(data){
        return new Promise((resolve,reject)=>{
            axios.post(MESURE_API_ROUTE,data)
            .then(response => {
                console.log(response);
                if(response.status ===200){
                    let mesure = response.data
                    dispatch(addNewMesure(mesure))
                    resolve(mesure)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    updateMesure(data,mesure_id){
        return new Promise((resolve,reject)=>{
            axios.put(`${MESURE_API_ROUTE}/${mesure_id}`,data)
            .then(response => {
                console.log(response);
                if(response.status ===200){
                    let mesure = response.data
                    dispatch(setCurrentMesure(mesure))
                    resolve(mesure)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    deleteMesure(mesure_id){
        return new Promise((resolve,reject)=>{
            axios.delete(`${MESURE_API_ROUTE + mesure_id}/`)
            .then(response => {
                if(response.status ===204){
                    resolve(response)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
}
export default mesures