/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit/"
import { commandesGenerator } from "../../utils/data_generator"

let data = commandesGenerator(100)
let initialState = {
    currentCommande: {},
    listCommandes :  {},
    listCommandesAll : []
}
const commandesSlice = createSlice({
    name:'commandes',
    initialState,
    reducers:{
        addNewCommande: (state, { payload }) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            state.listCommandes.data = [...state.listCommandesAll?.data, payload].reverse();
            // eslint-disable-next-line no-unsafe-optional-chaining
            state.listCommandesAll.data = [...state.listCommandes?.data, payload].reverse();
          },
        setCurrentCommande:(state,{payload})=>{
            state.currentCommande = payload
        },
        setCommandesList:(state,{payload})=>{
            state.listCommandes = payload
            state.listCommandesAll = payload
        }, 
        resetCurrentCommande:(state,{payload})=>{
            state.currentCommande = {}
        },
        filterCommandesBySearch:(state,{payload})=>{
            state.listCommandes.data.filter(item =>{
                
                let data = [item?.client?.nom_complet.toLowerCase(), item?.numero_commande, item?.montant, item?.date_commande].join(' ').toLowerCase()
                if(data){

                    console.log(payload)
                    
                    return data.includes(payload.toLowerCase())
                }else{
                    return console.log("no data")
                }
            })
        },
    }
})

export const {addNewCommande, setCurrentCommande,setCommandesList,resetCurrentCommande,filterCommandesBySearch} = commandesSlice.actions
export default commandesSlice.reducer