import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentItem } from "../../redux/reducers/navigation";
import { BsEyeFill } from 'react-icons/bs';
import moment from 'moment';
import {
  ADD_COMMANDE_PATH,
  COMMANDES_PATH,
  DETAIL_COMMANDE_PATH,
  UPDATE_COMMANDE_PATH,
} from "../../utils/navigation_paths";
// import AddCommandes from '../commandes/AddCommandes'
import AdminInterfaceContainer from "../utils_components/interface_containers/AdminInterfaceContainer";
import CustomBootstrapTable from "../utils_components/tables/CustomBootstrapTable";
import "./commandes.css";
import ApiController from "../../redux/actions";
// import { toast } from "react-toastify";
import { setCurrentCommande } from "../../redux/reducers/commandes";
import DeleteCommande from "./DeleteCommande";
import { toast } from "react-toastify";
import { resetCurrentCommande } from "../../redux/reducers/commandes";
import Confectionnee from "./stautCommande/Confectionnee";
import Terminee from "./stautCommande/Terminee";
import Livree from "./stautCommande/Livree";
// import UpdateCommande from "./UpdateCommande";
import Skeleton from 'react-loading-skeleton';


const Commandes = () => {

  const dispatch = useDispatch();


  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [CommandesEnConfection, setCommandesEnConfection] = useState([]);


  const listCommandes = useSelector(state => state?.commandes?.listCommandes?.data)


  // const [query,setQuery] =  useState()

  const commandeEncour = listCommandes?.filter(item => item.statut === "encours")


  const commandeTerminee = listCommandes?.filter(item => item.statut === 'terminee')

  const commandeLivree = listCommandes?.filter(item => item.statut === 'livree')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const currentUser = useSelector(state => state.users?.currentUser)
  const [processingCommands, setProcessingCommands] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)





  useEffect(() => {
    dispatch(setCurrentItem(COMMANDES_PATH));
    ApiController.commandes?.getCommandesList(currentUser?.id).then(() => {
      // console.log(res.data)
      // setSearch(res.data)
      // dispatch(setCommandesList(res))
      setLoading(false)
    })
  }, [currentUser.id, dispatch]);

  const openDeleteModal = (item) => {
    dispatch(setCurrentCommande(item))
    setShowDeleteModal(true)
  }
  const updateCommande = (item) => {
    dispatch(setCurrentCommande(item))
    const commandeId = item?.client?.nom_complet; // ou tout autre moyen d'obtenir l'ID de la commande
    const updateCommandeUrl = UPDATE_COMMANDE_PATH.replace(":id", commandeId);
    navigate(updateCommandeUrl);
  }

  const detailCommande = (item) => {
    // dispatch(setCurrentCommande(item))
    const commandeId = item?.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailCommande = DETAIL_COMMANDE_PATH.replace(":id", commandeId);
    navigate(detailCommande);
  }



  // ... autres importations

  const handleStatusChange = (commandeId) => {
    if (processingCommands.includes(commandeId)) {
      // La commande est déjà en cours de traitement, ne rien faire
      return;
    }

    setProcessingCommands((prevProcessingCommands) => [...prevProcessingCommands, commandeId]);

    const commande = listCommandes.find((cmd) => cmd.id === commandeId);
    if (commande && commande.statut !== 'encours') {
      dispatch(resetCurrentCommande());
      const formData = new FormData();
      formData.append('statut', 'encours');

      ApiController.commandes
        .updateCommande(formData, commandeId, currentUser.id)
        .then((res) => {
          dispatch(setCurrentCommande(res));
          toast.success('commande mise en confection');
          setCommandesEnConfection((prevCommandes) => [...prevCommandes, commande]);
          setProcessingCommands((prevProcessingCommands) =>
            prevProcessingCommands.filter((id) => id !== commandeId)
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error("erreur lors de la mis en confection");
          setProcessingCommands((prevProcessingCommands) =>
            prevProcessingCommands.filter((id) => id !== commandeId)
          );
        });
    } 
    else {
      setProcessingCommands((prevProcessingCommands) =>
        prevProcessingCommands.filter((id) => id !== commandeId)
      );
    }

  };




  // All commandes
  const AllCommandes = () => {
    // Table colums
    const columns = [
      {
        dataField: "id",
        text: "N°",
        formatter: (row) => {
          return loading ? <Skeleton duration={10} /> : row.id
        }
      },

      {

        dataField: "clientId",
        text: "Client",
        formatter: (cell, row) => {
          if (row.client) {
            // console.log(row)
            return loading ? <Skeleton duration={10} /> : row.client.nom_complet;
          } else {
            return "non defini";
          }
        },

      },
      {
        dataField: "date_livraison",
        text: "Date de livraison",
        formatter: (cell, row) => {
          const formattedDate = moment(row.date_livraison).format("DD/MM/YYYY");
          return loading ? <Skeleton duration={10} /> : formattedDate;
        }
        // formatter: () => date_livraison
      },
      {
        dataField: "montant",
        text: "Montant",
        formatter: (cell, row) => {
          return loading ? <Skeleton duration={10} /> : row.montant
        }
      },
      {
        dataField: "adresse",
        text: "status commande",
        formatter: (cell, row) => {
          const isLoading = processingCommands.includes(row.id);
          return (
            <>
              {
                loading ? <Skeleton duration={10} /> :
                  row.statut === 'nouvelle' ? (
                    <button
                      className="btn fs-90 shadow btnhover"
                      onClick={() => handleStatusChange(row.id)}
                      disabled={isLoading} // Désactiver le bouton pendant le traitement
                    >
                      {isLoading ? '...loading' : 'Nouvelle'}
                    </button>
                  ) : (
                    <p className="align-items-center">
                      En cours
                    </p>
                  )
              }
            </>
          );
        }
      }
      ,
      {
        dataField: "adresse",
        text: "Paiement",
        formatter: (cell, row) => {
          // <button className="btn fs-90 text-danger">Impaye</button>
          const commande = row; // La commande spécifique à cette ligne
          return (
            <>
              {
                loading ? <Skeleton duration={10} /> :
                  commande.montant_restant === 0 ? (
                    <button className="btn fs-90 text-success">Payée</button>
                  ) : commande.montant_restant === commande.montant ? (
                    <button className="btn fs-90 text-danger">Impayée</button>
                  ) : (
                    <button className="btn fs-90 text-danger">En cours</button>
                  )}
            </>
          );

        }
      },
      {
        dataField: "action",
        text: "Actions",
        formatter: (cell, row) => {
          return (
            <>
              {
                loading ? <Skeleton duration={10} /> : (

                  <div className="d-flex align-items-center">
                    <BsEyeFill className=" btn-icon   me-4" size={17}
                      onClick={() => detailCommande(row)} style={{ cursor: 'pointer' }} />

                    <button
                      className="btn-icon edit-btn-icon me-4"
                      onClick={() => updateCommande(row)}
                    >

                    </button>
                    <button
                      className="btn-icon delete-btn-icon me-4"
                      onClick={() => openDeleteModal(row)}
                    ></button>
                  </div>
                )
              }
            </>
          );
        },
      },
    ];


    return (
      <div className="p-0 m-0">


        <DeleteCommande showModal={showDeleteModal} setShowModal={setShowDeleteModal} />
        {/* <InputFiltrer 
          onChange={e => setSearch(e.target.value)}
          value={search}
        
        /> */}
        {
          listCommandes?.length ? (
            <>
              <CustomBootstrapTable
                columns={columns}
                data={listCommandes.filter(item => item.client?.nom_complet.toLowerCase().includes(search.toLowerCase()) || item?.numero_commande.includes(search)

                  // let data = [item?.client?.nom_complet.toLowerCase(), item?.numero_commande, item?.montant, item?.date_commande].join(' ').toLowerCase()
                  // if(data){

                  //     console.log(search)

                  //     return data.includes(search)
                  // }else{
                  //     console.log("no data")
                  //     return 
                  // }
                )}
                // autoIncrementer="N°"
                //   pacaginator={listClient.length > 10}
                showPaginator={listCommandes.length > 10 ? true : false}
              />
            </>
          ) : <p className="text-center p-5">Pas de commande disponible</p>
        }
      </div>
    );
  };

  // console.log({search});


  return (
    <AdminInterfaceContainer>
      <div className="client">
        <div className="row mt-3 mx-2 mb-4 commandeItems">
          <div className="col align-items-center row commande-action">
            <span className="text-start fw-100 fs-90">Liste des commandes</span>
          </div>
          <div className="col text-end btn-add-commande">
            {/* <button
              onClick={() => navigate(ADD_COMMANDE_PATH)}
              className="add-client-btn fs-80 px-4 fw-600"
            >
              Ajouter une commande
            </button> */}
            <button onClick={() => navigate(ADD_COMMANDE_PATH)} className='add-btn-red fs-80 px-4 fw-600'>Ajouter une commande</button>
            {/* <ButtonRed backgroundColor={'#e82828'} color={'#ffffff'}
              onClick={() => navigate(ADD_COMMANDE_PATH)}
            > <FiPlus size={20} />Ajouter une commande</ButtonRed> */}
            {/* <AddCommandes showModal={showModal} setShowModal={setShowModal} /> */}
          </div>
        </div>

        <div className="mb-5 mx-3 pt-4">
          <ul className="nav-tabs py-3 nav commands-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active p-3"
                id="command-tab"
                data-bs-toggle="tab"
                data-bs-target="#command-tab-pane"
                type="button"
                role="tab"
                aria-controls="command-tab-pane"
                aria-selected="true"
              >
                Commandes
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link p-3"
                id="onGoingCommand-tab"
                data-bs-toggle="tab"
                data-bs-target="#onGoingCommand-tab-pane"
                type="button"
                role="tab"
                aria-controls="onGoingCommand-tab-pane"
                aria-selected="false"
              >
                En confection {
                  commandeEncour?.length ? <span className="badge bg-danger">{commandeEncour?.length}</span> : ''
                }
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link p-3"
                id="commandDone-tab"
                data-bs-toggle="tab"
                data-bs-target="#commandDone-tab-pane"
                type="button"
                role="tab"
                aria-controls="commandDone-tab-pane"
                aria-selected="false"
              >
                Terminées {
                  commandeTerminee?.length ? <span className="badge bg-danger">{commandeTerminee?.length}</span> : ''
                }
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="commandDelivered-tab"
                data-bs-toggle="tab"
                data-bs-target="#commandDelivered-tab-pane"
                type="button"
                role="tab"
                aria-controls="commandDelivered-tab-pane"
                aria-selected="false"
              >
                Livrées {
                  commandeLivree?.length ? <span className="badge bg-danger">{commandeLivree?.length}</span> : ''
                }
              </button>
            </li>
          </ul>
          <div className="tab-content bg-white" id="myTabContent">
            <div
              className="tab-pane fade show active "
              id="command-tab-pane"
              role="tabpanel"
              aria-labelledby="command-tab"
              tabIndex="0"
            >
              <AllCommandes />
            </div>
            <div
              className="tab-pane fade"
              id="onGoingCommand-tab-pane"
              role="tabpanel"
              aria-labelledby="onGoingCommand-tab"
              tabIndex="0"
            >
              <Confectionnee listCommandes={listCommandes} />
            </div>
            <div
              className="tab-pane fade"
              id="commandDone-tab-pane"
              role="tabpanel"
              aria-labelledby="commandDone-tab"
              tabIndex="0"
            >
              {/* <CommandesTerminees /> */}
              <Terminee listCommandes={listCommandes} />
            </div>
            <div
              className="tab-pane fade"
              id="commandDelivered-tab-pane"
              role="tabpanel"
              aria-labelledby="commandDelivered-tab"
              tabIndex="0"
            >
              {/* <CommandesLivrees /> */}
              <Livree listCommandes={listCommandes} />
            </div>
          </div>
        </div>
      </div>
    </AdminInterfaceContainer>
  );
};

export default Commandes;
