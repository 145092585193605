/* eslint-disable no-unused-vars */
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { addNewComptabilite, setComptabilite, setCurrentComptabilite } from '../reducers/comptabilite'
import { store } from '../store/store'

const { COMPTABILITE_API_ROUTE, DELETE_COMPTABILITE_API_ROUTE, ADD_COMPTABILITE_API_ROUTE, UPDATE_COMPTABILITE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const comptabilites = {
    // getComptabilitesList(){
    //     return new Promise((resolve,reject)=>{
    //         axios.get(COMPTABILITE_API_ROUTE)
    //         .then(response => {
    //             console.log('comptabilite',response);
    //             if(response.status === 200){
    //                 let comptabilite = response.data
    //                 dispatch(setComptabilite(comptabilite))
    //                 resolve(comptabilite)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(res => reject(res))
    //     })
    // },
    registerComptabilite(data, id){
        return new Promise((resolve,reject)=>{
            axios.post(ADD_COMPTABILITE_API_ROUTE,data)
            .then(response => {
                // console.log(response)
                if(response.status ===201){
                    // console.log(response.data)
                    let comptabilite = response.data
                    // dispatch(this.getComptabilitesList(data, id))
                    dispatch(addNewComptabilite(comptabilite))
                    resolve(comptabilite)
                }
                // else{
                //     reject(response)
                // }
            })
            .catch(error => reject(error))
        })
    },
    updateComptabilite(id, data){
        return new Promise((resolve,reject)=>{
            axios.put(`${UPDATE_COMPTABILITE_API_ROUTE + id}/`,data)
            .then(response => {
                // console.log(response);
                if(response.status ===200){
                    let comptabilite = response.data
                    dispatch(setCurrentComptabilite(comptabilite))
                    // this.getComptabilitesList(id)
                    resolve(comptabilite)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    deleteComptabilite(id, userId){
        return new Promise((resolve,reject)=>{
            axios.delete(`${DELETE_COMPTABILITE_API_ROUTE + id}/`)
            .then(response => {
                if(response.status ===204){
                    resolve(response)
                    dispatch(this.getComptabilitesList(userId))
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
}
export default comptabilites