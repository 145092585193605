/* eslint-disable no-unused-vars */

const { ACCUEIL_PATH, CLIENTS_PATH,  COMMANDES_PATH, COMPTABLITE_PATH, GALERIE_PATH, PARAMETRE_PATH } = require('./navigation_paths')
module.exports = [
    {
        path:ACCUEIL_PATH,
        text:'Accueil',
        iconClass:'home-icon',
    },
    {
        path:CLIENTS_PATH,
        text:'Clients',
        iconClass:'users-icon',
    },
    {
        path:COMMANDES_PATH,
        text:'Commandes',
        iconClass:'commande-icon',
    },
    // {
    //     path:PARAMETRE_PATH,
    //     text:'Parametre',
    //     iconClass:'users-icon',
    // },
    // {
    //     path:COMPTABLITE_PATH,
    //     text:'Comptablité',
    //     iconClass:'paper-icon',
    // },
    // {
    //     path:STOCK_PATH,
    //     text:'Stock',
    //     iconClass:'list-icon',
    // },
    // {
    //     path:EMPLOYEES_PATH,
    //     text:'Employés',
    //     iconClass:'user-icon',
    // },
    // {
    //     path:GALERIE_PATH,
    //     text:'Galerie',
    //     iconClass:'crop-icon',
    // },
]